import React, {useEffect, useState} from 'react';
import {Form, message, Select} from "antd";
import {customFetch, getApiUrl, getParams} from "../../../helpers/helpers";
import axios from "axios";

const ShippingClassSelect = ({site_id, name}) => {
    const [options, setOptions] = useState([]);

    const params = { site_id }

    useEffect(() => {
        axios.get(`${getApiUrl('shipping_classes')}shipping_classes/${getParams(params)}`, {
            headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}
            }
        ).then(res => {
            setOptions(res.data.objects)
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }, [])

    const renderSelectOptions = () => (
        options &&
        options.length > 0 &&
        options.map(option => {
            return (
                <Select.Option key={option.id + Math.random()} value={option.id}>{option.name}</Select.Option>
            )
        })
    )

    return (
        <Form.Item labelCol={4} wrapperCol={20} name={name}>
            <Select style={{width: '100%'}}>
                {renderSelectOptions()}
            </Select>
        </Form.Item>
    );
};

export default ShippingClassSelect;