import React from 'react';
import {getDateAndTime} from "../../../helpers/getDate";
import {RetweetOutlined} from "@ant-design/icons";

const OrderRefundList = ({order}) => {
    return (
        order.refund_orders?.length > 0 &&
        <>
            <div className='order-refund__total--text'>Totalt</div>
            <ul className='order-refund__list'>
                {
                    order.refund_orders.map(refundOrder => {
                        return (
                            <li key={refundOrder.id} className='order-refund__item'>
                                <RetweetOutlined /> Återbetalning #{refundOrder.id} - {getDateAndTime(refundOrder.datetime_created)}
                                <span className='order-refund__total'>{refundOrder.amount} {order.currency}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
};

export default OrderRefundList;
