import React from 'react';
import AntTable from "../../../AntTable/AntTable";
import {useSelector} from "react-redux";
import {Tag} from "antd";

const PickListPrintTable = ({order, checked}) => {
    const {loading} = useSelector(state => state?.orders)
    const columns = [
        {
            title: 'Ordernummer',
            dataIndex: 'external_order_id',
        },
        {
            title: 'Produkt',
            dataIndex: 'name',
            width: '50%'
        },
        {
            title: 'Status',
            dataIndex: 'packing_status',
            render: (_, order) => {
                if (order.packing_status) {
                    const statusTitle = order.packing_status.replaceAll('_', ' ');

                    if (order.packing_status === 'not_packed') {
                        return <Tag color="volcano">{statusTitle}</Tag>
                    } else {
                        return <Tag color="green">{statusTitle}</Tag>
                    }
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Leverans',
            dataIndex: 'shipping_name'
        },
        {
            title: 'Antal',
            dataIndex: 'quantity'
        }
    ]

    const orderData = order.order_items?.map(item => (
        {
            name: item.name,
            quantity: item.quantity,
            shipping_name: order.shipping_method_name,
            external_order_id: order.external_order_id,
            packing_status: checked ? 'packed' : order.packing_status,
        }
    ))

    return (
        <AntTable rowKey={row => row.external_order_id + Math.random()}
                  loading={loading}
                  style={{marginTop: '20px'}}
                  dataSource={orderData}
                  columns={columns}
                  pagination={false}
                  isPaginationDisabled={true}
                  total={null}
                  rowSelection={false}/>
    );
};

export default PickListPrintTable;
