import React, {useEffect} from 'react';
import {getColumns} from "../../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../../ant-table/dashboardColumns";
import {Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import AntTable from "../../../components/AntTable/AntTable";
import {getTextFromType} from "../../../helpers/helpers";
import {getActionTypes} from "../../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../../helpers/getUseSelectorFromType";

const Attributes = ({type = 'attributes', title = 'Attribut'}) => {
    const {getItems, removeItem, setAttributesFilters, setAttributesSorting, setAttributesPagination} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const items = useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]);
    const {loading, pagination} = useSelector(state => state[useSelectorType] && state[useSelectorType]);

    const {
        attributesTableFilters,
        attributesTablePagination,
        attributesTableSorting} = useSelector(state => state?.attributes)

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                params: {
                    ...{...(attributesTableFilters && Object.keys(attributesTableFilters).length > 0 && attributesTableFilters)},
                    ...(attributesTableSorting && Object.keys(attributesTableSorting).length > 0 && attributesTableSorting?.order && {
                        order_by: attributesTableSorting.field,
                        order_type: attributesTableSorting.order.replace('end', '')
                    })
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                filters: attributesTableFilters,
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row className='filter-block' justify='end' align='middle'>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>

                        <AntTable initialData={items} total={items?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setAttributesFilters}
                                  setSorting={setAttributesSorting}
                                  setPagination={setAttributesPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default Attributes;
