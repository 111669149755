import React from 'react';
import {Link } from 'react-router-dom';
import {Popconfirm} from 'antd';
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import useActions from "../../hooks/useActions";

const Header = () => {
    const {logout} = useActions();
    const {user} = useSelector(state => state?.login)

    const logoutHandler = () => {
        if (user) {
            logout(false, user)
        }
    }

    return (
        <header className='header'>
            <div className="logo">
                <Link to='/'>Startsida</Link>
            </div>
            <div>
                <Link to='/user' style={{marginRight: '15px'}}>
                    <UserOutlined />
                </Link>
                <Popconfirm
                    title="Vill du logga ut?"
                    onConfirm={logoutHandler}
                    okText="Ja"
                    placement='left'
                    cancelText="Nej"
                >
                    <LogoutOutlined/>
                </Popconfirm>
            </div>
        </header>
    );
};

export default Header;
