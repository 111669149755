import React, {useEffect, useState} from 'react';
import {Button, Row} from "antd";
import {FilterFilled} from "@ant-design/icons";
import useActions from "../hooks/useActions";
import RangeInput from "../components/Form/RangeInput/RangeInput";

const getFiltersRangeInputs = ({dataIndex = '', tableFilters, filterText = ''}) => {
    const {setIsTableFiltered} = useActions();
    const [iconColor, setIconColor] = useState(null)
    const minText = dataIndex + '_min';
    const maxText = dataIndex + '_max';
    const [min, setMin] = useState(tableFilters ? tableFilters[minText] : null)
    const [max, setMax] = useState(tableFilters ? tableFilters[maxText] : null)

    useEffect(() => {
        if (tableFilters && tableFilters[minText] && tableFilters[maxText]) {
            setIconColor('#1890ff')
        }

        if (tableFilters && Object.keys(tableFilters).length === 0) {
            setMin(null);
            setMax(null);
            setIconColor(null)
        }
    }, [tableFilters])

    const filterHandler = (confirm, setSelectedKeys) => {
        setSelectedKeys([{
            [minText]: min,
            [maxText]: max
        }
        ])
        confirm({closeDropdown: true});
        setIconColor('#1890ff')
        setIsTableFiltered(false);
    }

    const clearFiltersHandler = (confirm, setSelectedKeys, clearFilters) => {
        setSelectedKeys([])
        clearFilters();
        setIconColor(null)
        setMin(null)
        setMax(null)
        confirm({closeDropdown: true});
    }

    return {
        filterDropdown: ({setSelectedKeys, confirm, clearFilters}) => {
            return (
                <Row id={dataIndex + '_range_inputs_dropdown'} style={{maxWidth: '300px', width: '100%', padding: '10px'}}>
                    <span style={{display: 'block', marginBottom: '10px'}}>Filtrera på {filterText}</span>
                    <RangeInput customMin={min} isCustom={true} customMax={max} customSetMin={setMin}
                                customSetMax={setMax} style={{marginBottom: '10px'}}/>
                    <Row>
                        <Button style={{marginRight: '10px'}} type='primary'
                                onClick={() => filterHandler(confirm, setSelectedKeys)}>Aktivera filter</Button>
                        <Button type='primary' ghost
                                onClick={() => clearFiltersHandler(confirm, setSelectedKeys, clearFilters)}>Rensa
                            filter</Button>
                    </Row>
                </Row>
            )
        },
        filteredValue: {
            [minText]: min,
            [maxText]: max
        },
        filterIcon: () => {
            return <FilterFilled id={dataIndex} style={{...iconColor && {color: iconColor}}}/>
        },
    }
}

export default getFiltersRangeInputs;
