import React from 'react';
import {useSelector} from "react-redux";
import AddItemForm from "../../../../components/Form/AddItemForm/AddItemForm";
import {addFormElements} from "./addFormElements";
import {getTextFromType} from "../../../../helpers/helpers";
import {getUseSelectorFromType} from "../../../../helpers/getUseSelectorFromType";
import {getActionTypes} from "../../../../helpers/getActionTypes";

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const ItemAdd = ({type}) => {
    const useSelectorType = getUseSelectorFromType(type);
    const {loading} = useSelector(state => state[useSelectorType]);
    const formElements = addFormElements[useSelectorType + 'AddFormElements'];
    const typeTextTranslations = {
        'products': 'produkt',
        'attributes': 'attribut',
        'categories': 'kategori',
        'shipping-base-addresses': 'avsändarensadress',
        'shipping-methods': 'frakt metod',
        'shipping-classes': 'fraktklass',
        'stock_objects': 'lagerobject',
        'sites': 'webbplats',
        'images': 'bild',
        'tags': 'tagger',
    }

    const buttonText = `Lägg till ${typeTextTranslations[type]}`
    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const actionItemData = {
        ...getActionTypes(type),
        contentText: `${getTextFromType({string: type})} har skapats`
    }

    return (
        <AddItemForm type={type} addItemActionData={actionItemData} loading={loading} formElements={formElements} validationMsg={validateMessages}
                     layout={layout} buttonText={buttonText}/>
    );
};

export default ItemAdd;
