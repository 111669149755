import {SET_TAGS_PAGINATION} from "@reduxStore/reducers/shopReducers/tagsReducer";
import {getApiUrl, getParamsFromObject} from "../../../helpers/helpers";
import axios from "axios";
import {message} from "antd";
import {
    SET_ORDER, SET_ORDER_NOTES, SET_ORDER_NOTES_LOADING,
    SET_ORDERS,
    SET_ORDERS_LOADING,
    SET_ORDERS_PAGINATION, SET_ORDERS_TABLE_FILTERING
} from "../../reducers/shopReducers/ordersReducer";
import {shopActions} from "./shopActions";
import {SET_FREIGHT_LOADING, SET_FREIGHT_POPUP} from "../../reducers/bookFreightReducer/bookFreightReducer";
import {SET_ORDERS_TABLE_SORTING} from "../../reducers/shopReducers/ordersReducer";
import {picklistActions} from "../picklistActions/picklistActions";

const url = getApiUrl('orders')
const orderValues = {
    type: 'orders',
    setItemsActionType: SET_ORDERS,
    setLoadingActionType: SET_ORDERS_LOADING,
    setPaginationActionType: SET_ORDERS_PAGINATION
}

const getUrlByType = ({type = '', id = null, status = null}) => {
    let postUrl = `${url}orders/${id}/`;

    if (type === 'refund') {
        postUrl = `${url}orders/${id}/refund/`
    }

    if (type === 'freight') {
        postUrl = `${url}orders/${id}/freight_booking/`
    }

    if (type === 'order_comments') {
        postUrl = `${url}order_comments/`
    }

    if (type === 'update_status') {
        postUrl = `${url}orders/${id}/update_status/${status}/`;
    }

    if (type === 'update_packing_status') {
        postUrl = `${url}orders/${id}/update_packing_status/${status}/`;
    }


    return postUrl;
}

const updateOrderViaAxios = ({
                                 id,
                                 values,
                                 url,
                                 dispatch,
                                 contentText,
                                 method = 'put',
                                 isOrderNote = false,
                                 isFreight = false,
                                 isPackingStatus = false,
                                 picklistFilters = {}
                             }) => {
    if (isOrderNote) {
        dispatch({type: SET_ORDER_NOTES_LOADING, payload: true})
    }

    if (isFreight) {
        dispatch({type: SET_FREIGHT_LOADING, payload: true})
    }

    return axios(
        {
            method,
            url,
            data: values,
            headers: {
                'Authorization': localStorage.getItem('token')?.replaceAll('"', ''),
            }
        })
        .then(() => {
            message.success({content: contentText, duration: 2});
            if (!isOrderNote) {
                if (isFreight) {
                    dispatch({type: SET_FREIGHT_LOADING, payload: false})
                    dispatch({type: SET_FREIGHT_POPUP, payload: {visible: false, order: {}}})
                }

                if (!isPackingStatus) {
                    dispatch(shopActions.getItem({
                        ...orderValues,
                        setItemActionType: SET_ORDER,
                        id
                    }))
                } else {
                    dispatch(picklistActions.getPicklists({
                        params: {
                            summary: 1,
                            ...getParamsFromObject(picklistFilters)
                        }
                    }))
                }


            } else {
                dispatch(orderActions.getComments(id))
            }
        })
        .catch(errors => {
            if (errors.response?.data?.detail) {
                message.error(errors.response?.data?.detail);
            } else {
                message.error(errors.toString());
            }
        });
}

export const orderActions = {
    updateOrderStatus: ({id, values, status, contentText}) => dispatch => {
        return updateOrderViaAxios({
            id,
            url: getUrlByType({type: 'update_status', id, status}),
            values,
            status,
            dispatch,
            contentText
        })
    },
    updatePackingStatus: ({id, values, status, contentText, filters}) => dispatch => {
        return updateOrderViaAxios({
            id,
            url: getUrlByType({type: 'update_packing_status', id, status}),
            values,
            status,
            dispatch,
            contentText,
            isPackingStatus: true,
            picklistFilters: filters
        })
    },
    refundOrder: ({id, values, contentText}) => dispatch => {
        return updateOrderViaAxios({id, url: getUrlByType({type: 'refund', id}), values, dispatch, contentText})
    },
    updateFreightBooking: ({id, values, contentText}) => dispatch => {
        return updateOrderViaAxios({
            id,
            url: getUrlByType({type: 'freight', id}),
            values,
            dispatch,
            contentText,
            method: 'put',
            isOrderNote: false,
            isFreight: true
        })
    },
    updateOrderCustomerDetails: ({id, values, contentText}) => dispatch => {
        return updateOrderViaAxios({id, url: getUrlByType({id}), values, dispatch, contentText})
    },
    addOrderComments: ({values, id, contentText}) => dispatch => {
        return updateOrderViaAxios({
            url: getUrlByType({type: 'order_comments', id}),
            values,
            id,
            dispatch,
            contentText,
            isOrderNote: true,
            method: 'post'
        })
    },
    setOrderPagination: (payload) => (
        {
            type: SET_ORDERS_PAGINATION,
            payload
        }
    ),
    getComments: id => dispatch => {
        return axios.get(`${url}order_comments/?order_id=${id}`, {headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', ''),}})
            .then(res => {
                dispatch({type: SET_ORDER_NOTES, payload: res.data})
            })
            .catch(errors => {
                message.error(errors.response?.data?.detail ?? errors.toString());
            });
    },
    syncTrivec: id => dispatch => {
        return axios.put(`${url}orders/${id}/sync_to_trivec/`, {}, {
            headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}
        })
            .then(() => {
                message.success('Ordern har synkroniserats till Trivec')
            })
            .catch(errors => {
                message.error(errors.response?.data?.detail ?? errors.toString());
            });
    },
    cancelDelivery: id => dispatch => {
        return axios.put(`${url}orders/${id}/cancel_delivery/`, {}, {
            headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}
        })
            .then(() => {
                message.success('Ordern har uppdaterats');
            })
            .catch(errors => {
                message.error(errors.response?.data?.detail ?? errors.toString());
            })
    },
    changeOrderDelivery: (id, values) => dispatch => {
        return axios.put(`${url}orders/${id}/change_delivery/`, values, {
            headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}
        })
            .then(res => {
                message.success('Ordern har uppdaterats')
            })
            .catch(errors => {
                message.error(errors.response?.data?.detail ?? errors.toString());
            });
    },
    setOrdersFiltering: (payload) => (
        {
            type: SET_ORDERS_TABLE_FILTERING,
            payload
        }
    ),
    setOrdersSorting: (payload) => (
        {
            type: SET_ORDERS_TABLE_SORTING,
            payload
        }
    ),
}
