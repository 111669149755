import React, {useState} from 'react';
import {Button, Modal, Popconfirm, Row} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import CustomSelect from "../../CustomSelect/CustomSelect";
import {useSelector} from "react-redux";

import useActions from "../../../../hooks/useActions";
import AttributeNewValueForm from "./AttributeNewValueForm/AttributeNewValueForm";

const AttributeSelect = ({ option, setIsChanged }) => {
    const {setProductAttributesFormValues} = useActions()
    const {defaultAttributes} = useSelector(state => state?.productAttribute)
    const {deleteProductAttributes} = useActions();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const filterOptionsCallback = (input, option) => {
        return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const deleteSelect = (e, id) => {
        deleteProductAttributes(id)
        setIsChanged(true);
    }

    const attrSelectHandler = ({value, id, name}) => {
        setProductAttributesFormValues(
            {
                id,
                name,
                is_variation: true,
                values: [...value]
            }
        )

        setIsChanged(true);
    }

    const defaultSelectValues = (id) => {
        if (defaultAttributes && defaultAttributes.length > 0) {
            const neededAttr = defaultAttributes.find(attr => attr.attribute_id === id)
            return neededAttr ? neededAttr.values.map(value => value.value) : null
        }

        return null;
    }

    return (
        <Row className='attribute-select' justify='space-between' align='middle'>
            <Popconfirm title='Är du säker på att du vill ta bort detta attribut?' okText='Yes' cancelText="No" placement="topRight"
                        onConfirm={(e) => deleteSelect(e, option.id)}>
                <DeleteOutlined className='attribute-select__attr--delete'/>
            </Popconfirm>
            <Row className='attribute-select__attr--choose' justify='space-between' align='middle'>
                <div className='attribute-select__col attribute-select__col--left'>
                    Namn:
                    <span>{option.name}</span>
                </div>
                <div className='attribute-select__col attribute-select__col--right'>
                    <span>Value(s):</span>
                    <CustomSelect
                        {...(defaultSelectValues && defaultSelectValues(option.id) && {defaultValue: defaultSelectValues(option.id)})}
                        onChange={value => attrSelectHandler({value, id: option.id, name: option.name})}
                        fetch={{shouldFetch: false}} selectOptions={option.values}
                        formItem={{isFormItem: false}}
                        optionName={null}
                        optionValue={null}
                        filterOption={filterOptionsCallback}/>
                    <Button onClick={() => setIsModalVisible(true)} style={{marginTop: '10px'}}>Lägg till attributvärde</Button>
                </div>
            </Row>
            <Modal width='500px' open={isModalVisible} footer={false} onCancel={()=> setIsModalVisible(false)}>
                <AttributeNewValueForm attr={option} values={defaultSelectValues(option.id)} setIsModalVisible={setIsModalVisible}/>
            </Modal>
        </Row>
    );
};

export default AttributeSelect;
