const initialState = {
    cacheRes: null,
    loading: false,
}

export const CLEAR_CACHE = 'CLEAR_CACHE';
export const CLEAR_CACHE_LOADING = 'CLEAR_CACHE_LOADING';

const cacheReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_CACHE:
            return {...state, cacheRes: action.payload, loading: false};
        case CLEAR_CACHE_LOADING:
            return {...state, loading: action.payload};
        default:
            return state;
    }
}

export default cacheReducer;
