import React, {useState} from 'react';
import {Button, Form, Input, Row, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../hooks/useActions";
import {getDateAndTime} from "../../../helpers/getDate";
import {getActionTypes} from "../../../helpers/getActionTypes";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditAttributeForm = ({id}) => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const {site, loading} = useSelector(state => state?.sites)
    const navigate = useNavigate();

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('sites'),
            contentText: `Du har uppdaterat webbplatsen ${site?.name}`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/sites');
    };


    if (Object.keys(site).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <Form {...layout}
              name="edit-site"
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>
            <Form.Item
                name='id'
                label="ID"
                initialValue={site.id}
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input bordered={false} readOnly={true}/>
            </Form.Item>
            <Form.Item
                name='datetime_created'
                label="Skapad"
                initialValue={getDateAndTime(site.datetime_created)}
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input bordered={false} readOnly={true}/>
            </Form.Item>
            <Form.Item
                name='datetime_last_updated'
                label="Senast uppdaterad"
                initialValue={getDateAndTime(site.datetime_last_updated)}
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input bordered={false} readOnly={true}/>
            </Form.Item>
            <Form.Item
                name='name'
                label="Namn"
                initialValue={site.name}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name='url'
                label="URL"
                initialValue={site.url}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name='consumer_key'
                label="Consumer key"
                initialValue={site?.consumer_key}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name='consumer_secret'
                label="Consumer secret"
                initialValue={site?.consumer_secret}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input type='password'/>
            </Form.Item>
            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};

export default EditAttributeForm;
