import React from 'react';
import {Col, Form, Switch} from "antd";

const EnableRuleCheckbox = ({fieldIndex}) => {
    return (
        <Col span={2}>
            <span>Aktiv</span>
            <Form.Item name={[fieldIndex, 'enabled']} initialValue={false} valuePropName='checked'>
                <Switch/>
            </Form.Item>
        </Col>
    );
};

export default EnableRuleCheckbox;
