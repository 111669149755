import React from 'react';

const DefaultTableForPrint = ({
                                  beforeTableContent = '',
                                  headRows = [],
                                  bodyContent = '',
                                  afterTableContent = ''
                              }) => {
    return (
        <>
            {beforeTableContent}
            <table className='default-picklist-table' style={{marginTop: '5px'}}>
                <thead>
                <tr>{headRows.map(row => <th key={row}>{row}</th>)}</tr>
                </thead>
                <tbody>
                {bodyContent}
                </tbody>
            </table>
            {afterTableContent}
        </>

    );
};

export default DefaultTableForPrint;
