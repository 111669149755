const initialState = {
    attributes: {
        objects: [],
        total: 0,
        page: 1,
    },
    attribute: {},
    attributesForProducts: [],
    pagination: {
        current: 1,
        pageSize: 25,
    },
    attributesTableFilters: {},
    attributesTableSorting: {},
    loading: false,
}

export const SET_ATTRIBUTE = 'SET_ATTRIBUTE';
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
export const SET_ATTRIBUTES_FOR_PRODUCT = 'SET_ATTRIBUTES_FOR_PRODUCT';
export const SET_ATTRIBUTES_LOADING = 'SET_ATTRIBUTES_LOADING';
export const SET_ATTRIBUTES_PAGINATION = 'SET_ATTRIBUTES_PAGINATION';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const SET_ATTRIBUTES_TABLE_FILTERS = 'SET_ATTRIBUTES_TABLE_FILTERS';
export const SET_ATTRIBUTES_TABLE_SORTING = 'SET_ATTRIBUTES_TABLE_SORTING';

let payload;

const attributesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ATTRIBUTES_LOADING:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_ATTRIBUTES_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_ATTRIBUTES:
            return {...state, attributes: action.payload, loading: false};
        case SET_ATTRIBUTES_FOR_PRODUCT:
            payload = [...state.attributesForProducts, action.payload]

            if (!action.payload) {
                payload = []
            }
            return {...state, attributesForProducts: payload, loading: false};
        case SET_ATTRIBUTE:
            return {...state, attribute: {...action.payload}, loading: false};
        case UPDATE_ATTRIBUTE:
            return {...state, attribute: {...action.payload}, loading: false};
        case DELETE_ATTRIBUTE:
            return {...state, loading: false};
        case SET_ATTRIBUTES_TABLE_FILTERS:
            return {...state, attributesTableFilters: action.payload};
        case SET_ATTRIBUTES_TABLE_SORTING:
            return {...state, attributesTableSorting: action.payload};
        default:
            return state;
    }
}

    export default attributesReducer;
