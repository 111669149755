import {
    SET_PRODUCTS_PAGINATION,
    SET_PRODUCTS_SITE_AND_WAREHOUSES_FILTERS,
    SET_PRODUCTS_SITE_AND_WAREHOUSES_FILTERS_ENABLED,
    SET_PRODUCTS_TABLE_FILTERS,
    SET_PRODUCTS_TABLE_SORTING
} from "../../../reducers/shopReducers/productsReducer";

export const productsActions = {
    setProductsFilters: (payload) => (
        {
            type: SET_PRODUCTS_TABLE_FILTERS,
            payload
        }
    ),
    setSitesAndWarehousesFilters: (payload) => (
        {
            type: SET_PRODUCTS_SITE_AND_WAREHOUSES_FILTERS,
            payload
        }
    ),
    setProductsSiteAndWarehousesFiltersEnabled: (payload) => (
        {
            type: SET_PRODUCTS_SITE_AND_WAREHOUSES_FILTERS_ENABLED,
            payload
        }
    ),
    setProductsSorting: (payload) => (
        {
            type: SET_PRODUCTS_TABLE_SORTING,
            payload
        }
    ),
    setProductsPagination: (payload) => (
        {
            type: SET_PRODUCTS_PAGINATION,
            payload
        }
    )
}
