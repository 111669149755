import React, {useEffect, useState} from 'react';
import QuillEditor from "../QuillEditor";
import {Input, Select, Form} from "antd";

const QuillOrInput = ({field, index, initialValues}) => {
    const [isEditor, setIsEditor] = useState(false);
    const [selectValue, setSelectValue] = useState('input');
    const {Option} = Select;

    const metaValueSelectChangeHandler = (value) => {
        if (value === 'editor') {
            setIsEditor(true);
            setSelectValue('editor')
        } else {
            setIsEditor(false);
            setSelectValue('input')
        }
    }

    useEffect(() => {
        if (initialValues && initialValues.length > 0) {
            if (initialValues[field.key]?.meta_value.includes('<')) {
                setIsEditor(true);
                setSelectValue('editor')
            }
        }
    }, [initialValues, setIsEditor]);


    return (
        <>
            {
                isEditor ?
                    <QuillEditor field={field} index={index}/>
                    :
                    <Form.Item name={[field.name, 'meta_value']}>
                        <Input/>
                    </Form.Item>
            }
           <>
               <span style={{display: 'block', marginBottom: '10px'}}>Välj typ av meta-data</span>
               <Select style={{width: '100%'}} value={selectValue} defaultValue='input' onChange={metaValueSelectChangeHandler}>
                   <Option value='input'>Databas-värde</Option>
                   <Option value='editor'>Textfält</Option>
               </Select>
           </>
        </>


    );
};

export default QuillOrInput;
