const initialState = {
    orders: {
        objects: [],
        total: 0,
        page: 1,
    },
    order: {},
    orderNotes: {
        notes: [],
        notesLoading: false,
    },
    pagination: {
        current: 1,
        pageSize: 25,
    },
    loading: false,
    sorting: {
        date_from: null,
        date_to: null,
    },
    filtering: {
        status: [],
        shipping_method: null
    },
    ordersTableFilters: {},
    ordersTableSorting: {},
}

export const SET_ORDER = 'SET_ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_SORTING = 'SET_ORDERS_SORTING';
export const SET_ORDERS_FILTERING = 'SET_ORDERS_FILTERING';
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const SET_ORDERS_PAGINATION = 'SET_ORDERS_PAGINATION';
export const SET_ORDER_NOTES = 'SET_ORDER_NOTES';
export const SET_ORDER_NOTES_LOADING = 'SET_ORDER_NOTES_LOADING';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const SET_ORDERS_TABLE_FILTERING = 'SET_ORDERS_TABLE_FILTERING';
export const SET_ORDERS_TABLE_SORTING = 'SET_ORDERS_TABLE_SORTING';

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDERS_LOADING:
            return {...state, loading: action.payload};
        case SET_ORDERS_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            };
        case SET_ORDERS:
            return {...state, orders: action.payload, loading: false};
        case SET_ORDERS_SORTING:
            return {...state, sorting: action.payload};
        case SET_ORDERS_FILTERING:
            return {...state, filtering: action.payload};
        case SET_ORDER_NOTES:
            return {...state, orderNotes: {notes: action.payload.objects, notesLoading: false}};
        case SET_ORDER_NOTES_LOADING:
            return {...state, orderNotes: {notes: state.orderNotes.notes, notesLoading: action.payload}};
        case SET_ORDER:
            return {...state, order: {...action.payload}, loading: false};
        case UPDATE_ORDER:
            return {...state, order: {...action.payload}, loading: false};
        case DELETE_ORDER:
            return {...state, loading: false};
        case SET_ORDERS_TABLE_FILTERING:
            return {...state, ordersTableFilters: action.payload};
        case SET_ORDERS_TABLE_SORTING:
            return {...state, ordersTableSorting: action.payload};
        default:
            return state;
    }
}

export default ordersReducer;
