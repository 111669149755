const initialState = {
    isAuth: !!localStorage.getItem('user')
}

export const SET_AUTH = 'SET_AUTH';

const authReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {...state, isAuth: action.payload}
        default:
            return state
    }
}

export default authReducer
