import React, {useEffect} from 'react';
import {getColumns} from "../../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../../ant-table/dashboardColumns";
import {Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import AntTable from "../../../components/AntTable/AntTable";
import {getTextFromType} from "../../../helpers/helpers";
import {getActionTypes} from "../../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../../helpers/getUseSelectorFromType";

const ShippingClasses = ({type = 'shipping_classes', title = 'Taggar'}) => {
    const {getItems, removeItem, setShippingClassesFilters, setShippingClassesSorting, setShippingClassesPagination} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const items = useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]);
    const {loading, pagination} = useSelector(state => state[useSelectorType] && state[useSelectorType]);

    const {
        shippingClassesTableFilters,
        shippingClassesTableSorting
    } = useSelector(state => state?.shippingClasses)

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                params: {
                    ...{...(shippingClassesTableFilters && Object.keys(shippingClassesTableFilters).length > 0 && shippingClassesTableFilters)},
                    ...(shippingClassesTableSorting && Object.keys(shippingClassesTableSorting).length > 0 && shippingClassesTableSorting?.order && {
                        order_by: shippingClassesTableSorting.field,
                        order_type: shippingClassesTableSorting.order.replace('end', '')
                    })
                },
                ...getActionTypes(type)
            });

            getItems({
                ...getActionTypes('sites')
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                filters: shippingClassesTableFilters,
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row className='filter-block' justify='end' align='middle'>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>

                        <AntTable initialData={items} total={items?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setShippingClassesFilters}
                                  setSorting={setShippingClassesSorting}
                                  setPagination={setShippingClassesPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default ShippingClasses;
