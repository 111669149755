import React, {createRef, useState} from 'react';

import {Link} from "react-router-dom";

import {useSelector} from "react-redux";

import {Button, Checkbox, Row, Spin} from "antd";

import {useReactToPrint} from "react-to-print";

import useActions from "../../../../hooks/useActions";

import PickListOrderDetails from "../PickListOrderDetails/PickListOrderDetails";

import DiaRegularTTF from '@fonts/Dia-Regular/Dia-Regular.ttf';
import BatonMediumTTF from '@fonts/Baton-Medium/Baton-Medium.ttf';

const PickListOrderPopupContent = ({isAllOrders = false}) => {
    const {picklistOrderPopup, picklists, filters} = useSelector(state => state?.picklist)
    const {setIsPrintAllButtonPressed, setPickListOrderPopup, updatePackingStatus} = useActions()
    const {selected} = useSelector(state => state?.bulkOperations);
    const [checked, setChecked] = useState(false);
    const ref = createRef()
    const allOrdersRef = createRef();
    const neededOrder = picklists.objects.find(item => item.id === picklistOrderPopup.orderId);
    const {sites, loading} = useSelector(state => state?.sites);

    const print = useReactToPrint({
        content: () => {
            if (checked) {
                const selectedItems = (selected.length > 0 ? selected : picklists?.objects);

                if (selectedItems.length > 0) {
                    selectedItems.forEach(item => {
                        if (item.order_items && Array.isArray(item.order_items) && item.order_items.length > 0) {
                            const allOrderIds = new Set(item.order_items.map(item => item.order_id));

                            for (const orderId of allOrderIds) {
                                updatePackingStatus({
                                    status: 'packed',
                                    id: orderId,
                                    values: {packing_status: 'packed'},
                                    contentText: `Beställning id ${orderId} har uppdaterats`,
                                    filters
                                })
                            }
                        } else {
                            if (item.id) {
                                updatePackingStatus({
                                    status: 'packed',
                                    id: item.id,
                                    values: {packing_status: 'packed'},
                                    contentText: `Beställning id ${item.id} har uppdaterats`,
                                    filters
                                })
                            }
                        }
                    })
                }
            }

            setIsPrintAllButtonPressed(false);
            setPickListOrderPopup({
                visible: false, orderId: null, order: {}
            })

            return isAllOrders ? allOrdersRef.current : ref.current
        },

    })

    const tableCss = `  
        @media all {
          .page-break {
            display: none;
          }
          
        @media print {     
          html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
          }
         
          @font-face {
                font-family: Dia-Regular;
                font-display: swap;
                src: local('Dia-Regular'),
                     url("${DiaRegularTTF}") format("truetype");
           }    
                
          @font-face {
                font-family: Baton-Medium;
                font-display: swap;
                src: local('Baton-Medium'),
                     url("${BatonMediumTTF}") format("truetype");
            }
                
        .pick-list-table__content.sturehof {
            font-family: 'Baton-Medium', Poppins, sans-serif !important;
        }  
        
        .pick-list-table__content.emmer {
            font-family: 'Dia-Regular', Poppins, sans-serif !important;
        }
                    
          body {
            padding: 0px 50px;
            font-family: 'Dia-Regular', sans-serif !important;
          }
          

          body * {
           line-height: 22px !important;
          }
          
          body .font-size-big {
            line-height: 25px !important;
          }
          
          
          body .font-weight-bold b {
            font-weight: 600 !important;
          }
          
          .customer-details h2 {
            display: none;
          }
         
          table {
             width: 100%;
             text-align: left;
          }
        
          table tr {
           border: 1px solid #000;
          }
          
          table thead {
            background-color: #ffffff;
          }
          
          table th, table td {
            padding: 3px;
            white-space: nowrap;
          }
          
          table th:not(:last-child), table td:not(:last-child) {
            border-right: 1px solid #000;
          }
          
          table th:nth-child(2) {
            width: 50%;
          }
          
          .customer-details__item {
            font-size: 15px !important;
            font-weight: 500 !important;
            margin-bottom: 5px;
          }

          .customer-details__item b {
            font-weight: 500 !important;
          }
          
          .ant-table-hidden {
            display: none !important;
          }
          
          .customer-details__item.font-size-big {
            margin-bottom: 10px;
            font-size: 25px !important;
          }
        }
        
        @media print {
          .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: always;
          }
        }
        
        @page {
          size: auto;
        }
    `;

    const cancelButtonHandler = () => {
        setIsPrintAllButtonPressed(false);
        setPickListOrderPopup({
            visible: false,
            orderId: null
        })
    }

    const checkSite = (sites, site_id) => {
        if (!site_id || !sites) return 'Sturehof';
        const foundSite = sites.find(site => +site.id === +site_id);
        return foundSite ? foundSite.name : 'Sturehof';
    }

    if (loading) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        picklists?.objects.length > 0 && picklists?.objects.length > 0 &&
        <>
            {
                isAllOrders ?
                    <>
                        <div style={{padding: '24px'}}>
                            <Button style={{marginRight: '10px'}} type='primary' onClick={print}>Skriv ut
                                packsedlar</Button>
                            <Button onClick={cancelButtonHandler} type='primary' ghost>Avbryt</Button>
                        </div>
                        <Checkbox onChange={(e) => setChecked(e.target.checked)} style={{padding: '0 24px'}}>Sätt
                            automatiskt packstatus till "packad" för samtliga utskrivna ordrar?</Checkbox>
                        <div ref={allOrdersRef}>
                            {
                                (selected.length > 0 ? selected : picklists?.objects).map((orderItem, index) => {
                                    const objectsLength = selected.length > 0 ? selected.length - 1 : picklists?.objects.length - 1;
                                    const isPageBreakEnabled = objectsLength !== index;
                                    return (
                                        <React.Fragment key={orderItem.id}>
                                            <PickListOrderDetails order={orderItem} index={index + 1}
                                                                  site={checkSite(sites?.objects, orderItem.site_id)}
                                                                  total={selected.length > 0 ? selected.length : picklists?.objects?.length}
                                                                  checked={checked}/>
                                            {
                                                isPageBreakEnabled &&
                                                <div className="page-break"/>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                            <style>{tableCss}</style>
                        </div>
                    </>
                    :
                    picklistOrderPopup.orderId && neededOrder &&
                    <>
                        <PickListOrderDetails
                            order={neededOrder}
                            site={checkSite(sites?.objects, neededOrder.site_id)}
                            tableCss={tableCss}
                            total={1}
                            index={1}
                            ref={ref}
                            checked={checked}
                        />
                        <Row style={{padding: '24px'}}>
                            <Button style={{marginRight: '10px'}} type='primary' onClick={print}>Skriv ut
                                packsedlar</Button>
                            <Button><Link to={`/orders/${picklistOrderPopup.orderId}`}>Ändra i order</Link></Button>
                        </Row>
                    </>
            }

        </>
    );
};

export default PickListOrderPopupContent;
