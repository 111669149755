import React from 'react';
import {Button} from "antd";
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";

const Cache = () => {
    const {clearCache} = useActions();
    const {loading} = useSelector(state => state?.cache)

    const clearCacheHandler = () => {
        clearCache({type: 'products', contentText: 'Cache rensades framgångsrikt'})
    }

    return (
        <>
            <h2>Töm produktcache</h2>
            <Button loading={loading} type='primary' onClick={clearCacheHandler}>Töm cache</Button>
        </>
    );
};

export default Cache;
