const initialState = {
    upsellObjects: {
        objects: [],
        total: 0,
        page: 1,
    },
    upsellObject: {},
    shippingClass: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    upsellObjectsTableFilters: {},
    upsellObjectsTableSorting: {},
    loading: false,
}

export const SET_UPSELL_OBJECTS = 'SET_UPSELL_OBJECTS';
export const SET_UPSELL_OBJECT = 'SET_UPSELL_OBJECT';
export const SET_UPSELL_OBJECTS_LOADING = 'SET_UPSELL_OBJECTS_LOADING';
export const SET_UPSELL_OBJECTS_PAGINATION = 'SET_UPSELL_OBJECTS_PAGINATION';
export const UPDATE_UPSELL_OBJECT = 'UPDATE_UPSELL_OBJECT';
export const ADD_UPSELL_OBJECT = 'ADD_UPSELL_OBJECT';
export const REMOVE_UPSELL_OBJECT = 'REMOVE_UPSELL_OBJECT';
export const SET_UPSELL_OBJECTS_TABLE_FILTERS = 'SET_UPSELL_OBJECTS_TABLE_FILTERS';
export const SET_UPSELL_OBJECTS_TABLE_SORTING = 'SET_UPSELL_OBJECTS_TABLE_SORTING';

const upsellObjectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPSELL_OBJECTS_LOADING:
            return {...state, loading: action.payload};
        case SET_UPSELL_OBJECTS_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_UPSELL_OBJECTS:
            return {...state, upsellObjects: action.payload, loading: false};
        case SET_UPSELL_OBJECT:
            return {...state, upsellObject: {...action.payload}, loading: false};
        case UPDATE_UPSELL_OBJECT:
            return {...state, upsellObject: {...action.payload}, loading: false};
        case ADD_UPSELL_OBJECT:
            return {...state, upsellObject: {...action.payload}, loading: false};
        case REMOVE_UPSELL_OBJECT:
            return {...state, loading: false};
        case SET_UPSELL_OBJECTS_TABLE_FILTERS:
            return {...state, upsellObjectsTableFilters: action.payload};
        case SET_UPSELL_OBJECTS_TABLE_SORTING:
            return {...state, upsellObjectsTableSorting: action.payload};
        default:
            return state;
    }
}

export default upsellObjectsReducer
