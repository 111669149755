const initialState = {
    chooseImages: [],
    page: 1,
    total: 0,
    loading: false,
}

export const SET_CHOOSE_IMAGES = 'SET_CHOOSE_IMAGES'
export const SET_CHOOSE_IMAGES_MORE = 'SET_CHOOSE_IMAGES_MORE'
export const SET_CHOOSE_IMAGES_LOADING = 'SET_CHOOSE_IMAGES_LOADING'
export const SET_CHOOSE_IMAGES_PAGE = 'SET_CHOOSE_IMAGES_PAGE'

const chooseImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHOOSE_IMAGES:
            return {...state, chooseImages: action.payload, loading: false};
        case SET_CHOOSE_IMAGES_MORE:
            return {...state, chooseImages: [...state.chooseImages, ...action.payload], loading: false};
        case SET_CHOOSE_IMAGES_LOADING:
            return {...state, loading: action.payload};
        case SET_CHOOSE_IMAGES_PAGE:
            return {...state, page: action.payload};
        default:
            return state
    }
}

export default chooseImageReducer;
