import React, {useContext, useEffect, useRef, useState} from 'react';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import {EditableContext} from "../EditableRow/EditableRow";
import {getSwitchSitesForProducts} from "../../../../helpers/getSwitchSitesForProducts";

    const EditableCell = ({
                              title,
                              editable,
                              children,
                              dataIndex,
                              record,
                              inputType,
                              options,
                              handleSave,
                              defaultValue = null,
                              ...restProps
                          }) => {
        const [editing, setEditing] = useState(inputType === 'switch');
        const productSite = record?.sites?.find( item => item.site_id.toString() === restProps.site_id )
        const [switcher, setSwitcher] = useState(productSite ? productSite.active : false )
        const [isValueChanged, setIsValueChanged] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing && inputRef.current) {
                inputRef.current.focus();
            }
        }, [editing]);

        useEffect(() => {
            if (inputType === 'switch' && isValueChanged) {
               save().then(() => {
                   setIsValueChanged(false)
               })
            }
        }, [switcher, isValueChanged]);


        const toggleEdit = () => {
            if ( inputType !== 'switch' ) {
                setEditing(prev => !prev);
            }

            if ( dataIndex.includes( 'disposable_quantity' ) ) {
                const warehouseId = dataIndex.split('_')[2]
                form.setFieldsValue({
                    [dataIndex]: record?.disposable_quantities?.reduce( ( acc, item ) => {
                        if ( warehouseId ) {
                            return item.warehouse_id.toString() === warehouseId ? acc + item.disposable_quantity : acc;
                        }
                        return acc + item.disposable_quantity;
                    }, 0 ),
                });
            } else {
                if (inputType === 'switch') {
                    form.setFieldsValue({
                        sites: getSwitchSitesForProducts(record, restProps, switcher, dataIndex),
                    });

                } else {
                    form.setFieldsValue({
                        [dataIndex]: record[dataIndex],
                    });
                }

            }
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                if ( dataIndex.includes( 'disposable_quantity' ) ) {
                    const warehouse_id = Number( dataIndex.split('_')[2] );
                    const disposable_quantity = values[dataIndex];
                    const currentQty = record?.disposable_quantities?.reduce( ( acc, item ) => {
                        if ( warehouse_id && item.warehouse_id !== warehouse_id ) {
                            return acc;
                        }

                        return acc + item.disposable_quantity;
                    }, 0 )

                    if ( disposable_quantity !== currentQty && warehouse_id ) {
                        const { id, sku } = record;
                        handleSave( { id, sku, disposable_quantity, warehouse_id }, true );
                    }

                    setEditing(false)
                } else {
                    if (record[dataIndex] !== values[dataIndex]) {
                        toggleEdit();
                        handleSave({ ...record, ...values }, dataIndex === 'disposable_quantity');
                    } else {
                        setEditing(false)
                    }
                }
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let inputNode = <Input ref={inputRef} onPressEnter={save} onBlur={save}/>;

        if ( inputType === 'number' ) {
            inputNode = <InputNumber ref={inputRef} onPressEnter={save} onBlur={save}/>;
        } else if ( inputType === 'switch' ) {
            inputNode = <Switch ref={inputRef} onChange={ () => {setSwitcher(prev => !prev); setIsValueChanged(true)} } checked={ switcher } />
        }

        let childNode = children;

        const rules = [
            {
                required: false,
                message: `${title} krävs.`,
            }
        ]

        if (inputType === 'number' && dataIndex !== 'disposable_quantity') {
            rules.push({
                pattern: /^(\d+\.)?\d+$/,
                message: 'Not valid number'
            })
        }

        if (editable) {
            childNode = inputType === 'switch' || editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={rules}
                >
                    {
                        inputType === 'select' ?
                            <Select
                                ref={inputRef}
                                mode="single"
                                onBlur={save}
                                onChange={save}
                                bordered={false}
                                placeholder="Synlighet i katalogvy"
                                {...defaultValue && {defaultValue}}
                            >
                                {options.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
                            </Select> :
                            inputNode
                    }
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    export default EditableCell
