import React from 'react';
import {Col, Form, InputNumber} from "antd";

const OrderLimitItems = ({fieldIndex}) => {
    return (
        <Col span={4}>
            <span>Max ordrar</span>
            <Form.Item name={[fieldIndex, 'order_limit']} initialValue={-1}>
                <InputNumber />
            </Form.Item>
            <span>Produktionstid (minuter) </span>
            <Form.Item name={[fieldIndex, 'production_delay']} initialValue={60}>
                <InputNumber/>
            </Form.Item>
            <span>Leveransintervall (minuter) </span>
            <Form.Item name={[fieldIndex, 'delivery_interval']} initialValue={60}>
                <InputNumber/>
            </Form.Item>
        </Col>
    );
};

export default OrderLimitItems;
