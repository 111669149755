import React, {forwardRef} from 'react';

import PickListCustomerOrderDetails from "../PickListCustomerOrderDetails/PickListCustomerOrderDetails";
import PickListPrintTable from "../PickListPrintTable/PickListPrintTable";
import DefaultTableForPrint from "../PickListPrintTable/DefaultTableForPrint";

import SturehofLogo from './sturehof-logo.png';
import EmmerLogo from './emmer-logo.png';

import DiaRegularTTF from '@fonts/Dia-Regular/Dia-Regular.ttf';
import BatonMediumTTF from '@fonts/Baton-Medium/Baton-Medium.ttf';

const PickListOrderDetails = forwardRef(({
                                             order,
                                             checked,
                                             tableCss,
                                             index,
                                             total,
                                             site
                                         }, ref) => {

    const logoSrc = site === 'Sturehof' ? SturehofLogo : EmmerLogo;

    return (
        <div>
            <div className={`pick-list-table__content ${site?.toLowerCase()}`} ref={ref} style={{padding: '24px 24px 24px'}}>
                <link rel="preload" href={site?.toLowerCase() === 'sturehof' ? BatonMediumTTF : DiaRegularTTF} as="font" crossOrigin="anonymous"/>
                <div style={{marginBottom: '15px', textAlign: 'center'}}>
                    <img style={{width: site === 'Sturehof' ? '376px' : '389px'}} src={logoSrc} alt='logo'/>
                </div>
                <div style={{textAlign: 'right', marginBottom: '55px', fontSize: '18px'}}>{index}/{total}</div>
                <PickListCustomerOrderDetails order={order}/>
                <DefaultTableForPrint headRows={['Ordernummer','Produkt','Leverans','Antal']}
                                      bodyContent={
                                        order && order.order_items && order.order_items.length > 0 &&
                                            order.order_items.map((orderItem, i) => {
                                                return (
                                                    <tr key={orderItem.name + '_' + i}>
                                                        <td>{order.external_order_id}</td>
                                                        <td>{orderItem.name}</td>
                                                        <td>{order.shipping_method_name}</td>
                                                        <td>{orderItem.quantity}</td>
                                                    </tr>
                                                )
                                            })}
                                    afterTableContent={
                                        <div className='not-visible' style={{textAlign: 'right', margin: '20px 0'}}>
                                            <b>Totalt:</b> {order.total} {order.currency} ({order.order_items?.reduce((prev, item) => prev + item.quantity, 0)} varor)
                                        </div>
                                    }
                />
                <style>{tableCss}</style>
            </div>
            <div className='ant-table-hidden' style={{padding: '0 24px 24px', marginTop: '-4px'}}>
                <PickListPrintTable order={order} checked={checked}/>
                <div style={{textAlign: 'right', margin: '20px 0'}}>
                    <b>Totalt:</b> {order.total} {order.currency} ({order.order_items?.reduce((prev, item) => prev + item.quantity, 0)} varor)
                </div>
            </div>
        </div>
    );
});

export default PickListOrderDetails;
