import React from 'react';
import DateFormItems from "./DateFormItems/DateFormItems";
import TimeRangeItems from "./TimeRangeItems/TimeRangeItems";
import moment from "moment";
import OrderLimitItems from "./OrderLimitItems/OrderLimitItems";
import EnableRuleCheckbox from "./EnableRuleCheckbox/EnableRuleCheckbox";
import HiddenIdsFormItems from "./HiddenIdsFormItems/HiddenIdsFormItems";

const AvailabilityRuleForm = ({ index, fieldIndex, data, shippingClassId, shippingMethodId }) => {
    const format = 'HH:mm';
    const timeData = data[fieldIndex] ? data[fieldIndex].time_ranges?.map(time => (
        {
            time_from: moment(time.time_from, format),
            time_to: moment(time.time_to, format)
        }
    )) : []

    return (
       <>
           <HiddenIdsFormItems fieldIndex={fieldIndex} shippingClassId={shippingClassId} shippingMethodId={shippingMethodId}/>
           <EnableRuleCheckbox index={index} fieldIndex={fieldIndex}/>
           <DateFormItems index={index} fieldIndex={fieldIndex} data={data[fieldIndex]}/>
           <TimeRangeItems format={format} index={index} fieldIndex={fieldIndex} time={timeData}/>
           <OrderLimitItems fieldIndex={fieldIndex}/>
       </>
    );
};

export default AvailabilityRuleForm;
