import {SET_RANGE_CHANGED, SET_RANGE_DATE} from "../../reducers/rangeDateReducer/rangeDateReducer";

export const rangeDateActions = {
    setRangeDate: (payload) => ({
            type: SET_RANGE_DATE,
            payload
    }),
    setRangeDateChanged: (payload) => ({
            type: SET_RANGE_CHANGED,
            payload
    }),
}
