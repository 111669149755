import React, {useEffect, useState} from 'react';
import {Button, Row, Select} from "antd";
import {FilterFilled} from "@ant-design/icons";
import useActions from "../hooks/useActions";
import CustomSelect from "../components/Form/CustomSelect/CustomSelect";
import {getUseSelectorFromType} from "./getUseSelectorFromType";
import {useSelector} from "react-redux";
import {getActionTypes} from "./getActionTypes";

const getFiltersDropdown = ({
                                dataIndex = '',
                                values = [],
                                filterText = 'status',
                                isCustomSelect = false,
                                customSelectMode = 'multiple',
                                type = null,
                                fetchType = null,
                                optionName='name',
                                optionValue='id',
                            }) => {
    const {setIsTableFiltered, getItems} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const tableFiltersType = useSelectorType + 'TableFilters';
    const tableFilters = useSelector(state => state[useSelectorType][tableFiltersType])
    const [value, setValue] = useState(
        isCustomSelect ?
            ( (tableFilters && tableFilters[dataIndex]) ? (customSelectMode === 'multiple' ? [tableFilters[dataIndex]] : tableFilters[dataIndex]) : [])
        :
        ((tableFilters && tableFilters[dataIndex]) ? tableFilters[dataIndex] : ''));
    const [iconColor, setIconColor] = useState(null)

    const filterHandler = (confirm, setSelectedKeys) => {
        const isEmpty = !value || (Array.isArray(value) && value.length === 0)
        if (isEmpty) return;
        setSelectedKeys([isCustomSelect ? (customSelectMode === 'multiple' ? value.join(',') : value) : value])
        confirm({closeDropdown: true});
        setIconColor('#1890ff')
        setIsTableFiltered(false);
    }

    const clearFiltersHandler = (confirm, setSelectedKeys, clearFilters) => {
        if (isCustomSelect) {
            setValue(customSelectMode === 'multiple' ? [] : null)
        } else {
            setValue('')
        }

        setSelectedKeys([])
        clearFilters();
        setIconColor(null)
        confirm({closeDropdown: true});
        getItems({
            ...getActionTypes(fetchType ?? type)
        })
    }

    return {
        filterDropdown: ({setSelectedKeys, confirm, clearFilters}) => {
            useEffect(() => {
                if (tableFilters && Object.keys(tableFilters).length === 0) {
                    setValue(null)
                    setIconColor(null);
                }
            }, [tableFilters])

            return (
                <Row id={dataIndex + '_filter_dropdown'} style={{maxWidth: '300px', width: '100%', padding: '10px'}}>
                    <span style={{display: 'block', marginBottom: '10px'}}>Filtrera på {filterText}</span>
                    {
                            isCustomSelect ?
                                <div style={{width: '100%'}}>
                                    <CustomSelect modeType={customSelectMode} style={{width: '100%', marginBottom: '10px'}}
                                                 value={optionValue === 'id' ? +value : value}
                                                  fetch={
                                                      {
                                                          shouldFetch: true,
                                                          paramType: 'search',
                                                          params: {...{...tableFilters && Object.keys(tableFilters).length > 0 && tableFilters[dataIndex] && {ids: value}}}
                                                      }
                                                  }
                                                  optionName={optionName}
                                                  optionValue={optionValue}
                                                  onChange={value => setValue(value)}
                                                  type={fetchType}
                                                  name={dataIndex} />
                                </div>
                                :
                                <Select value={value} style={{width: '100%', marginBottom: '10px'}}
                                        onChange={(value) => setValue(value)}>
                                    {
                                        values.map(val => <Select.Option key={val.value} value={val.value}>{val.text}</Select.Option>)
                                    }
                                </Select>
                    }

                    <Row>
                        <Button style={{marginRight: '10px'}} type='primary'
                                onClick={() => filterHandler(confirm, setSelectedKeys)}>Aktivera filter</Button>
                        <Button type='primary' ghost
                                onClick={() => clearFiltersHandler(confirm, setSelectedKeys, clearFilters)}>Rensa
                            filter</Button>
                    </Row>
                </Row>
            )
        },
        filteredValue: value,
        filterIcon: () => {
            return <FilterFilled className='filter-dropdown-icon' id={dataIndex} style={{...iconColor && {color: iconColor}}}/>
        },
    }
}

export default getFiltersDropdown;
