import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Row, Select} from "antd";
import useActions from "../../../../hooks/useActions";
import {DatePicker} from "antd";
import {useSelector} from "react-redux";
import locale from "antd/es/date-picker/locale/sv_SE";
import {useParams} from "react-router-dom";
import CustomSelect from "../../../Form/CustomSelect/CustomSelect";
import {getActionTypes} from "../../../../helpers/getActionTypes";
import {cleanPayload} from "../../../../helpers/helpers";

const layout = {
	labelCol: {
		span: 7,
	},
	wrapperCol: {
		span: 18,
	},
};

const BookFreightPopup = () => {
    const {setBookFreightPopup, updateFreightBooking, getItems} = useActions()
    const {isFreightPopupVisible, loading} = useSelector(state => state?.bookFreight)
    const order = useSelector(state => state?.orders?.order);
    const {id} = useParams();

    let shippingMethods = useSelector(state => state?.shippingMethods?.shippingMethods?.objects);

    if (shippingMethods && shippingMethods.length > 0 && order) {
        shippingMethods = shippingMethods.filter(method => +method.site_id === +order?.site?.site_id && method.shipping_type === 'home' && ! method.machine_name.includes("gordon"))
    }

    useEffect(() => {
        getItems({...getActionTypes('shipping_methods')})
    }, [])

    const [form] = Form.useForm();
    const [shippingMethod, setShippingMethod] = useState({
        name: null,
        home_delivery_type: null
    });
    const delivery_time_intervals = [
            {
                value: '11:00-12:00',
                key: '11:00-12:00'
            },
            {
                value: '12:00-13:00',
                key: '12:00-13:00'
            },
            {
                value: '13:00-14:00',
                key: '13:00-14:00'
            },
            {
                value: '14:00-15:00',
                key: '14:00-15:00'
            },
            {
                value: '15:00-16:00',
                key: '15:00-16:00'
            },
            {
                value: '16:00-17:00',
                key: '16:00-17:00'
            },
            {
                value: '17:00-18:00',
                key: '17:00-18:00'
            }
    ]

    const finishHandler = ( _values ) => {
        const values = {..._values};

        if (shippingMethod.name) {
            values.shipping_method_name = shippingMethod.name
        }

        if (shippingMethod.home_delivery_type) {
            values.shipping_method_type = shippingMethod.home_delivery_type
        }

        updateFreightBooking({values: cleanPayload(values), id, contentText: 'Fraktmetod har uppdaterats'})
        form.resetFields();
        location.reload(true);
	};

    const onCancelPopup = () => {
        setBookFreightPopup({visible: false, order: {}})
    }

    const onSelectChange = (value) => {
        if (shippingMethods.length > 0) {
            const _shippingMethod = shippingMethods.find(method => +method.id === +value);
            _shippingMethod && setShippingMethod(_shippingMethod)
        }
    }


    return (
        <Modal className='book-freight-popup' width='600px' open={isFreightPopupVisible} onCancel={onCancelPopup} footer={false}>
            <Form form={form} { ...layout } name="edit-attribute" onFinish={ finishHandler }>
                <h2>Boka frakt</h2>
                <Form.Item name='shipping_method_id' label="Leveransmetod" rules={ [{required: true}] }>
                    <Select onChange={onSelectChange}>
                        {
                            shippingMethods?.map(method => (
                                <Select.Option key={method.id} value={method.id}>
                                    {method.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item hidden={true} name='shipping_method_name'>
                    <Input/>
                </Form.Item>
                <Form.Item hidden={true} name='shipping_method_type'>
                    <Input/>
                </Form.Item>

                <Form.Item name='delivery_date' label="Leveransdatum" rules={ [{required: true,},] }>
                    <DatePicker allowClear={false} locale={locale} format="YYYY-MM-DD"/>
                </Form.Item>
                <Form.Item name='delivery_time_interval' label="Leveranstid" rules={ [{required: true,},] }>
                    <Select>
                        {
                            delivery_time_intervals.map(option => {
                                return <Select.Option key={option + '_' + Math.random()} value={option.key}>{ option.label ?? option.value}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={ { ...layout.wrapperCol, offset: layout.labelCol.span } }>
                    <Row justify="space-between">
                    <Button type="primary" loading={loading} htmlType="submit">
                        Boka
                    </Button>
                    <Button type="primary" ghost onClick={ onCancelPopup }>
                        Gå tillbaka
                    </Button>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BookFreightPopup;
