export const dashboardBulkFormElements = {
    stockObjectsBulkFormElements: [
        {
            name: 'disposable_quantity',
            required: false,
            label: 'Disponibelt lager',
            type: 'number',
        }
    ],
    ordersBulkFormElements: [
        {
            name: 'status',
            required: true,
            label: 'Status',
            type: 'select',
            options: [
                {
                    value: 'Behandlas',
                    key: 'processing'
                },
                {
                    value: 'Färdigbehandlad',
                    key: 'completed'
                },
                {
                    value: 'Avbruten',
                    key: 'cancelled'
                }
            ]
        }
    ],
    productsBulkFormElements: [
        {
            name: 'catalog_visibility',
            required: false,
            label: 'Synlighet i katalogvy',
            type: 'select',
            options: [
                {
                    value: 'Synlig',
                    key: 'visible'
                },
                {
                    value: 'Katalog',
                    key: 'catalog'
                },
                {
                    value: 'Sök',
                    key: 'search'
                },
                {
                    value: 'Gömd',
                    key: 'hidden'
                }
            ]
        },
        // {
        //     name: 'price',
        //     required: false,
        //     label: 'Pris',
        //     type: 'number',
        // },
        // {
        //     name: 'sales_price',
        //     required: false,
        //     label: 'REA-pris',
        //     type: 'number',
        // },
        // {
        //     name: 'purchase_price',
        //     required: false,
        //     label: 'Inköpspris',
        //     type: 'number',
        // },
        {
            name: 'categories',
            required: false,
            label: 'Kategori',
            type: 'multiple',
        },
    ],
    picklistsBulkFormElements: [
        {
            name: 'packing_status',
            required: false,
            label: 'Packstatus',
            type: 'select',
            options: [
                {
                    key: 'packed',
                    value: 'Packed'
                },
                {
                    key: 'not_packed',
                    value: 'Not packed'
                }
            ]
        }
    ]
}
