const initialState = {
    warehouses: {
        objects: [],
        total: 0,
        page: 1,
    },
    warehouse: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    loading: false,
}

export const SET_WAREHOUSE = 'SET_WAREHOUSE';
export const SET_WAREHOUSES = 'SET_WAREHOUSES';
export const SET_WAREHOUSES_LOADING = 'SET_WAREHOUSES_LOADING';
export const SET_WAREHOUSES_PAGINATION = 'SET_WAREHOUSES_PAGINATION';
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';

const warehousesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WAREHOUSES_LOADING:
            return {...state, loading: action.payload};
        case SET_WAREHOUSES_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_WAREHOUSES:
            const {objects, ...restPropertiesPayload} = action.payload;

            /**
             * Hidden warehouse "Täby" and "Garnisonen"
             * @wrike https://www.wrike.com/open.htm?id=1304107934
             */
            const filteredPayload = action.payload.objects.filter(obj => obj.name !== 'Garnisonen' && obj.name !== 'Täby');

            restPropertiesPayload.objects = filteredPayload;
            return {...state, warehouses: restPropertiesPayload, loading: false};
        case SET_WAREHOUSE:
            return {...state, warehouse: {...action.payload}, loading: false};
        case UPDATE_WAREHOUSE:
            return {...state, warehouse: {...action.payload}, loading: false};
        case DELETE_WAREHOUSE:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default warehousesReducer;
