import React from 'react';
import {
    FileImageOutlined,
    FileTextOutlined,
    CarOutlined,
    ShopOutlined, SwitcherOutlined,
    UnorderedListOutlined, CloudServerOutlined,
} from "@ant-design/icons";

const menu = [
    {
        name: 'products',
        title: 'PIM',
        parent: true,
        icon: <ShopOutlined />,
        children: [
            {
                name: 'all_products',
                link: 'products',
                title: 'Produkter'
            },
            {
                name: 'attributes',
                link: 'attributes',
                title: 'Attribut'
            },
            {
                name: 'categories',
                link: 'categories',
                title: 'Kategori'
            },
            {
                name: 'tags',
                link: 'tags',
                title: 'Taggar'
            },
            {
                name: 'stock_objects',
                link: 'stock_objects',
                title: 'Lagerobjekt'
            },
            {
                name: 'upsell-objects',
                link: 'upsell-objects',
                title: 'Tilläggsköp'
            }
        ]
    },
    {
        name: 'all_orders',
        title: 'Ordrar',
        link: 'orders',
        icon: <FileTextOutlined />
    },
    {
        name: 'shipping-methods',
        title: 'Frakt',
        parent: true,
        icon: <CarOutlined />,
        children: [
            {
                name: 'shipping-methods',
                link: 'shipping-methods',
                title: 'Fraktmetoder'
            },
            {
                name: 'shipping-base-addresses',
                link: 'shipping-base-addresses',
                title: 'Avsändaradresser'
            },
            {
                name: 'shipping-classes',
                link: 'shipping-classes',
                title: 'Fraktklasser'
            }
        ]
    },
    {
        name: 'all_images',
        title: 'Bilder',
        link: 'images',
        icon: <FileImageOutlined />,
    },
    {
        name: 'all_sites',
        title: 'Försäljningsställen',
        link: 'sites',
        icon: <SwitcherOutlined />,
    },
    {
        name: 'pick-lists',
        title: 'Plocklista',
        link: 'pick-lists',
        icon: <UnorderedListOutlined />,
    },
    {
        name: 'cache',
        title: 'Cache',
        link: 'cache',
        icon: <CloudServerOutlined />,
    }
]

export default menu;

/*
{
        name: 'all_users',
        title: 'Användare',
        link: 'user',
        icon: <UserOutlined />,
    },
    {
        name: 'all_rights',
        title: 'Rättigheter',
        link: 'rights',
        icon: <LockOutlined />,
    }
 */
