import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import * as AWS from 'aws-sdk/global';

export const POOL_KEY = process.env.COGNITO_POOL_KEY;
export const IdentityPoolId = process.env.COGNITO_IDENTITY_POOL_ID;
AWS.config.region = process.env.AWS_REGION;

const getPoolData = () => {
    return {
        UserPoolId: process.env.COGNITO_USER_POOL_ID,
        ClientId: process.env.COGNITO_CLIENT_ID
    }
}

export const getSession = async (user) => {
    await new Promise((resolve, reject) => {
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    reject(new Error(err.toString()));
                } else {
                    resolve(session);
                }
            })
        } else {
            reject('Okänt sessionsfel');
        }
    })
}

export const getPool = () => {
    return new CognitoUserPool(getPoolData())
}

const getCognitoData = async ({username, password}) => {
    return new Promise(function(resolve, reject) {
        const user = new CognitoUser({
            Username: username,
            Pool: getPool()
        })

        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        })

        user.authenticateUser(authDetails, {
            onSuccess: (res) => {
                let token = res.getIdToken().getJwtToken();
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId,
                    Logins: {
                        [POOL_KEY]: token,
                    },
                });
                AWS.config.credentials.refresh(error => {
                    if (error) {
                        reject(new Error(error.toString()));
                    } else {
                        resolve({
                            token,
                            expire: res.getIdToken().getExpiration(),
                            pool: getPool(),
                            user: getPool().getCurrentUser()
                        })
                    }
                });
            },
            onFailure: (error) => {
                reject(new Error(error));
            },
            newPasswordRequired: (userAttributes) => {
                console.warn('password required')
                resolve({
                    ...resolve,
                    user: getPool().getCurrentUser(),
                    isFirstLogin: true,
                    userAttributes
                })
            },
            totpRequired: (secretCode) => {
                console.warn('totpRequired required')

                const mfaCode = prompt('Ange din TOTP-kod.');
                resolve({
                    isTotpRequired: true,
                    mfaCode,
                    secretCode,
                    username,
                    password,
                    user
                })
            },
            mfaRequired: (codeDeliveryDetails) => {
                console.warn('mfaCode required')
                const mfaCode = prompt('Ange din MFA-kod du fått på SMS.');
                resolve({
                    isMfaRequired: true,
                    mfaCode,
                    username,
                    password,
                    user
                })
            }
        })
    })
}

export default getCognitoData;
