import {
    SET_TAGS_PAGINATION,
    SET_TAGS_TABLE_FILTERS,
    SET_TAGS_TABLE_SORTING
} from "../../../reducers/shopReducers/tagsReducer";

export const tagsActions = {
    setTagsFilters: (payload) => (
        {
            type: SET_TAGS_TABLE_FILTERS,
            payload
        }
    ),
    setTagsSorting: (payload) => (
        {
            type: SET_TAGS_TABLE_SORTING,
            payload
        }
    ),
    setTagsPagination: (payload) => (
        {
            type: SET_TAGS_PAGINATION,
            payload
        }
    )
}
