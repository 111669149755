import React, {useEffect, useRef, useState} from 'react';

import {Button, Form, Input, InputNumber, Row, Select, Spin, Checkbox} from 'antd';

import {useSelector} from 'react-redux';

import {useNavigate} from 'react-router-dom';

import {SET_PRODUCT} from "@reduxStore/reducers/shopReducers/productsReducer";

import useActions from "@hooks/useActions";

import {getFormatDate, getFormatTime} from "@helpers/getDate";
import {getActionTypes} from "@helpers/getActionTypes";

import AttributesSelect from "@components/Form/AttributesSelect/AttributesSelect";
import RepeaterFields from "@components/Form/RepeaterFields/RepeaterFields";
import TagSelect from "@components/Form/TagSelect/TagSelect";
import FormImages from "@components/Form/ProductImages/FormImages/FormImages";
import QuillEditor from "@components/Form/../QuillEditor/QuillEditor";
import StockTable from "@components/Form/Stock/StockTable/StockTable";
import CustomSelect from "@components/Form/CustomSelect/CustomSelect";
import SitesFieldTable from "@components/SitesFieldTable/SitesFieldTable";
import ProductBadge from "@components/Form/ProductBadge/ProductBadge";
import {ProductsSelect} from "@components/Products/ProductsSelect/ProductsSelect";

import "./EditProductForm.scss";

import moment from "moment";

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const format = 'YYYY-MM-DD HH:mm:ss';

const EditProductForm = ({id}) => {
    const {updateItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const {product, loading} = useSelector(state => state?.products);
    const formRef = useRef();
    const {Option} = Select;
    const navigate = useNavigate();
    const acfFields = [
        {name: '_drinking_suggestion', label: 'Dryckesförslag'},
        {name: '_recipe', label: 'Tips'},
        {name: '_ingredients', label: 'Innehåll'},
        {name: '_more_info', label: 'Mer Info'},
    ];

    useEffect(() => {
        return () => {
            setItemNull(SET_PRODUCT);
        };
    }, []);

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        const newValues = {...values};

        const newMetaArray = Object.keys(newValues)
            .filter(key => (key === '_drinking_suggestion' && newValues[key]) || (key === '_ingredients' && newValues[key]) || (key === '_more_info' && newValues[key]) || (key === '_recipe' && newValues[key]))
            .map(key => {
                return {
                    meta_key: key,
                    meta_value: newValues[key]
                }
            })

        const {_drinking_suggestion, _ingredients, _more_info, _recipe, ...restValues} = newValues;
        const metaData = restValues.meta_data ? (restValues.meta_data.length === 0 ? [] : restValues.meta_data) : [];

        restValues.meta_data = [
            ...metaData.filter(item => item.meta_key !== '_drinking_suggestion' && item.meta_key !== '_ingredients' && item.meta_key !== '_more_info' && item.meta_key !== '_recipe'),
            ...newMetaArray
        ];

        if (!restValues.sales_price) {
            restValues.sales_price = "";
        }

        if (restValues.description) {
            restValues.description = restValues.description.replaceAll('<p><br></p>', '<br>')
        }

        if (restValues.short_description) {
            restValues.short_description = restValues.short_description.replaceAll('<p><br></p>', '<br>')
        }

        restValues.external_products = restValues.external_products.map(item => {
            const {active, time_publish, time_publish_from, time_publish_to, ...rest} = item;
            let publishFrom = null;
            let publishTo = null;

            if (time_publish && time_publish_from) {
                publishFrom = `${getFormatDate(time_publish_from._d)} ${getFormatTime(time_publish_from._d)}`
            }

            if (time_publish && time_publish_to) {
                publishTo = `${getFormatDate(time_publish_to._d)} ${getFormatTime(time_publish_to._d)}`
            }

            return {
                ...rest,
                active: typeof active === 'undefined' || typeof active === null ? false : active,
                time_publish,
                ...{
                    ...publishFrom && {
                        time_publish_from: publishFrom,
                        time_publish_to: publishTo
                    }
                }
            }
        })

        void updateItem({
            values: restValues,
            id,
            ...getActionTypes('products'),
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/products');
    };

    if (Object.keys(product).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin
            size='medium'/></Row>;
    }

    if (product?.badge) {
        if (!product?.badge.text_color) {
            product.badge.text_color = '#FFFFFF';
        }
        if (!product?.badge.color) {
            product.badge.color = '#E6003F';
        }
    }

    /**
     * For DatePicker initialValues fixed according to moment library
     */

    if (product.external_products && product.external_products?.length > 0) {
        product.external_products.map(site => {
            if (site.time_publish_from && site.time_publish_to) {
                let dateFrom;
                let dateTo;
                let publishFrom = site.time_publish_from;
                let publishTo = site.time_publish_to;

                if (typeof site.time_publish_from === 'string') {
                    dateFrom = new Date(site.time_publish_from.replace(' ', 'T'));
                    publishFrom = moment(`${getFormatDate(dateFrom)} ${getFormatTime(dateFrom)}`, format);
                }

                if (typeof site.time_publish_to === 'string') {
                    dateTo = new Date(site.time_publish_to.replace(' ', 'T'));
                    publishTo = moment(`${getFormatDate(dateTo)} ${getFormatTime(dateTo)}`, format);

                }

                site.time_publish_from = publishFrom
                site.time_publish_to = publishTo
            } else {
                if (site.time_publish_from) {
                    let dateFrom;
                    let publishFrom = site.time_publish_from;
                    if (typeof site.time_publish_from === 'string') {
                        dateFrom = new Date(site.time_publish_from.replace(' ', 'T'));
                        publishFrom = moment(`${getFormatDate(dateFrom)} ${getFormatTime(dateFrom)}`, format);

                    }
                    site.time_publish_from = publishFrom
                } else {
                    site.time_publish_from = null;
                    site.time_publish_to = null;
                }
            }

            return site;
        })
    }

    return (
        <>
            <Form {...layout}
                  ref={formRef}
                  data-testid='edit-product-form'
                  name="edit-product"
                  initialValues={{
                      images: product?.images?.map(img => img.id),
                      description: product?.description ?? '',
                      short_description: product?.short_description,
                      featured_image: product?.featured_image?.id,
                      shipping_class: product?.shipping_class?.id,
                      badge: {...product?.badge},
                  }}
                  style={{width: '100%'}}
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}
                  validateMessages={validateMessages}
            >

                <SitesFieldTable item={product} shouldAddTimePublish={true}/>

                <Form.Item
                    name='title'
                    label="Namn"
                    initialValue={product?.title}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name='sku'
                    label="SKU"
                    initialValue={product?.sku}
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name='product_type'
                    label="Typ"
                    initialValue={product?.product_type}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select>
                        {[
                            'simple'
                        ].map(option => <Option key={option}>{option}</Option>)}
                    </Select>
                </Form.Item>

                <QuillEditor isAcf={true} field={{label: "Kort beskrivning", name: 'short_description'}}
                             initialValue={product?.short_description}
                             setIsChanged={setIsChanged}
                />

                <QuillEditor isAcf={true} field={{label: "Produkttext", name: 'description'}}
                             initialValue={product?.description}
                             setIsChanged={setIsChanged}
                />

                <Form.Item
                    name='price'
                    label="Pris (kr)"
                    initialValue={product?.price}
                    rules={[
                        {
                            required: true,
                            type: 'number',
                        },
                        {
                            pattern: /^\d*[1-9]\d*$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    name='sales_price'
                    label="Reapris (kr)"
                    initialValue={product?.sales_price}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d*[1-9]\d*$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    name='purchase_price'
                    label="Inköpspris (kr)"
                    initialValue={product?.purchase_price}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d*[1-9]\d*$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item hidden={true}
                           name='status'
                           label="Publicerad"
                           initialValue={product?.status}>
                    <Select
                        mode="single"
                        bordered={false}
                        placeholder="publicerad">
                        {[
                            {
                                label: 'Publicerad',
                                value: 'publish'
                            },
                            {
                                label: 'Utkast',
                                value: 'draft'
                            },
                            {
                                label: 'Privat',
                                value: 'private'
                            }
                        ].map(status => <Option key={status.value}>{status.label}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='catalog_visibility'
                    label="Synlighet i katalogvy"
                    initialValue={product?.catalog_visibility}>
                    <Select
                        mode="single"
                        bordered={false}
                        placeholder="Synlighet i katalogvy">
                        {[
                            {
                                label: 'Synlig',
                                value: 'visible'
                            },
                            {
                                label: 'Katalog',
                                value: 'catalog'
                            },
                            {
                                label: 'Gömd',
                                value: 'hidden'
                            },
                            {
                                label: 'Sök',
                                value: 'search'
                            }
                        ].map(catalog_visibility => <Option
                            key={catalog_visibility.value}>{catalog_visibility.label}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='is_stock'
                    label="Lagervara"
                    valuePropName="checked"
                    initialValue={product?.is_stock}>
                    <Checkbox>Lagervara</Checkbox>
                </Form.Item>
                <Form.Item
                    name='tax_class'
                    label="Momsklass"
                    initialValue={product?.tax_class}>
                    <Select
                        mode="single"
                        bordered={false}
                        defaultValue="12-moms"
                        placeholder="Momsklass">
                        {[
                            {
                                label: '6% moms',
                                value: '6-moms'
                            },
                            {
                                label: '12% moms',
                                value: '12-moms'
                            },
                            {
                                label: '25% moms',
                                value: '25-moms'
                            }].map(tax_class => <Option key={tax_class.value}>{tax_class.label}</Option>)}
                    </Select>
                </Form.Item>
                <CustomSelect optionName='name'
                              optionValue='id'
                              initialValue={product?.categories?.map(cat => parseInt(cat.id))}
                              formItem={{isFormItem: true, label: 'Kategorier', name: 'categories', layout}}
                              fetch={{
                                  shouldFetch: true, params: {
                                      ...{...product?.categories?.length > 0 && {ids: product?.categories?.map(cat => parseInt(cat.id)).join(',')}}
                                  }
                              }
                              }/>
                <Form.Item
                    name='attributes'
                    label="Attribut"
                >
                    <AttributesSelect formRef={formRef}
                                      defaultProductAttributes={product?.attributes}
                                      setIsChanged={setIsChanged}/>
                </Form.Item>

                <TagSelect initialValues={product?.tags} formRef={formRef} setIsChanged={setIsChanged}/>

                <ProductBadge/>

                <FormImages
                    initialFeaturedImageValue={product?.featured_image?.id}
                    initialGalleryImageValues={product?.images}
                    formRef={formRef}
                    setIsChanged={setIsChanged}/>

                <Form.Item
                    name='menu_order'
                    label="Menyordning"
                    initialValue={product?.menu_order}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d+(\.\d{1,2})?$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber decimalSeparator='.'/>
                </Form.Item>
                <Form.Item
                    name='weight'
                    label="Vikt (kg)"
                    initialValue={product?.weight}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d+(\.\d{1,2})?$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber decimalSeparator='.'/>
                </Form.Item>
                <Form.Item
                    name='length'
                    label="Längd (cm)"
                    initialValue={product?.length}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d*[1-9]\d*$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    name='width'
                    label="Bredd (cm)"
                    initialValue={product?.width}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d*[1-9]\d*$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    name='height'
                    label="Höjd (cm)"
                    initialValue={product?.height}
                    rules={[
                        {
                            required: false,
                            type: 'number',
                        },
                        {
                            pattern: /^\d*[1-9]\d*$/,
                            message: 'Nummer måste vara positiva'
                        }
                    ]}
                >
                    <InputNumber/>
                </Form.Item>

                <ProductsSelect
                    initialValues={product?.related_products ?? []}
                    form={{
                        label: 'Rekommenderade produkter',
                        layout,
                        name: 'related_products',
                        ref: formRef,
                    }}
                    limit={5}
                />

                {
                    acfFields.map(acf => (
                        <QuillEditor isAcf={true}
                                     key={acf.name}
                                     initialValue={product?.meta_data?.length > 0 && product?.meta_data.find(meta => meta.meta_key === acf.name)}
                                     field={{name: acf.name, label: acf.label}}/>
                    ))
                }

                <RepeaterFields buttonText='Lägg till metadata'
                                initialValues={product.meta_data?.length > 0 ? [...product.meta_data.filter(item => item.meta_key !== '_drinking_suggestion' && item.meta_key !== '_ingredients' && item.meta_key !== '_more_info' && item.meta_key !== '_recipe')] : []}/>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}}
                                        type="primary"
                                        loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button>
                                :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>

            <StockTable product_id={product.id} layout={layout}/>
        </>
    );
};

export default EditProductForm;
