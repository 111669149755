const initialState = {
    shippingMethodsRules: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingMethodRules: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    loading: false,
}

export const SET_SHIPPING_METHODS_RULES = 'SET_SHIPPING_METHODS_RULES';
export const SET_SHIPPING_METHOD_RULES = 'SET_SHIPPING_METHOD_RULES';
export const SET_SHIPPING_METHODS_RULES_LOADING = 'SET_SHIPPING_METHODS_RULES_LOADING';
export const SET_SHIPPING_METHODS_RULES_PAGINATION = 'SET_SHIPPING_METHODS_RULES_PAGINATION';
export const UPDATE_SHIPPING_METHOD = 'UPDATE_SHIPPING_METHOD';

const shippingMethodsRulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_METHODS_RULES_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_METHODS_RULES_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_SHIPPING_METHODS_RULES:
            return {...state, shippingMethodsRules: action.payload, loading: false};
        case SET_SHIPPING_METHOD_RULES:
            return {...state, shippingMethodRules: {...action.payload}, loading: false};
        case UPDATE_SHIPPING_METHOD:
            return {...state, shippingMethod: {...action.payload}, loading: false};
        default:
            return state;
    }
}

export default shippingMethodsRulesReducer
