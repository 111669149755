import React, {createRef} from 'react';

import {Button, Form, Row} from "antd";

import setClasses from "@helpers/setClasses";
import getComponentByType from "@helpers/getComponentByType";

import useActions from "@hooks/useActions";

import RepeaterFields from "@components/Form/RepeaterFields/RepeaterFields";
import FormImages from "@components/Form/ProductImages/FormImages/FormImages";
import QuillEditor from "@components/QuillEditor/QuillEditor";
import CustomSelect from "@components/Form/CustomSelect/CustomSelect";
import SitesFieldTable from "@components/SitesFieldTable/SitesFieldTable";
import ProductBadge from "@components/Form/ProductBadge/ProductBadge";

const AddItemForm = ({
                         formElements = [],
                         type = 'products',
                         formName = 'add-product',
                         formTitle,
                         formSubtitle,
                         validationMsg,
                         addItemActionData,
                         formClasses,
                         buttonText = 'Lägg till produkt',
                         loading
                     }) => {
    const {addItem, setFeaturedImage, setGalleryImages, setFeaturedId} = useActions()
    const formRef = createRef();
    const [form] = Form.useForm();
    const acfFields = [
        {name: '_drinking_suggestion', label: 'Dryckesförslag'},
        {name: '_recipe', label: 'Tips'},
        {name: '_ingredients', label: 'Ingredienser'},
        {name: '_more_info', label: 'Mer Info'},
    ];

    const finishHandler = (values) => {
        let reworkedValues = {...values}
        if (reworkedValues.values && reworkedValues.values.length > 0) {
            const itemValues = reworkedValues.values;
            delete reworkedValues.values;

            reworkedValues.values = itemValues.map(val => {
                return {
                    value: val
                }
            })
        }
        let disposableQty = '';

        if (type === 'products') {
            const newMetaArray = Object.keys(reworkedValues)
                .filter(key => (key === '_drinking_suggestion' && reworkedValues[key]) || (key === '_ingredients' && reworkedValues[key]) || (key === '_more_info' && reworkedValues[key]) || (key === '_recipe' && reworkedValues[key]))
                .map(key => {
                    return {
                        meta_key: key,
                        meta_value: reworkedValues[key]
                    }
                })
            // removing unnecessary keys from values
            delete reworkedValues._drinking_suggestion;
            delete reworkedValues._ingredients;
            delete reworkedValues._more_info;
            delete reworkedValues._recipe;

            if (reworkedValues.description) {
                reworkedValues.description = reworkedValues.description.replaceAll('<p><br></p>', '<br>')
            }

            if (reworkedValues.short_description) {
                reworkedValues.short_description = reworkedValues.short_description.replaceAll('<p><br></p>', '<br>')
            }

            const metaData = reworkedValues.meta_data ? (reworkedValues.meta_data.length === 0 ? [] : reworkedValues.meta_data) : [];
            reworkedValues.meta_data = [
                ...metaData.filter(item => item.meta_key !== '_drinking_suggestion' && item.meta_key !== '_ingredients' && item.meta_key !== '_more_info' && item.meta_key !== '_recipe'),
                ...newMetaArray
            ];

            disposableQty = reworkedValues.disposable_quantity;
            delete reworkedValues.disposable_quantity;
        }

        if (type === 'categories' && reworkedValues?.external_categories) {
            reworkedValues.external_categories = reworkedValues?.external_categories?.map(item => {
                const {site_name, ...rest} = item;
                return rest
            })
        }

        addItem({
            values: reworkedValues, ...addItemActionData,
            product: {isProduct: true, disposable_quantity: disposableQty}
        });
        form.resetFields()

        if (type === 'products') {
            setFeaturedImage('')
            setGalleryImages([])
            setFeaturedId(null)
        }
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 18,
        },
    };

    return (
        <div className='container'>
            {
                formTitle &&
                <h2 style={{textAlign: 'center', maxWidth: '430px', width: '100%', fontSize: '20px'}}>{formTitle}</h2>
            }

            {
                formSubtitle &&
                <p style={{
                    textAlign: 'center',
                    maxWidth: '430px',
                    width: '100%',
                    fontSize: '14px',
                    color: '#898989'
                }}>{formSubtitle}</p>
            }

            <Form {...layout}
                  ref={formRef}
                  form={form}
                  className={setClasses(formClasses)}
                  name={formName}
                  scrollToFirstError={true}
                  onFinish={finishHandler}
                  validateMessages={validationMsg}>

                {
                    type === 'categories' &&
                    <SitesFieldTable shouldAddClasses={false} isPublishStatus={false} formName='external_categories'/>
                }

                {
                    formElements.length > 0
                    && formElements.map(value => {
                        return (
                            value.name === 'tags' ?
                                <div key={value.name + Math.random()}>{getComponentByType({value, formRef})}</div>
                                :
                                <React.Fragment key={value.name + Math.random()}>
                                    {
                                        value.type === 'multiple' ||
                                        value.type === 'home_delivery_type' ||
                                        value.type === 'single_select' ?
                                            getComponentByType({
                                                value, formRef, setIsChanged: () => {
                                                }, isAddingForm: true
                                            })
                                            :
                                            typeof value.noWrapper !== 'undefined' && value.noWrapper ?
                                                getComponentByType({
                                                                        value, formRef, setIsChanged: () => {}
                                                }) :
                                                <Form.Item
                                                    name={value.name}
                                                    {...(value.type === 'checkbox' && {valuePropName: 'checked'})}
                                                    label={value.label}
                                                    {...value.defaultValue && {initialValue: value.defaultValue}}
                                                    style={{...{...value.hidden && {display: 'none'}}}}
                                                    rules={[
                                                        {
                                                            required: value.required,
                                                        },
                                                    ]}
                                                >
                                                    {getComponentByType({
                                                        value, formRef, setIsChanged: () => {}
                                                    })}
                                                </Form.Item>
                                    }
                                </React.Fragment>

                        )
                    })
                }

                {
                    type === 'categories' && formRef &&
                    <>
                        <CustomSelect
                            formItem={{isFormItem: true, name: 'parent_id', label: 'Föräldrakategori', layout}}
                            optionName='name' optionValue='id' modeType='single' type='categories'/>
                    </>
                }

                {
                    type === 'products' && formRef &&
                    <>
                        <FormImages
                            initialFeaturedImageValue=''
                            formRef={formRef}/>
                        {
                            acfFields.map(acf => (
                                <QuillEditor isAcf={true}
                                             key={acf.name}
                                             field={{name: acf.name, label: acf.label}}/>
                            ))
                        }
                        <ProductBadge/>
                        <RepeaterFields buttonText='Lägg till metadata'/>
                    </>
                }

                {
                    ['shipping-methods', 'shipping-classes', 'upsell-objects'].includes(type) &&
                    <CustomSelect modeType='single' type='sites' formItem={{
                        isFormItem: true,
                        label: 'Försäljningsställe',
                        name: 'site_id',
                        ...{...type === 'shipping-methods' && {required: true}}
                    }}
                    />
                }

                {
                    type === 'shipping-methods' &&
                    <CustomSelect modeType='single' type='warehouses' formItem={{
                        isFormItem: true,
                        label: 'Lagerställe',
                        name: 'warehouse_id',
                    }}/>
                }

                <Form.Item>
                    <Row justify='space-between' align='middle'>
                        <Button loading={loading} type="primary" htmlType="submit">
                            {buttonText}
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddItemForm;
