import {
    DELETE_PRODUCT_ATTRIBUTES,
    SET_PRODUCT_ATTRIBUTE_BUTTON_DISABLED,
    SET_PRODUCT_ATTRIBUTES,
    SET_PRODUCT_ATTRIBUTES_CHOICE_VALUE, SET_PRODUCT_ATTRIBUTES_COL_ID,
    SET_PRODUCT_ATTRIBUTES_DEFAULT, SET_PRODUCT_FORM_VALUES
} from "../../reducers/productAttributeReducer/productAttributeReducer";
import {SET_ATTRIBUTES_FOR_PRODUCT} from "../../reducers/shopReducers/attributesReducer";

export const productAttributesActionCreator = {
    setDefaultAttributes: (payload) => {
        return {
            type: SET_PRODUCT_ATTRIBUTES_DEFAULT,
            payload
        }
    },
    setAddAttrButtonDisabled: (payload) => {
        return {
            type: SET_PRODUCT_ATTRIBUTE_BUTTON_DISABLED,
            payload
        }
    },

    setProductAttributes: (payload) => {
        return {
            type: SET_PRODUCT_ATTRIBUTES,
            payload
        }
    },
    setProductAttributesChoiceValue: (payload) => {
        return {
            type: SET_PRODUCT_ATTRIBUTES_CHOICE_VALUE,
            payload
        }
    },
    setProductAttributesColId: (payload) => {
        return {
            type: SET_PRODUCT_ATTRIBUTES_COL_ID,
            payload
        }
    },
    deleteProductAttributes: (payload) => {
        return {
            type: DELETE_PRODUCT_ATTRIBUTES,
            payload
        }
    },
    setProductAttributesFormValues: (payload) => {
        return {
            type: SET_PRODUCT_FORM_VALUES,
            payload
        }
    },
    setProductForAttributes: (payload) => {
        return {
            type: SET_ATTRIBUTES_FOR_PRODUCT,
            payload
        }
    }

}
