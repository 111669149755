import {
    SET_SHIPPING_BASE_ADDRESSES_PAGINATION,
    SET_SHIPPING_BASE_ADDRESSES_TABLE_FILTERS,
    SET_SHIPPING_BASE_ADDRESSES_TABLE_SORTING
} from "../../../reducers/shopReducers/shippingBaseAddressesReducer";

export const shippingBaseAddressesActions = {
        setShippingBaseAddressesFilters: (payload) => (
            {
                type: SET_SHIPPING_BASE_ADDRESSES_TABLE_FILTERS,
                payload
            }
        ),
        setShippingBaseAddressesSorting: (payload) => (
            {
                type: SET_SHIPPING_BASE_ADDRESSES_TABLE_SORTING,
                payload
            }
        ),
        setShippingBaseAddressesPagination: (payload) => (
            {
                type: SET_SHIPPING_BASE_ADDRESSES_PAGINATION,
                payload
            }
        )
}
