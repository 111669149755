export const addFormElements = {
    productsAddFormElements: [
        {
            name: 'title',
            required: true,
            label: 'Titel',
            type: 'text',
        },
        {
            name: 'price',
            required: true,
            label: 'Pris (kr)',
            type: 'number',
        },
        {
            name: 'sales_price',
            required: false,
            label: 'REA-pris (kr)',
            type: 'number',
        },
        {
            name: 'purchase_price',
            required: false,
            label: 'Inköpspris (kr)',
            type: 'number',
        },
        {
            name: 'description',
            required: false,
            label: 'Kort beskrivning',
            type: 'quill'
        },
        {
            name: 'short_description',
            required: false,
            label: 'Produkttext',
            type: 'quill'
        },
        {
            name: 'product_type',
            required: false,
            label: 'Produkttyp',
            type: 'select',
            options: [
                {
                    value: 'Enkel',
                    key: 'simple'
                },
                {
                    value: 'Variabel',
                    key: 'variable'
                }
            ]
        },
        {
            name: 'tax_class',
            required: false,
            label: 'Momsklass',
            type: 'select',
            defaultValue: '12-moms',
            options: [
                {
                    value: '6% moms',
                    key: '6-moms'
                },
                {
                    value: '12% moms',
                    key: '12-moms'
                },
                {
                    value: '25% moms',
                    key: '25-moms'
                }
            ]
        },
        {
            name: 'is_stock',
            required: false,
            label: 'Lagervara',
            type: 'checkbox',
        },
        {
            name: 'categories',
            required: false,
            label: 'Kategori(er)',
            fetch_type: 'categories',
            type: 'multiple',
        },
        {
            name: 'attributes',
            required: false,
            label: 'Attribut',
            type: 'attribute_select',
        },
        {
            name: 'tags',
            required: false,
            label: 'Tagg(ar)',
            type: 'custom_tag',
        },
        {
            name: 'catalog_visibility',
            required: true,
            label: 'Synlighet i katalogvy',
            type: 'select',
            options: [
                {
                    value: 'Synlig',
                    key: 'visible'
                },
                {
                    value: 'Katalog',
                    key: 'catalog'
                },
                {
                    value: 'Gömd',
                    key: 'hidden'
                },
                {
                    value: 'Sök',
                    key: 'search'
                }
            ]
        },
        {
            name: 'related_products',
            label: 'Rekommenderade produkter',
            type: 'product_select',
            limit: 5,
            noWrapper: true
        },
        {
            name: 'menu_order',
            required: false,
            label: 'Menyordning',
            type: 'number',
        },
        {
            name: 'weight',
            required: false,
            label: 'Vikt (kg)',
            type: 'number',
        },
        {
            name: 'length',
            label: 'Längd (cm)',
            required: false,
            type: 'number',
        },
        {
            name: 'width',
            label: 'Bredd (cm)',
            required: false,
            type: 'number',
        },
        {
            name: 'height',
            required: false,
            label: 'Höjd (cm)',
            type: 'number',
        },
        {
            name: 'disposable_quantity',
            required: false,
            label: 'Disponibelt antal i lager',
            type: 'number',
        }

    ],
    attributesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: 'Attributets namn',
            type: 'text',
        },
        {
            name: 'values',
            required: true,
            label: 'Attributets värde',
            type: 'tags',
        },
    ],
    upsellObjectsAddFormElements: [
        {
            name: 'upsell_products',
            required: true,
            label: 'Produkter',
            type: 'product_select',
            limit: 5,
            noWrapper: true
        },
    ],
    categoriesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: 'Namn',
            type: 'text',
        },
        {
            name: 'description',
            required: true,
            label: 'Beskrivning',
            type: 'textarea',
        },
        {
            name: 'menu_order',
            required: false,
            label: 'Menyordning',
            type: 'number',
        }
    ],
    shippingMethodsAddFormElements: [
        {
            name: 'name',
            required: true,
            label: "Namn",
        },
        {
            name: 'description',
            label: "Beskrivning",
        },
        {
            name: 'pickup_address',
            label: "Upphämtningsadress",
        },
        {
            required: true,
            name: 'shipping_type',
            type: 'select',
            label: 'Leveranstyp',
            options: [
                {
                    value: 'Hämta i butik',
                    key: 'pickup'
                },
                {
                    value: 'Hemleverans',
                    key: 'home'
                }
            ]
        },
        {
            type: 'home_delivery_type'
        },
        {
            required: true,
            name: 'price',
            type: 'number',
            label: 'Pris'
        },
        {
            name: 'free_shipping_threshold',
            type: 'number',
            label: 'Fri frakt, gräns'
        },
        {
            name: 'postcodes',
            type: 'textarea',
            label: 'Postnummer'
        }
    ],
    shippingBaseAddressesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: "Namn",
        },
        {
            name: 'address',
            required: true,
            label: "Adress",
        },
        {
            name: 'city',
            required: true,
            label: "Ort",
        },
        {
            name: 'country',
            required: true,
            label: "Land",
        },
        {
            name: 'email',
            required: true,
            label: "E-post",
            type: 'email',
            rules: {
                type: 'email',
                message: 'Detta är ej en giltig e-postadress!',
            }
        },
        {
            name: 'phone',
            required: true,
            label: "Telefon",
            type: 'phone'
        }
    ],
    shippingClassesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: 'Namn'
        },
        {
            name: 'description',
            required: true,
            label: 'Beskrivning',
            type: 'textarea'
        },
        {
            name: 'woocommerce_id',
            required: true,
            label: 'WooCommerce-ID',
            type: 'number'
        },
    ],
    stockObjectsAddFormElements: [],
    ordersAddFormElements: [],
    sitesAddFormElements: [],
    tagsAddFormElements: [
        {
            name: 'name',
            required: true,
            label: 'Namn',
            type: 'text',
        }
    ],
}
