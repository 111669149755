import React from 'react';
import {Button, Col, Form, Input, Row, TimePicker} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

const TimeRangeItems = ({format, time = [], index, fieldIndex}) => {
    return (
        <Col span={10}>
            <span>Tidsintervall (från/till)</span>
            <Form.List name={[fieldIndex, 'time_ranges_2']} initialValue={time}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map((field, i) => (
                                <Row justify='space-between' key={field.key}>
                                    <Input.Group compact>
                                        <Form.Item style={{marginBottom: '10px'}} wrapperCol={24} name={[i, 'time_from']}>
                                            <TimePicker format={format} />
                                        </Form.Item>
                                        <Form.Item style={{marginBottom: '10px'}} wrapperCol={24} name={[i, 'time_to']}>
                                            <TimePicker format={format} />
                                        </Form.Item>
                                        <DeleteOutlined style={{width: '10%', paddingTop: '9px'}} onClick={() => remove(field.name)}/>
                                    </Input.Group>
                                </Row>
                            ))
                            }
                            <Row style={{width: '100%'}}>
                                <Button type='primary' onClick={() => add()}>+ Lägg till</Button>
                            </Row>
                        </div>
                    )
                }
                }
            </Form.List>
        </Col>
    );
};

export default TimeRangeItems;
