const initialState = {
    order: {},
    isFreightPopupVisible: false,
    loading: false,
}

export const SET_FREIGHT_POPUP = 'SET_FREIGHT_POPUP';
export const SET_FREIGHT_LOADING = 'SET_FREIGHT_LOADING';

const bookFreightReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_FREIGHT_POPUP:
            return {...state, isFreightPopupVisible: action.payload.visible, order: action.payload.order}
        case SET_FREIGHT_LOADING:
            return {...state, loading: action.payload}
        default:
            return state
    }
}

export default bookFreightReducer
