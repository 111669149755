const initialState = {
    tags: {
        objects: [],
        total: 0,
        page: 1,
    },
    tag: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    tagsTableFilters: {},
    tagsTableSorting: {},
    loading: false,
}

export const SET_TAG = 'SET_TAG';
export const SET_TAGS = 'SET_TAGS';
export const SET_TAGS_LOADING = 'SET_TAGS_LOADING';
export const SET_TAGS_PAGINATION = 'SET_TAGS_PAGINATION';
export const UPDATE_TAG = 'UPDATE_TAG';
export const ADD_TAG = 'ADD_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const SET_TAGS_TABLE_FILTERS = 'SET_TAGS_TABLE_FILTERS';
export const SET_TAGS_TABLE_SORTING = 'SET_TAGS_TABLE_SORTING';

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAGS_LOADING:
            return {...state, loading: action.payload};
        case SET_TAGS_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_TAGS:
            return {...state, tags: action.payload, loading: false};
        case SET_TAG:
            return {...state, tag: {...action.payload}, loading: false};
        case UPDATE_TAG:
            return {...state, tag: {...action.payload}, loading: false};
        case DELETE_TAG:
            return {...state, loading: false};
        case SET_TAGS_TABLE_FILTERS:
            return {...state, tagsTableFilters: action.payload};
        case SET_TAGS_TABLE_SORTING:
            return {...state, tagsTableSorting: action.payload};
        default:
            return state;
    }
}

export default tagsReducer;
