import { combineReducers } from 'redux';
import productsReducer from './shopReducers/productsReducer';
import bulkReducer from "./bulkReducer/bulkReducer";
import attributesReducer from "./shopReducers/attributesReducer";
import categoriesReducer from "./shopReducers/categoriesReducer";
import authReducer from "./authReducer/authReducer";
import loginReducer from "./loginReducer/loginReducer";
import tagsReducer from "./shopReducers/tagsReducer";
import stockObjectsReducer from "./shopReducers/stockObjectsReducer";
import imagesReducer from "./shopReducers/imagesReducer";
import ordersReducer from "./shopReducers/ordersReducer";
import sitesReducer from "./shopReducers/sitesReducer";
import productAttributeReducer from "./productAttributeReducer/productAttributeReducer";
import imageUploaderReducer from "./imageUploaderReducer/imageUploaderReducer";
import shippingBaseAddressesReducer from "./shopReducers/shippingBaseAddressesReducer";
import productImagesReducer from "./productImagesReducer/productImagesReducer";
import chooseImageReducer from "./chooseImageReducer/chooseImageReducer";
import picklistReducer from "./picklistReducer/picklistReducer";
import shippingClassesReducer from "./shopReducers/shippingClassesReducer";
import rangeDateReducer from "./rangeDateReducer/rangeDateReducer";
import tableFiltersReducer from "./tableFiltersReducer/tableFiltersReducer";
import bookFreightReducer from "./bookFreightReducer/bookFreightReducer";
import shippingMethodsReducer from "./shopReducers/shippingMethodsReducer";
import cacheReducer from "./shopReducers/cacheReducer";
import shippingMethodsRulesReducer from "./shopReducers/shippingMethodsRulesReducer";
import warehousesReducer from "./shopReducers/warehousesReducer";
import upsellObjectsReducer from "./shopReducers/upsellObjectsReducer";

export const rootReducer = combineReducers( {
    products: productsReducer,
    attributes: attributesReducer,
    categories: categoriesReducer,
    tags: tagsReducer,
    images: imagesReducer,
    orders: ordersReducer,
    shippingBaseAddresses: shippingBaseAddressesReducer,
    stockObjects: stockObjectsReducer,
    bulkOperations: bulkReducer,
    auth: authReducer,
    sites: sitesReducer,
    login: loginReducer,
    productAttribute: productAttributeReducer,
    imagesUploader: imageUploaderReducer,
    productImages: productImagesReducer,
    chooseImages: chooseImageReducer,
    picklist: picklistReducer,
    shippingClasses: shippingClassesReducer,
    rangeDate: rangeDateReducer,
    tableFilters: tableFiltersReducer,
    bookFreight: bookFreightReducer,
    shippingMethods: shippingMethodsReducer,
    shippingMethodsRules: shippingMethodsRulesReducer,
    cache: cacheReducer,
    warehouses: warehousesReducer,
    upsellObjects: upsellObjectsReducer
} );
