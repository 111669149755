import React, {createRef, useState} from 'react';
import {Button, Form, Input, Row, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../hooks/useActions";
import {getActionTypes} from "../../../helpers/getActionTypes";
import CustomSelect from '../CustomSelect/CustomSelect';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditShippingClassForm = ({id}) => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const {loading, shippingClass} = useSelector(state => state?.shippingClasses)
    const navigate = useNavigate();
    const formRef = createRef();

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('shipping_classes'),
            contentText: `Fraktklass "${values.name}" har uppdaterats`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/shipping-classes');
    };


    if (Object.keys(shippingClass).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    const initialValues = {
        site_id: shippingClass?.site_id,
        name: shippingClass?.name,
        woocommerce_id: shippingClass?.woocommerce_id,
        description: shippingClass?.description
    }

    return (
        <Form name='edit-shipping-class'
              ref={formRef}
              {...layout}
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              initialValues={initialValues}
              validateMessages={validateMessages}
        >
            <Form.Item name='name' label='Namn'>
                <Input/>
            </Form.Item>
            <Form.Item name='description' label='Beskrivning'>
                <Input.TextArea rows={4}/>
            </Form.Item>
            <Form.Item hidden={true} name='woocommerce_id' label='WooCommerce ID'>
                <Input/>
            </Form.Item>
            <Form.Item name='site_id' label="Försäljningsställe">
                <CustomSelect formItem={{isFormItem: true}}
                              name='site_id'
                              modeType='single'
                              type='sites'/>
            </Form.Item>
            <Form.Item wrapperCol={{...layout.wrapperCol}}>
                <Row>
                    {
                        isChanged ?
                            <Button style={{marginRight: '20px'}} type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    )
};

export default EditShippingClassForm;
