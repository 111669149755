import React, {createRef, useState} from 'react';
import {Button, Form, Input, Row, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../hooks/useActions";
import {getActionTypes} from "../../../helpers/getActionTypes";

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditTagForm = ({id}) => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const loading = useSelector(state => state?.tags?.loading)
    const tag = useSelector(state => state?.tags?.tag)
    const formRef = createRef();
    const navigate = useNavigate();

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('tags'),
            contentText: `Tagg med Id ${id} har nu uppdaterats` });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/tags');
    };

    if (Object.keys(tag).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <Form {...layout}
              name="edit-tag"
              ref={formRef}
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>
            <Form.Item
                name='name'
                label="Namn"
                initialValue={tag?.name}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};

export default EditTagForm;


/*
"id": 0,
    "datetime_created": "string",
    "datetime_last_updated": "string",
    "parent_id": 0,
    "status": "string",
    "catalog_visibility": "string",
    "tax_class": "string",
    "tax_status": "string",
    "sku": "string",
    "title": "string",
    "description": "string",
    "short_description": "string",
    "url": "string",
    "product_type": "string",
    "price": 0,
    "sales_price": 0,
    "purchase_price": 0,
    "is_stock": true,
    "weight": 0,
    "length": 0,
    "width": 0,
    "height": 0,
    "tags": [
      {
        "id": 0,
        "name": "string"
      }
    ],
    "categories": [
      {
        "id": 0,
        "datetime_created": "string",
        "datetime_last_updated": "string",
        "name": "string",
        "description": "string",
        "parent_id": 0
      }
    ],
    "attributes": [
      {
        "id": 0,
        "datetime_created": "string",
        "datetime_last_updated": "string",
        "name": "string",
        "values": [
          {
            "id": 0,
            "value": "string"
          }
        ]
      }
    ],
    "images": [
      {
        "id": 0,
        "url": "string"
      }
    ]
  }
 */
