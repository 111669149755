import React, {useState} from 'react';
import {Colorpicker,} from 'antd-colorpicker'
import {Form, Input, Button} from "antd";

const ProductBadge = ({
                          colors = ['#E6003F', '#F9C7D5', '#507C91'],
                          textColors = ['#FFFFFF', '#E6003F']
}) => {

    return (
        <>
            <Form.Item label='Badge färg' >
                <Form.Item name={['badge', 'color']}>
                    <Colorpicker colors={colors}
                                 onColorResult={color => color.hex}
                                 className='badge-color-picker'
                                 picker='TwitterPicker'
                                 popup={true}/>
                </Form.Item>
            </Form.Item>
            <Form.Item label='Badge textfärg' >
                <Form.Item name={['badge', 'text_color']}>
                    <Colorpicker colors={textColors}
                                 onColorResult={color => color.hex}
                                 className='badge-color-picker'
                                 picker='TwitterPicker'
                                 popup={true}/>
                </Form.Item>
            </Form.Item>

            <Form.Item label='Badge text' name={['badge', 'text']}>
                <Input maxLength={35}/>
            </Form.Item>
        </>
    );
};

export default ProductBadge;
