import {useEffect, useRef, useState} from 'react'

export function useFetch({
                             url,
                             token,
                             options,
                             deps = []
                         }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const defaultOptions = {
        headers: token ? {'Authorization': token} : {}
    }

    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef(false)

    const fetchData = async (url) => {
        setLoading(true);

        try {
            const response = await fetch(url, {...defaultOptions, ...options})

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            const data = (await response.json())


            if (cancelRequest.current) return;

            setData(data);
            setLoading(false)
        } catch (error) {
            if (cancelRequest.current) return

            setError(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!url) return;

        cancelRequest.current = false

        void fetchData(url)

    }, [url, ...deps])

    return {data, setData, loading, error}
}
