import React, {createRef, useState} from 'react';
import {Button, Form, Row, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../hooks/useActions";
import {getActionTypes} from "../../../helpers/getActionTypes";
import {ProductsSelect} from "@components/Products/ProductsSelect/ProductsSelect";
import CustomSelect from "@components/Form/CustomSelect/CustomSelect";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditUpsellObjectForm = ({id}) => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const {loading, upsellObject} = useSelector(state => state?.upsellObjects)
    const navigate = useNavigate();
    const formRef = createRef();

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('upsell_objects'),
            contentText: `Tilläggsköp "${values.name}" har uppdaterats`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/upsell-objects');
    };

    if (Object.keys(upsellObject).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <Form name='edit-upsell-object'
              ref={formRef}
              {...layout}
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}
        >
            <CustomSelect modeType='single'
                          type='sites'
                          formItem={{
                              isFormItem: true,
                              label: 'Försäljningsställe',
                              name: 'site_id'
                          }}
                          initialValue={upsellObject?.site_id}
            />
            <ProductsSelect
                form={{
                    layout,
                    label: 'Tilläggsköp',
                    name: 'upsell_products',
                    ref: formRef,
                }}
                initialValues={upsellObject?.upsell_products}
                limit={10}
            />
            <Form.Item wrapperCol={{...layout.wrapperCol}}>
                <Row>
                    {
                        isChanged ?
                            <Button style={{marginRight: '20px'}} type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    )
};

export default EditUpsellObjectForm;
