import React, {useEffect, useState} from 'react';
import {Select, Form} from "antd";
import useDebounce from "../../../hooks/useDebounce";
import useActions from "../../../hooks/useActions";
import {getTextFromType} from "../../../helpers/helpers";
import {useSelector} from "react-redux";
import {getActionTypes} from "../../../helpers/getActionTypes";

const CustomSelect = ({
                           modeType = 'multiple',
                           type = 'categories',
                           filterOption = false,
                           fetch = {
                               shouldFetch: true,
                               params: {},
                               paramType: 'search'
                           },
                           formItem = {
                               isFormItem: false,
                               label: 'Kategorier',
                               name:'categories',
                               required: false,
                               layout: {
                                   labelCol: {
                                       span: 4,
                                   },
                                   wrapperCol: {
                                       span: 18,
                                   }
                               }
                           },
                           selectOptions = [],
                           optionName = 'name',
                           showSearch = true,
                           optionValue = 'id',
                           initialValue = null,
                           ...props
                       }) => {
    const {getItems} = useActions();
    const [searchValue, setSearchValue] = useState('');
    const [isSearchedValueChanged, setIsSearchedValueChanged] = useState(false);
    const useSelectorType = getTextFromType({string: type, isRemoveSymbols: false, isRemoveSymbolsAndUppercase: true});
    const dataOptions = selectOptions.length > 0 ? selectOptions : (fetch.shouldFetch ? useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]?.objects) : []);
    const debouncedValue = useDebounce(searchValue, 300);
    const {Option} = Select;
    const paramType = fetch.paramType ?? 'search';

    useEffect(() => {
        if (!fetch.shouldFetch) return;
        getItems({params: fetch.params, ...getActionTypes(type)})
    }, [])

    useEffect(() => {
        if (!fetch.shouldFetch || !isSearchedValueChanged || !searchValue) return;
        getItems({params: {[paramType]: debouncedValue}, ...getActionTypes(type)})
        setIsSearchedValueChanged(false)
    }, [debouncedValue])

    const onSearchHandler = (value) => {
        setIsSearchedValueChanged(true)
        setSearchValue(value)
    }

    const renderSelectOptions = () => (
        dataOptions &&
        dataOptions.length > 0 &&
        dataOptions.map(option => {
            return (
                <Option key={type + Math.random()} value={optionValue ? option[optionValue] : option}>{optionName ? option[optionName] : option}</Option>
            )
        })
    )

    const renderSelect = () => (
        <Select filterOption={filterOption}
           mode={modeType}
           showSearch={showSearch}
           onSearch={onSearchHandler}
           {...props} >
            {renderSelectOptions()}
        </Select>
    )

    return (
        formItem.isFormItem ?
            <Form.Item
                {...formItem.layout}
                label={formItem.label}
                name={formItem.name}
                {...formItem.required && {
                    rules: [{
                        required: true
                    }]
                }}
                {...initialValue && {initialValue}}>
                {renderSelect()}
            </Form.Item>
            : renderSelect()
    )
};

export default CustomSelect;
