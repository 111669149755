import React, {useEffect, useState} from 'react';
import ReactQuill from "react-quill";
import {Form} from "antd";
import 'react-quill/dist/quill.snow.css'

const QuillEditor = ({field, index = 0, isAcf = false, initialValue = null, setIsChanged = () => {}}) => {
    const [quillValue, setQuillValue] = useState([{html: '', key: '0_meta_value_0'}]);
    const [quillOneValue, setQuillOneValue] = useState(initialValue ? initialValue : '');

    initialValue = initialValue ? (initialValue.meta_value ?? initialValue) : null

    useEffect(() => {
        setIsChanged(false)
    }, [])

    const quillHandleChange = ({value, key}) => {
        setQuillValue(prev => {
            const foundItem = prev.find(item => item.key === key)
            const previous = foundItem ? prev.filter(item => item.key !== foundItem.key) : prev

            return [
                ...previous,
                {
                    html: value,
                    key
                }
            ]
        })
    }

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'color': []}, {'background': []}],
        [{'font': []}],
        [{'align': []}],
        ['clean'],
    ]

    const format = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "color",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "color",
        "code-block",
        "clean"
    ]

    return (
        <Form.Item name={ isAcf ? field.name : [field.name, 'meta_value']}
                   {...initialValue && {initialValue}}
                   {...field.label && {label: field.label}}
        >
            <ReactQuill
                theme='snow'
                modules={{toolbar: toolbarOptions}}
                formats={format}
                value={isAcf ? quillOneValue : (quillValue[field.key + '_meta_value_' + index] ? quillValue[field.key + '_meta_value_' + index].html : quillValue[0].html)}
                onChange={value => isAcf ? setQuillOneValue(value.replaceAll('<p><br></p>', '<br>')) : quillHandleChange({value, key: field.key + '_meta_value_' + index})}
            />
        </Form.Item>
    );
};

export default QuillEditor;
