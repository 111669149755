import React, {useState} from 'react';
import {Button, Form, Row, Select} from "antd";
import {dashboardBulkFormElements} from "../../../ant-table/dashboardBulkFormElements";
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";

const OrderStatus = ({order}) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {loading} = useSelector(state => state?.orders)
    const {updateOrderStatus} = useActions();

    const onStatusFormFinishHandler = (values) => {
        updateOrderStatus({
            id: order.id,
            values,
            status: values.status,
            contentText: `Beställning med ID ${order.id} har uppdaterats`
        })
        setIsButtonDisabled(true)
    }

    return (
        <Form name='book-freight' style={{marginTop: '20px'}} onFinish={onStatusFormFinishHandler}>
            <Row align='middle'>
                <Form.Item name='status' label='' initialValue={order.status} style={{width: '30%', minWidth: '250px'}}>
                    <Select mode='single' onChange={() => setIsButtonDisabled(false)}>
                        {
                            dashboardBulkFormElements.ordersBulkFormElements[0].options.map(option => {
                                return (
                                    <Select.Option key={option.key} value={option.key}>
                                        {option.value}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item style={{width: 'calc(30% - 10px)', marginLeft: '10px'}}>
                    <Button disabled={isButtonDisabled} loading={loading} type="primary" htmlType="submit">Uppdatera status</Button>
                </Form.Item>
            </Row>
        </Form>
    );
};

export default OrderStatus;
