import {bulkOperationsAction} from "./bulkActions/bulkOperationsAction";
import {imageUploaderActions} from "./imageUplaoderActions/imageUploaderAction";
import {loginActionCreator} from "./loginActions/loginActions";
import {logoutActionsCreator} from "./logoutActions/logoutActions";
import {shopActions} from "./shopActions/shopActions";
import {productAttributesActionCreator} from "./shopActions/productAttributesAction";
import {orderActions} from "./shopActions/orderActions";
import {productImagesAction} from "./productImagesActions/productImagesAction";
import {chooseImagesActions} from "./chooseImagesActions/chooseImagesActions";
import {picklistActions} from "./picklistActions/picklistActions";
import {rangeDateActions} from "./rangeDateActions/rangeDateActions";
import {tableFiltersActions} from "./tableFiltersActions/tableFiltersActions";
import {bookFreightActions} from "./bookFreightActions/bookFreightActions";
import {productsActions} from "./shopActions/productsActions/productsActions";
import {attributesActions} from "./shopActions/attributesActions/attributesActions";
import {categoriesActions} from "./shopActions/categoriesActions/categoriesActions";
import {imagesActions} from "./shopActions/imagesActions/imagesActions";
import {tagsActions} from "./shopActions/tagsActions/tagsActions";
import {shippingBaseAddressesActions} from "./shopActions/shippingBaseAddressesActions/shippingBaseAddressesActions";
import {shippingClassesActions} from "./shopActions/shippingClassesActions/shippingClassesActions";
import {shippingMethodsActions} from "./shopActions/shippingMethodsActions/shippingMethodsActions";
import {stockObjectsActions} from "./shopActions/stockObjectsActions/stockObjectsActions";
import {upsellObjectsActions} from "./shopActions/upsellObjectsActions/upsellObjectsActions";

export const allActionCreators = {
    ...productsActions,
    ...attributesActions,
    ...categoriesActions,
    ...imagesActions,
    ...tagsActions,
    ...stockObjectsActions,
    ...shippingBaseAddressesActions,
    ...shippingClassesActions,
    ...shippingMethodsActions,
    ...bulkOperationsAction,
    ...imageUploaderActions,
    ...loginActionCreator,
    ...logoutActionsCreator,
    ...shopActions,
    ...orderActions,
    ...productAttributesActionCreator,
    ...productImagesAction,
    ...chooseImagesActions,
    ...picklistActions,
    ...rangeDateActions,
    ...tableFiltersActions,
    ...bookFreightActions,
    ...upsellObjectsActions
}
