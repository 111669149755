import React, {useEffect, useState} from 'react';

import {getApiUrl, getParams} from "@helpers/helpers";

import useDebounce from "@hooks/useDebounce";
import {useFetch} from "@hooks/useFetch";

import {Select, Form} from "antd";
import {useSelector} from "react-redux";

const defaultLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
}

export const ProductsSelect = ({form, limit = -1, initialValues = []}) => {
    const {products, loading} = useSelector(state => state.products)
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState([]);
    const debouncedValue = useDebounce(searchValue, 300);
    const [url, setUrl] = useState(null);
    const {data, loading: fetchLoading} = useFetch({
        url,
        token: localStorage.getItem('token')?.replaceAll('"', ''),
        deps: [],
    });

    const setDisabledOptions = (options, values) => {
        return options.map(option => {
            const found = values.find(item => item.value === option.value)
            option.disabled = values.length >= limit ? !found : false
            return option;
        })
    }
    const onChange = (value, values) => {
        /* disable option if options chosen for limit */
        setOptions(setDisabledOptions(options, values))
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    useEffect(() => {
        return () => {
            setUrl(null);
            setSearchValue('');
            setOptions(null);
        }
    }, [])

    useEffect(() => {
        if (debouncedValue.trim()) {
            setUrl(getApiUrl('products') + 'products' + '/' + getParams({
                search: debouncedValue
            }))
        }
    }, [debouncedValue])

    /* Don't make any call by default, because products object already exist */
    useEffect(() => {
        if (products && products.objects && products.objects.length > 0) {
            const isLimit = initialValues && initialValues.length >= limit;

            let defaultOptions = products.objects.map(option => (
                    {
                        label: option.title,
                        disabled: isLimit,
                        value: option.id
                    }
                )
            );


            if (initialValues && initialValues.length > 0) {
                /* Make the same array structure as defaultOptions */
                const initialOptions = initialValues.map(option => (
                        {
                            label: option.title,
                            disabled: false,
                            value: option.product_id
                        }
                    )
                );

                /* Merge two arrays and remove duplicates */
                defaultOptions = Array.from([...defaultOptions, ...initialOptions]
                    .reduce((prev, next) => prev.set(next.value, next), new Map)
                    .values()
                );
            }

            setOptions(defaultOptions)
        }
    }, [products])

    useEffect(() => {
        if (!fetchLoading && data && data.objects) {
            const isLimit = form.ref &&
                form.ref.current &&
                form.ref.current.getFieldsValue()[form.name] &&
                form.ref.current.getFieldsValue()[form.name].length >= limit;

            setOptions(data.objects.map(option => (
                    {
                        label: option.title,
                        disabled: isLimit,
                        value: option.id
                    }
                )
            ))
        }
    }, [fetchLoading])

    return (
        <Form.Item
            {...(form.layout ?? defaultLayout)}
            label={form.label}
            name={form.name}
            {...initialValues.length > 0 && {initialValue: initialValues.map(val => val.product_id)}}
            {...form.required && {
                rules: [{required: true}]
            }}
        >
            <Select showSearch={true}
                    loading={loading}
                    onSearch={onSearch}
                    onChange={onChange}
                    filterOption={false}
                    mode='multiple'
                    options={options}
            />
        </Form.Item>
    );
};