import {
    SET_LOGIN_LOADING, SET_LOGIN_VALUES,
    SET_NEW_PASS_FORM_VISIBLE,
    SET_USER_ATTRIBUTES,
    SET_USER
} from "../../reducers/loginReducer/loginReducer";
import getCognitoData from "../../../helpers/getCognitoData";
import {message} from "antd";
import {setLoginCognito} from "../../../helpers/setLoginCognito";

export const loginActionCreator = {
    login: (values, tokenData = {}) => (dispatch, useState) => {
        dispatch({type: SET_LOGIN_LOADING, payload: true})
        dispatch({type: SET_LOGIN_VALUES, payload: values})

        if (Object.keys(tokenData).length > 0) {
            return setLoginCognito({
                token: tokenData.token,
                expire: tokenData.expire,
                user: tokenData.user,
                cognitoData: useState().login,
                dispatch
            })
        } else {
            getCognitoData(values).then(cognitoData => {
                if (cognitoData?.isFirstLogin) {
                    dispatch({type: SET_NEW_PASS_FORM_VISIBLE, payload: true})
                    dispatch({type: SET_USER, payload: cognitoData.user})
                    dispatch({type: SET_USER_ATTRIBUTES, payload: cognitoData.userAttributes})
                    dispatch({type: SET_LOGIN_LOADING, payload: false})
                    return;
                }

                if (cognitoData?.isMfaRequired) {
                    loginActionCreator.mfa({
                        mfaCode: cognitoData.mfaCode,
                        type: 'SMS_MFA',
                        user: cognitoData.user,
                        username: cognitoData.username,
                        password: cognitoData.password
                    })
                    return;
                }

                if (cognitoData?.isTotpRequired) {
                    loginActionCreator.mfa({
                        mfaCode: cognitoData.mfaCode,
                        type: 'SOFTWARE_TOKEN_MFA',
                        secretCode: cognitoData.secretCode,
                        username: cognitoData.username,
                        user: cognitoData.user,
                        password: cognitoData.password
                    })
                    return;
                }

                return setLoginCognito({
                    token: cognitoData.token,
                    expire: cognitoData.expire,
                    cognitoData,
                    dispatch
                })
            })
                .catch(error => {
                    message.error(error.toString());
                    dispatch({type: SET_LOGIN_LOADING, payload: false})
                })
        }
    },
    mfa: ({mfaCode, type, password, username, user, secretCode = null}) => (dispatch, getState) => {
        dispatch({type: SET_LOGIN_LOADING, payload: true})
        user.sendMFACode(
            mfaCode,
            {
                onSuccess: (res) => {
                    dispatch({type: SET_LOGIN_LOADING, payload: false})

                    const tokenData = {
                        token: res.getIdToken().getJwtToken(),
                        expire: res.getIdToken().getExpiration(),
                        user
                    }

                    loginActionCreator.login({password, username}, tokenData)
                },
                onFailure: (error) => {
                    message.error(error.toString());
                    dispatch({type: SET_LOGIN_LOADING, payload: false})
                }
            },
            type)
    },
    newPassword: (values) => (dispatch, getState) => {
        dispatch({type: SET_LOGIN_LOADING, payload: true})
        const attrsWithoutEmailVerified = {...getState().login.userAttributes};
        delete attrsWithoutEmailVerified.email_verified;
        delete attrsWithoutEmailVerified.email;

        getState().login.user.completeNewPasswordChallenge(values?.new_password, attrsWithoutEmailVerified, {
            onSuccess: res => {
                dispatch({type: SET_LOGIN_LOADING, payload: false})
                const tokenData = {
                    token: res.getIdToken().getJwtToken(),
                    expire: res.getIdToken().getExpiration(),
                }
                loginActionCreator.login(values, tokenData)
            },
            onFailure: (err) => {
                message.error(err.toString());
                dispatch({type: SET_LOGIN_LOADING, payload: false})
            },
        });
    }
}
