import React, {createRef, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';

import {useSelector} from "react-redux";

import {SET_ORDER} from "../../../../redux-store/reducers/shopReducers/ordersReducer";

import useActions from "../../../../hooks/useActions";

import {Button, Col, message, Popconfirm, Row, Spin} from "antd";

import {getColumns} from "./columns";

import {getAvailableAmountForRefund} from "../../../../helpers/helpers";
import {getActionTypes} from "../../../../helpers/getActionTypes";

import BookFreight from "../../../../components/Order/BookFreight/BookFreight";
import OrderInfo from "../../../../components/Order/OrderInfo/OrderInfo";
import OrderTable from "../../../../components/Order/OrderTable";
import OrderRefundList from "../../../../components/Order/OrderRefundList/OrderRefundList";
import OrderStatus from "../../../../components/Order/OrderStatus/OrderStatus";
import OrderCustomerDetails from "../../../../components/Order/OrderCustomerDetails/OrderCustomerDetails";
import OrderNotesList from "../../../../components/Order/OrderNotes/OrderNotesList/OrderNotesList";
import CancelDelivery from "../../../../components/Order/CancelDelivery/CancelDelivery";
import OrderShippingMethodsAndDate
    from "../../../../components/Order/OrderShippingMethodsAndDate/OrderShippingMethodsAndDate";

import './order-page.scss';

const OrderPage = () => {
    const {id} = useParams();
    const formRef = createRef();
    const [isEditing, setIsEditing] = useState(false);
    const {order, loading} = useSelector(state => state?.orders)
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [userRefundSum, setUserRefundSum] = useState(0);
    const [isCanRefund, setIsCanRefund] = useState(false);
    const {getItem, refundOrder, syncTrivec, setItemNull} = useActions();
    const syncToTrivecData = order?.order_meta?.find(item => item.meta_key === 'synchronized_to_trivec');

    useEffect(() => {
        getItem({
            id,
            ...getActionTypes('orders'),
        });

        return () => {
            setItemNull(SET_ORDER)
        }

    }, []);

    useEffect(() => {
        if (!isEditing || !isFormChanged || !formRef.current) return;
        const values = formRef.current.getFieldsValue();
        const reWorkedValues = []
        Object.keys(values).forEach((key, i) => {
            if (key.includes('total') && !key.includes('total_tax')) {
                const qty = values[Object.keys(values)[i - 1]];
                const totalTax = values[Object.keys(values)[i + 1]];
                reWorkedValues.push((values[key] * (qty === 0 ? 1 : qty)) + totalTax)
            }
        })

        const sum = reWorkedValues.reduce((prev, item) => prev + item, 0);

        if (+getAvailableAmountForRefund(order) < sum) {
            message.warning(`Summan kan ej vara mer än ${getAvailableAmountForRefund(order)}`)
            setIsCanRefund(false)
            setUserRefundSum(+sum)
        }

        if (sum === 0) {
            setIsCanRefund(false)
            message.warning('Summan kan ej vara noll')
            setUserRefundSum(+sum)
        }

        if (+getAvailableAmountForRefund(order) >= sum && sum !== 0) {
            setIsCanRefund(true)
            setUserRefundSum(+sum)
        }

        setIsFormChanged(false)
    }, [isEditing, formRef, isFormChanged, setIsFormChanged, setUserRefundSum])

    const onRefundClickHandler = () => {
        if (isCanRefund) {
            const formValues = formRef.current.getFieldsValue();
            const arrayOfValues = [];

            Object.keys(formValues).forEach((item, index) => {
                if (index % 2) {
                    arrayOfValues.push({
                        [item]: formValues[item],
                        [Object.keys(formValues)[index - 1]]: formValues[Object.keys(formValues)[index - 1]],
                        [Object.keys(formValues)[index + 1]]: formValues[Object.keys(formValues)[index + 1]],
                    })
                }
            })

            const values = {
                order_id: order.id,
                amount: userRefundSum,
                refund_order_items: []
            }

            arrayOfValues.forEach(value => {
                const id = Object.keys(value)[0].split('_id_')[1]
                const foundItem = order.order_items.find(item => +item.id === +id)
                if (foundItem) {
                    const quantity = +value[Object.keys(value)[1]] === 0 ? 1 : +value[Object.keys(value)[1]];
                    const total = +value[Object.keys(value)[0]] === 0 ? foundItem.total : +value[Object.keys(value)[0]];
                    const total_tax = +value[Object.keys(value)[2]] === 0 ? foundItem.total_tax : +value[Object.keys(value)[2]];

                    values.refund_order_items.push({
                        sku: foundItem.sku,
                        quantity,
                        total,
                        total_tax
                    })
                }
            })

            refundOrder({
                id,
                values,
                contentText: 'Ordern har återbetalats'
            })

            setIsCanRefund(false)
            formRef.current.resetFields();
            setIsEditing(false)
        } else {
            message.warning(isFormChanged ? `Summan kan ej vara mer än ${getAvailableAmountForRefund(order)}` : (
                userRefundSum > 0 ?
                    'Vänligen ändra summan' :
                    'Summan kan ej vara 0'))
        }
    }

    if (Object.keys(order).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <Row justify='space-between' align='center'>
                <h2>Beställning #{order.external_order_id}</h2>
                {
                    syncToTrivecData && syncToTrivecData.meta_value === 'error' &&
                    <Button onClick={() => syncTrivec(id)} style={{marginBottom: '10px'}} type='primary' ghost>Synkronisera
                        till Trivec</Button>
                }
            </Row>

            <br/>
            <Row justify='space-between'>
                <Col style={{flexGrow: '1', marginRight: '20px'}}>
                    {
                        <OrderCustomerDetails order={order}/>
                    }
                    {
                        order.site &&
                        <span style={{display: 'block', marginBottom: '20px'}}>Försäljningsställe: {order.site.name}</span>
                    }
                    <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                        <h3>Orderstatus</h3>
                    </Row>
                    <OrderStatus order={order}/>
                    <div style={{marginTop: '10px'}}>
                        <h2>Leverans</h2>
                        <ul>
                            <li>
                                <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}>Leveranssätt:</span>
                                <span style={{ color: '#8e8e8e'}}>{order.shipping_method_name}</span>
                            </li>
                            <li>
                                <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}>Leveransdatum:</span>
                                <span style={{ color: '#8e8e8e'}}>{order.delivery_date.substring(0,10)}</span>
                            </li>
                            <li>
                                <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}>Tidsintervall:</span>
                                <span style={{ color: '#8e8e8e'}}>{order.delivery_time_interval}</span>
                            </li>
                        </ul>
                    </div>

                    <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                        <h3>Ändra Leverans</h3>
                    </Row>
                    <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                        <OrderShippingMethodsAndDate order={order}/>
                    </Row>
                    { order.shipping_method_type !== 'local_pickup' ?
                        <>
                            <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                                <h3>Avboka Leverans</h3>
                            </Row>
                            <Row className='order-info' align='middle' style={{marginBottom: '20px'}}>
                                <CancelDelivery/>
                            </Row>
                        </>
                        :
                        <>
                            <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                                <h3>Boka Leverans</h3>
                            </Row>
                            <Row className='order-info' align='middle' style={{marginBottom: '20px'}}>
                                <BookFreight order={order}/>
                            </Row>

                        </>
                    }
                     <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                                <h2>Orderdetaljer</h2>
                            </Row>
                    <OrderTable ref={formRef} order={order} loading={loading} setIsFormChanged={setIsFormChanged}
                                columns={getColumns} isEditing={isEditing}/>
                    {
                        <>
                            <>
                                <OrderRefundList order={order}/>
                                <OrderInfo order={order} isEditing={isEditing}/>
                            </>
                            {
                                order.status === 'completed' &&
                                <>
                                    {
                                        isEditing ?
                                            <>
                                                <Button onClick={() => setIsEditing(false)} type="primary" ghost
                                                        style={{marginRight: '20px'}}>Avbryt</Button>
                                                <Popconfirm title='Är du säker på att du vill återbetala detta belopp?'
                                                            placement='topRight' onConfirm={onRefundClickHandler}>
                                                    <Button type="primary">Återbetala</Button>
                                                </Popconfirm>
                                            </>
                                            : <Button onClick={() => setIsEditing(true)} type="primary"
                                                      ghost>Återbetala</Button>

                                    }
                                </>
                            }
                        </>
                    }
                </Col>
                <Col style={{minWidth: '300px', maxWidth: '300px'}}>
                    <OrderNotesList/>
                </Col>
            </Row>
        </>
    );
};

export default OrderPage;
