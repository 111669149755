const initialState = {
    shippingClasses: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingClass: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shippingClassesTableFilters: {},
    shippingClassesTableSorting: {},
    loading: false,
}

export const SET_SHIPPING_CLASSES = 'SET_SHIPPING_CLASSES';
export const SET_SHIPPING_CLASS = 'SET_SHIPPING_CLASS';
export const SET_SHIPPING_CLASSES_LOADING = 'SET_SHIPPING_CLASSES_LOADING';
export const SET_SHIPPING_CLASSES_PAGINATION = 'SET_SHIPPING_CLASSES_PAGINATION';
export const UPDATE_SHIPPING_CLASS = 'UPDATE_SHIPPING_CLASS';
export const ADD_SHIPPING_CLASS = 'ADD_SHIPPING_CLASS';
export const REMOVE_SHIPPING_CLASS = 'REMOVE_SHIPPING_CLASS';
export const SET_SHIPPING_CLASSES_TABLE_FILTERS = 'SET_SHIPPING_CLASSES_TABLE_FILTERS';
export const SET_SHIPPING_CLASSES_TABLE_SORTING = 'SET_SHIPPING_CLASSES_TABLE_SORTING';

const shippingClassesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_CLASSES_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_CLASSES_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_SHIPPING_CLASSES:
            return {...state, shippingClasses: action.payload, loading: false};
        case SET_SHIPPING_CLASS:
            return {...state, shippingClass: {...action.payload}, loading: false};
        case UPDATE_SHIPPING_CLASS:
            return {...state, shippingClass: {...action.payload}, loading: false};
        case ADD_SHIPPING_CLASS:
            return {...state, shippingClass: {...action.payload}, loading: false};
        case REMOVE_SHIPPING_CLASS:
            return {...state, loading: false};
        case SET_SHIPPING_CLASSES_TABLE_FILTERS:
            return {...state, shippingClassesTableFilters: action.payload};
        case SET_SHIPPING_CLASSES_TABLE_SORTING:
            return {...state, shippingClassesTableSorting: action.payload};
        default:
            return state;
    }
}

export default shippingClassesReducer
