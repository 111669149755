import {
    SET_IMAGE,
    SET_IMAGES,
    SET_IMAGES_LOADING,
    SET_IMAGES_PAGINATION
} from "@reduxStore/reducers/shopReducers/imagesReducer";
import {message} from "antd";

import {customFetch, getApiUrl, cleanPayload, getParams, payloadNullToQuotes} from "../../../helpers/helpers";
import {getActionTypes} from "../../../helpers/getActionTypes";

import {productImagesAction} from "../productImagesActions/productImagesAction";

import {SET_IS_FILTERED} from "../../reducers/tableFiltersReducer/tableFiltersReducer";
import {CLEAR_CACHE, CLEAR_CACHE_LOADING} from "../../reducers/shopReducers/cacheReducer";

export const shopActions = {
    addItem: ({
                  values,
                  type = 'products',
                  addItemActionType = 'ADD_PRODUCT',
                  setLoadingActionType = 'SET_PRODUCTS_LOADING',
                  contentText = 'Produkten har uppdaterats',
                  isMessage = true,
                  formRef = null,
                  product = {},
              }) => dispatch => {
        dispatch({type: setLoadingActionType, payload: true})
        const multipart = type === 'images' ? {'Content-Type': 'multipart/form-data'} : {};

        return customFetch({
            url: `${getApiUrl(type)}${type}/`,
            method: 'post',
            values: type === 'images' ? values : cleanPayload(values),
            multipart,
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                if (isMessage) {
                    message.success({content: contentText, duration: 2});
                }
                dispatch({type: addItemActionType, payload: res.data})
                dispatch({type: setLoadingActionType, payload: false})

                if (product.isProduct && product.disposable_quantity) {
                    dispatch(shopActions.getItems({
                        params: {product_id: res.data},
                        ...getActionTypes('stock_objects'),
                        disposable_quantity: product.disposable_quantity
                    }))
                }

                if (type === 'images' && Object.keys(product).length > 0 && product.isProduct) {
                    if (product.isFeaturedImage) {
                        dispatch(productImagesAction.getImageByName({
                            params: {search: product.file.name.split('.')[0]}
                        }))
                    } else {
                        dispatch(productImagesAction.getImageByName({
                            params: {search: product.file.name.split('.')[0]},
                            isFeaturedImage: false,
                            productImages: product.images,
                            formRef
                        }))
                    }
                }

                if (type === 'images' && !product.isProduct) {
                    dispatch(shopActions.getItems(
                        {...getActionTypes( type )}
                    ))
                }
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    removeItem: ({
                     id,
                     setItemsActionType,
                     setPaginationActionType,
                     type = 'products',
                     itemDeleteActionType = 'DELETE_PRODUCT',
                     setLoadingActionType = 'SET_PRODUCTS_LOADING',
                     contentText = 'You are successfully removed product with id',
                     filters = null,
                     isFiltered = false,
                 }) => dispatch => {

        dispatch({type: setLoadingActionType, payload: true})

        return customFetch({
            url: `${getApiUrl(type)}${type}/${id}/`,
            method: 'delete',
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                dispatch({type: itemDeleteActionType, payload: res.data})
                dispatch(shopActions.getItems({
                    type,
                    setItemsActionType,
                    setPaginationActionType,
                    setLoadingActionType,
                    filters,
                    isFiltered
                }));
                message.success({content: contentText, duration: 2});
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    getItem: ({
                  id,
                  type = 'products',
                  setItemActionType = 'SET_PRODUCT',
                  setLoadingActionType = 'SET_PRODUCTS_LOADING',
                  setProductAttributes = 'SET_ATTRIBUTES_FOR_PRODUCT',
                  isGetProductAttributes = false,
                  disposable_quantity = null
              }) => dispatch => {
        dispatch({type: setLoadingActionType, payload: true})
        dispatch({type: setItemActionType, payload: {}})
        return customFetch({
            url: `${getApiUrl(type)}${type}/${id}/`,
            method: 'get',
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                dispatch({type: isGetProductAttributes ? setProductAttributes : setItemActionType, payload: res.data})
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    getItems: ({
                   params = {},
                   type = 'products',
                   setItemsActionType = 'SET_PRODUCTS',
                   setPaginationActionType = 'SET_PRODUCTS_PAGINATION',
                   setLoadingActionType = 'SET_PRODUCTS_LOADING',
                   filters = {},
                   sorting = {},
                   siteAndWarehousesFilters = {},
                   isFiltered = false,
                   disposable_quantity = null,
                   warehouse_id = null,
               }) => dispatch => {
        const {page, limit} = params;
        dispatch({type: setLoadingActionType, payload: true})

        if (isFiltered && filters) {
            params = {...params, ...filters}
            dispatch({type: SET_IS_FILTERED, payload: false})
        }

        return customFetch({
            url: `${getApiUrl(type)}${type}/${getParams(params)}`,
            method: 'get',
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                dispatch({type: setItemsActionType, payload: res.data})

                dispatch({
                    type: setPaginationActionType,
                    payload: {
                        current: page ?? 1
                    }
                })

                if ((type === 'stock_objects' || type === 'products') && disposable_quantity != null && warehouse_id) {
                    const data = res.data.objects.find( item => item.warehouse.id === warehouse_id );
                    const { id, sku, product_id, warehouse } = data;
                    dispatch(shopActions.updateItem(
                        {
                            id,
                            values: {
                                sku,
                                product_id,
                                disposable_quantity,
                                warehouse_id: warehouse?.id
                            },
                            params: {
                                ...cleanPayload(filters),
                                ...cleanPayload(siteAndWarehousesFilters),
                                ...(sorting && Object.keys(sorting).length > 0 && sorting?.order && {
                                    order_by: sorting.field,
                                    order_type: sorting.order.replace('end', '')
                                })
                            },
                            isMessage: false,
                            ...getActionTypes('stock_objects'),
                            needRefreshProducts: type === 'stock_objects'
                        }
                    ))
                }
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    updateItem: ({
                     values,
                     id,
                     type = 'products',
                     updateActionType = 'UPDATE_PRODUCT',
                     setLoadingActionType = 'SET_PRODUCTS_LOADING',
                     contentText = 'Produkten har uppdaterats',
                     shouldUpdateItems = false,
                     currentPage = 1,
                     allowNull = false,
                     isTrivec = false,
                     isWoocommerce = false,
                     isMessage = true,
                     needRefreshProducts = false,
                     params = { page: currentPage },
                 }) => (dispatch, getState) => {
        dispatch({type: setLoadingActionType, payload: true})

        return customFetch({
            url: isTrivec ? `${getApiUrl(type)}${type}/synchronize/trivec/${id}/` : (isWoocommerce ? `${getApiUrl(type)}${type}/synchronize/woocommerce/${id}/` : `${getApiUrl(type)}${type}/${id}/`),
            method: 'put',
            values: Object.keys(values).length === 0 ? {} : (allowNull ? payloadNullToQuotes(values) : cleanPayload(values)),
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                if (isMessage) {
                    message.success({content: `${contentText}`, duration: 2});
                }
                dispatch({type: updateActionType, payload: res.data});
                dispatch({type: setLoadingActionType, payload: false})

                const { siteAndWarehousesFilters } = getState().products;
                if ( siteAndWarehousesFilters.warehouse_ids ) {
                    params.warehouse_ids = siteAndWarehousesFilters.warehouse_ids;
                }

                if (shouldUpdateItems) {
                    dispatch(shopActions.getItems({
                        ...getActionTypes(type),
                        params: cleanPayload(params)
                    }));
                }

                if (needRefreshProducts) {
                    dispatch(shopActions.getItems({
                            ...getActionTypes('products'),
                            params
                        }));
                }
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    batchUpdate: ({
                      values,
                      type = 'products',
                      setLoadingActionType = 'SET_PRODUCTS_LOADING',
                      contentText = 'Alla Produkter har uppdaterats',
                  }) => dispatch => {

        dispatch({type: setLoadingActionType, payload: true})

        return customFetch({
            url: `${getApiUrl(type)}${type}/batch_update/`,
            method: 'put',
            values: values.map(item => cleanPayload(item)),
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: () => {
                dispatch(shopActions.getItems({
                    ...getActionTypes(type)
                }));
                dispatch({type: setLoadingActionType, payload: false})
                message.success(`${contentText}`, 2);
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    setItemNull: (SET_ITEM, payload = {}) => {
        return {
            type: SET_ITEM,
            payload
        }
    },
    setItemsNull: (SET_ITEMS) => {
        return {
            type: SET_ITEMS,
            payload: {}
        }
    },
    clearCache: ({type, contentText}) => dispatch => {
        dispatch({type: CLEAR_CACHE_LOADING, payload: true})
        return customFetch({
            url: `${getApiUrl(type)}${type}/clear_satellite_cache/`,
            method: 'post',
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                dispatch({type: CLEAR_CACHE, payload: res.data})
                message.success(contentText);
            },
            setLoading: () => {
                dispatch({type: CLEAR_CACHE_LOADING, payload: false})
            }
        })
    },
    reorder: ({type, id, order_id, params}) => (dispatch, getState) => {

        const {setLoadingActionType} = getActionTypes(type);

        dispatch({type: setLoadingActionType, payload: true})

        return customFetch({
            url: `${getApiUrl(type)}${type}/reorder/${id}/`,
            method: 'post',
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            values: {order_id},
            successCallback: () => {
                const { siteAndWarehousesFilters } = getState().products;
                if ( siteAndWarehousesFilters.warehouse_ids ) {
                    params.warehouse_ids = siteAndWarehousesFilters.warehouse_ids;
                }

                dispatch(shopActions.getItems({
                    ...getActionTypes(type),
                    params: cleanPayload(params)
                }));
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    }
}
