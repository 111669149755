import {renderDateYMD} from "../../../helpers/helpers";

const initialState = {
    picklists: {
        objects: [],
        total: 0,
        page: 1,
    },
    picklist: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    isViewPicklistsTableActive: true,
    isViewTotalTableActive: false,
    isPrintAllButtonPressed: false,
    picklistLoading: false,
    picklistOrderPopup: {
      visible: false,
      orderId: null,
    },
    picklistFormRef: null,
    filters: {
        name: null,
        date: null,
        delivery_date: {delivery_date_to: renderDateYMD(new Date()), delivery_date_from: renderDateYMD(new Date())},
        order_number: {min: null, max: null},
        phone_number: null,
        email: null,
        status: null,
        price: { min: null, max: null},
        shipping_method: [],
        customer_first_name: null,
        customer_last_name: null,
        products: []
    },
    productsNames: [],
    picklistTableSorting: {}
}

export const SET_PICKLISTS = 'SET_PICKLISTS';
export const SET_PICKLISTS_FORM_REF = 'SET_PICKLISTS_FORM_REF';
export const SET_IS_VIEW_PICKLISTS_TABLE_ACTIVE = 'SET_IS_VIEW_PICKLISTS_TABLE_ACTIVE';
export const SET_IS_VIEW_TOTAL_TABLE_ACTIVE = 'SET_IS_VIEW_TOTAL_TABLE_ACTIVE';
export const SET_PICK_LIST_ORDER_POPUP = 'SET_PICK_LIST_ORDER_POPUP';
export const SET_PICK_LIST_PRODUCTS_NAMES = 'SET_PICK_LIST_PRODUCTS_NAMES';
export const SET_PICK_LISTS_IS_PRINT_ALL_BUTTON_PRESSED = 'SET_PICK_LISTS_IS_PRINT_ALL_BUTTON_PRESSED';
export const SET_PICKLISTS_LOADING = 'SET_PICKLIST_LOADING';
export const SET_PICKLISTS_PAGINATION = 'SET_PICKLIST_PAGINATION';
export const SET_PICKLISTS_FILTERS = 'SET_PICKLISTS_FILTERS';
export const SET_PICKLISTS_TABLE_SORTING = 'SET_PICKLISTS_TABLE_SORTING';

let payload;
const picklistReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PICKLISTS:
            return {...state, picklists: action.payload, picklistLoading: false}
        case SET_PICKLISTS_FORM_REF:
            return {...state, picklistFormRef: action.payload}
        case SET_PICKLISTS_FILTERS:
            return {...state, filters: action.payload}
        case SET_PICK_LISTS_IS_PRINT_ALL_BUTTON_PRESSED:
            return {...state, isPrintAllButtonPressed: action.payload}
        case SET_PICK_LIST_ORDER_POPUP:
            payload = action.payload;
            return {...state, picklistOrderPopup: {
                    visible: payload.visible ?? state.picklistOrderPopup.visible,
                    orderId: typeof payload.orderId !== 'undefined' ? payload.orderId : state.picklistOrderPopup.orderId
                }, picklistLoading: false}
        case SET_IS_VIEW_PICKLISTS_TABLE_ACTIVE:
            return {...state, isViewPicklistsTableActive: action.payload, picklistLoading: false}
        case SET_PICK_LIST_PRODUCTS_NAMES:
            return {...state, productsNames: action.payload}
        case SET_IS_VIEW_TOTAL_TABLE_ACTIVE:
            return {...state, isViewTotalTableActive: action.payload, picklistLoading: false}
        case SET_PICKLISTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_PICKLISTS_LOADING:
            return {...state, picklistLoading: action.payload}
        case SET_PICKLISTS_TABLE_SORTING:
            return {...state, picklistTableSorting: action.payload}
        default:
            return state;
    }
}

export default picklistReducer;
