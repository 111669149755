import React from 'react';
import {Form, InputNumber} from "antd";

const HiddenImagesFromItems = ({isCategory}) => {
    return (
       <>
           <Form.Item name='featured_image' style={{display: 'none'}}>
               <InputNumber hidden={true}/>
           </Form.Item>
           {
               !isCategory &&
               <Form.Item name='images' style={{display: 'none'}}>
                   <InputNumber hidden={true}/>
               </Form.Item>
           }
       </>
    );
};

export default HiddenImagesFromItems;
