import React from 'react';

import {Row} from "antd";

import DefaultTableForPrint from "@components/PickList/PickListOrderPopup/PickListPrintTable/DefaultTableForPrint";
import AntTable from "@components/AntTable/AntTable";

import {dashboardColumns} from "@antTable/dashboardColumns";

const PickListTables = ({summary, picklistLoading, rangeDate}) => {
    return (
        <Row justify='space-between' style={{width: '100%', marginBottom: '20px'}}>
            <AntTable dataSource={summary.products}
                      loading={picklistLoading}
                      style={{width: 'calc(50% - 20px)'}}
                      rowSelection={false}
                      rowKey={row => row.name + Math.random()}
                      rangeDate={rangeDate}
                      pagination={false}
                      isPaginationDisabled={true}
                      columns={dashboardColumns({type: 'pick-lists'}).picklistTotalProductsColumns}
            />
            <AntTable style={{width: 'calc(50% - 20px)'}}
                      dataSource={summary.shipping_methods}
                      loading={picklistLoading}
                      rowSelection={false}
                      rowKey={row => row.name + Math.random()}
                      rangeDate={rangeDate}
                      pagination={false}
                      isPaginationDisabled={true}
                      columns={dashboardColumns({type: 'pick-lists'}).picklistTotalDeliveryColumns}
            />
            <table cellSpacing={0}
                   cellPadding={0}
                   style={{width: '100%'}}
                   className='view-all-print-table not-visible'>
               <tbody>
               <tr>
                   <td valign='top' style={{width: '50%'}}>
                       <DefaultTableForPrint
                           headRows={dashboardColumns({type: 'pick-lists'}).picklistTotalProductsColumns.map(th => th.title)}
                           bodyContent={
                               summary && summary.products.map((product, i) => {
                                   return (
                                       <tr key={product.name + '_' + i}>
                                           <td>{product.name}</td>
                                           <td style={{textAlign: 'center'}}>{product.quantity}</td>
                                       </tr>
                                   )
                               })
                           }
                       />
                   </td>
                   <td valign='top' style={{width: '50%'}}>
                       <DefaultTableForPrint
                           headRows={dashboardColumns({type: 'pick-lists'}).picklistTotalDeliveryColumns.map(th => th.title)}
                           bodyContent={
                               summary.shipping_methods && summary.shipping_methods.map((shippingMethod, i) => {
                                   return (
                                       <tr key={shippingMethod.name+ '_' + i}>
                                           <td>{shippingMethod.name}</td>
                                           <td style={{textAlign: 'center'}}>{shippingMethod.count}</td>
                                       </tr>
                                   )
                               })
                           }
                       />
                   </td>
               </tr>
               </tbody>
            </table>
        </Row>
    );
};

export default PickListTables;
