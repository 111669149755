const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

const initialState = {
    user,
    loading: false,
    loginValues: {},
    userAttributes: null,
    pool: null,
    mfaCode: null,
    tokenExpire: localStorage.getItem('tokenExpire'),
    isNewPassFormVisible: false,
    logout: false,
}

export const SET_USER = 'SET_USER';
export const SET_LOGIN_VALUES = 'SET_LOGIN_VALUES';
export const SET_TOKEN_EXPIRE = 'SET_TOKEN_EXPIRE';
export const SET_USER_ATTRIBUTES = 'SET_USER_ATTRIBUTES';
export const SET_POOL = 'SET_POOL';
export const SET_MFA_CODE = 'SET_MFA_CODE';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';
export const SET_NEW_PASS_FORM_VISIBLE = 'SET_NEW_PASS_FORM_VISIBLE';
export const SET_LOGOUT = 'SET_LOGOUT';

const loginReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {...state, user: action.payload, loading: false}
        case SET_LOGIN_VALUES:
            return {...state, loginValues: action.payload}
        case SET_USER_ATTRIBUTES:
            return {...state, userAttributes: action.payload}
        case SET_POOL:
            return {...state, pool: action.payload}
        case SET_MFA_CODE:
            return {...state, mfaCode: action.payload}
        case SET_TOKEN_EXPIRE:
            return {...state, tokenExpire: action.payload}
        case SET_LOGIN_LOADING:
            return {...state, loading: action.payload}
        case SET_NEW_PASS_FORM_VISIBLE:
            return {...state, isNewPassFormVisible: action.payload}
        case SET_LOGOUT:
            return {...state, logout: action.payload, loading: false}
        default:
            return state
    }
}

export default loginReducer
