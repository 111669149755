import React from 'react';
import {Form, Input, Button,Col, Collapse, Popconfirm} from 'antd';
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import 'react-quill/dist/quill.snow.css';
import QuillOrInput from "../../QuillEditor/QuillOrInput/QuillOrInput";

const RepeaterFields = ({name = 'meta_data', buttonText = 'Lägg till metadata', initialValues = []}) => {
    const {Panel} = Collapse;
    const accordionHeader = (field) => {
        if (initialValues && initialValues.length) {
            return 'Metadata: ' + initialValues[field.key]?.meta_key
        }

        return 'Metadata';
    }

    return (
        <Form.List
            {...initialValues.length > 0 && {initialValue: initialValues}}
            name={name}
        >
            {(fields, {add, remove}) => (
                <>
                <Collapse expandIconPosition='end' accordion style={{marginBottom: '20px'}}>
                {fields.map((field, index) => (
                    <Panel forceRender={true} style={{width: '100%'}} header={accordionHeader(field)} key={index + '_ind'}>
                            <Col style={{width: '80%'}}>
                                <Form.Item
                                    {...field}
                                    wrapperCol={{span: 18}}
                                    labelCol={{span: 4}}
                                    label="Metadatanamn"
                                    key={`meta_key_${index}`}
                                    name={[field.name, 'meta_key']}
                                    rules={[{required: true, message: 'Metadatanamn saknas'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="Metadatavärde"
                                    wrapperCol={{span: 18}}
                                    labelCol={{span: 4}}
                                    key={`meta_value_${index}`}
                                    rules={[{required: true, message: 'Metadatavärde saknas'}]}
                                >
                                    <QuillOrInput index={index} field={field} initialValues={initialValues}/>
                                </Form.Item>
                            </Col>
                            <Col style={{width: '20%'}}>
                                <Popconfirm title={'Är du säker på att du vill ta bort detta?'} placement={"topRight"} onConfirm={() => remove(field.name)}>
                                    <Button danger icon={<DeleteOutlined />}>
                                        Remove
                                    </Button>
                                </Popconfirm>
                            </Col>
                    </Panel>
                    ))
                }
                </Collapse>
                <Form.Item>
                    <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{width: '40%'}}
                        icon={<PlusOutlined/>}
                    >
                        {buttonText}
                    </Button>
                </Form.Item>
                </>
            )}
        </Form.List>
    );
};

export default RepeaterFields;
