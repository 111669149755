const initialState = {
    stockObjects: {
        objects: [],
        total: 0,
        page: 1,
    },
    stockObject: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    stockObjectsTableFilters: {},
    stockObjectsTableSorting: {},
    loading: false,
}

export const SET_STOCK_OBJECT = 'SET_STOCK_OBJECT';
export const SET_STOCK_OBJECTS = 'SET_STOCK_OBJECTS';
export const SET_STOCK_OBJECTS_LOADING = 'SET_STOCK_OBJECTS_LOADING';
export const SET_STOCK_OBJECTS_PAGINATION = 'SET_STOCK_OBJECTS_PAGINATION';
export const UPDATE_STOCK_OBJECT = 'UPDATE_STOCK_OBJECT';
export const DELETE_STOCK_OBJECT = 'DELETE_STOCK_OBJECT';
export const ADD_STOCK_OBJECT = 'ADD_STOCK_OBJECT';
export const SET_STOCK_OBJECTS_TABLE_FILTERS = 'SET_STOCK_OBJECTS_TABLE_FILTERS';
export const SET_STOCK_OBJECTS_TABLE_SORTING = 'SET_STOCK_OBJECTS_TABLE_SORTING';

const stockObjectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STOCK_OBJECTS_LOADING:
            return {...state, loading: action.payload};
        case SET_STOCK_OBJECTS_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_STOCK_OBJECTS:
            return {...state, stockObjects: action.payload, loading: false};
        case SET_STOCK_OBJECT:
            return {...state, stockObject: {...action.payload}, loading: false};
        case UPDATE_STOCK_OBJECT:
            return {...state, stockObject: {...action.payload}, loading: false};
        case DELETE_STOCK_OBJECT:
            return {...state, loading: false};
        case SET_STOCK_OBJECTS_TABLE_FILTERS:
            return {...state, stockObjectsTableFilters: action.payload};
        case SET_STOCK_OBJECTS_TABLE_SORTING:
            return {...state, stockObjectsTableSorting: action.payload};
        case ADD_STOCK_OBJECT:
            return {...state, stockObject: action.payload, loading: false};
        default:
            return state;
    }
}

export default stockObjectsReducer;
