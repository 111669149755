import React, {useState} from 'react';
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Button, Form, Input, Row, Spin} from "antd";
import {getActionTypes} from "../../../helpers/getActionTypes";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditImageForm = ({id}) => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const {image, loading} = useSelector(state => state?.images)
    const navigate = useNavigate();
    const {TextArea} = Input

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('images'),
            contentText: `Bild med id #${id} har uppdaterats`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/images');
    };


    if (Object.keys(image).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <Form {...layout}
              name="edit-image"
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>
            <Form.Item name='id' initialValue={image?.id} label='ID'>
                <Input readOnly={true} bordered={false}/>
            </Form.Item>
            <Form.Item name='url' initialValue={image?.url} label='Bild-URL'>
                <Button type='link' style={{padding: '0'}}>
                    <a target='_blank' href={image?.url}>{image?.url}</a>
                </Button>
            </Form.Item>
            <Form.Item name='title' initialValue={image?.title} label='Bildtitel'>
                <Input/>
            </Form.Item>
            <Form.Item name='description' initialValue={image?.description} label='Bildbeskrivning'>
                <TextArea/>
            </Form.Item>
            <Form.Item name='alt_text' initialValue={image?.alt_text} label='Alternativ bildtext'>
                <Input/>
            </Form.Item>
            <Form.Item name='caption' initialValue={image?.caption} label='Bildtext'>
                <Input/>
            </Form.Item>
            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};

export default EditImageForm;
