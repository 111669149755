const initialState = {
    featuredImage: '',
    galleryImages: [],
    isEditPopupVisible: false,
    isMediaPopupVisible: false,
    featuredId: null,
    galleriesId: [],
    isFeaturedImage: false,
    isFeaturedImageUploaded: false,
    isGalleryImagesUploaded: false,
}

export const SET_FEATURED_IMAGE = 'SET_FEATURED_IMAGE'
export const SET_GALLERY_IMAGES = 'SET_GALLERY_IMAGES'
export const REMOVE_FEATURED_IMAGE = 'REMOVE_FEATURED_IMAGE'
export const REMOVE_GALLERY_IMAGE = 'REMOVE_GALLERY_IMAGE'
export const SET_PRODUCT_IMAGE_POPUP_VISIBLE = 'SET_PRODUCT_IMAGE_POPUP_VISIBLE'
export const SET_PRODUCT_MEDIA_POPUP_VISIBLE = 'SET_PRODUCT_MEDIA_POPUP_VISIBLE'
export const SET_IS_FEATURED_IMAGE_UPLOADED = 'SET_IS_FEATURED_IMAGE_UPLOADED'
export const SET_IS_GALLERY_IMAGES_UPLOADED = 'SET_IS_GALLERY_IMAGES_UPLOADED'
export const SET_IS_FEATURED_IMAGE = 'SET_IS_FEATURED_IMAGE'
export const SET_FEATURED_ID = 'SET_FEATURED_ID'

const productImagesReducer =(state= initialState, action) => {
    switch (action.type) {
        case SET_FEATURED_IMAGE:
            return {...state, featuredImage: action.payload}
        case SET_GALLERY_IMAGES:
            return {...state, galleryImages: action.payload }
        case SET_IS_FEATURED_IMAGE:
            return {...state, isFeaturedImage: action.payload}
        case SET_IS_FEATURED_IMAGE_UPLOADED:
            return {...state, isFeaturedImageUploaded: action.payload }
        case SET_IS_GALLERY_IMAGES_UPLOADED:
            return {...state, isGalleryImagesUploaded: action.payload }
        case REMOVE_FEATURED_IMAGE:
            return {...state, featuredImage: ''}
        case REMOVE_GALLERY_IMAGE:
            return {...state, galleryImages: state.galleryImages.filter(img => img.id !== action.payload)}
        case SET_FEATURED_ID:
            return {...state, featuredId: action.payload}
        case SET_PRODUCT_IMAGE_POPUP_VISIBLE:
            return {...state, isEditPopupVisible: action.payload}
        case SET_PRODUCT_MEDIA_POPUP_VISIBLE:
            return {...state, isMediaPopupVisible: action.payload}
        default:
            return state
    }
}

export default productImagesReducer
