import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import useActions from '../../../hooks/useActions';
import { useSelector } from 'react-redux';
import {Button, Col, Pagination, Row} from 'antd';
import {
	CaretDownOutlined,
	CaretUpOutlined,
	UploadOutlined
} from '@ant-design/icons';
import {cleanPayload, renderDateYMD} from '../../../helpers/helpers';
import { getActionTypes } from '../../../helpers/getActionTypes';
import { getUseSelectorFromType } from '../../../helpers/getUseSelectorFromType';
import ImageUploaderPopup from '../../../components/Form/ImageUploader/ImageUplaoderPopup/ImageUploaderPopup';
import ImageUploader from '../../../components/Form/ImageUploader/ImageUploader';
import "./Images.scss";
import GalleryImage from "../../../components/Images/GalleryImage/GalleryImage";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import ImagesWithSpin from "../../../components/Images/ImagesWithSpin/ImagesWithSpin";
import RangeDatePicker from "../../../components/RangeDatePicker/RangeDatePicker";

const Images = ( { type = 'images', title = 'Blider' } ) => {
	const { getItems, setImageUploaderPopupVisible } = useActions();
	const useSelectorType = getUseSelectorFromType( type );
	const { id } = useParams();
	const location = useLocation();
	const images = useSelector( state => state.images.images );
	const { loading } = useSelector( state => state[ useSelectorType ] && state[ useSelectorType ] );
	const [date, setDate] = useState(null);
	const [isDateChanged, setIsDateChanged] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const order = ['asc', 'desc', ''];
	const [sort, setSort] = useState('');
	const [sortingNumber, setSortingNumber] = useState(0);
	const {imagesTableFilters} = useSelector( state => state?.images );

	useEffect( () => {
		if ( !location.pathname.includes( 'new' ) && !id ) {
			const cleanFilters = cleanPayload(imagesTableFilters, true);
			getItems( {
				params: {
					...{ ...( cleanFilters && Object.keys( cleanFilters ).length > 0 && cleanFilters ) },
				},
				...getActionTypes( type ),
			} );
		}
	}, [ location ] );

	useEffect(() => {
		if (!isFirstRender) {
			getItems( {
				params: {...{...sort && {order_by: 'datetime_created', order_type: sort}}, ...cleanPayload(imagesTableFilters, true), ...{...date && {
						datetime_created_from: renderDateYMD(date[0]._d),
						datetime_created_to: renderDateYMD(date[1]._d)
					}}},
				...getActionTypes( type ),
			} );
		}
		setIsFirstRender(false)
	}, [date, sort])

	const paginationChangeHandler = (pagination) => {
		getItems( {
			params: {
				page: pagination,
				...{...date && {
						datetime_created_from: renderDateYMD(date[0]._d),
						datetime_created_to: renderDateYMD(date[1]._d)
					}},
				...cleanPayload(imagesTableFilters, true)
			},
			...getActionTypes( type ),
		} );
	}

	const onSort = () => {
		if (sortingNumber === 2) {
			setSortingNumber(-1)
		}
		setSort(order[sortingNumber])
		setSortingNumber(prev => prev + 1);
	}

	return (
		id || location.pathname.includes( '/new' ) ? <Outlet/>
			:
			<>
				<h2 className="dashboard__content--title">{ title }</h2>
				<>
					<Row className="filter-block"
						 justify="space-between"
						 align="middle">
						<Row  align="middle" >
							<div>
								<span style={{display: 'block'}}>Sök</span>
								<SearchFilter type='images' searchPlaceholder='Sök bilder...' isMainView={true}/>
							</div>
							<div style={{marginLeft: '20px'}}>
								<span>Filtrera på datum</span>
								<RangeDatePicker style={{maxWidth: '250px'}}
											custom={{
												customRange: date,
												customIsRangeChanged: setIsDateChanged,
												isCustom: true,
												customSetIsRangeChanged: setIsDateChanged,
												customSetRange: setDate,
												customFrom: 'datetime_created_from',
												customTo: 'datetime_created_to',
												customName: 'datetime_created'
											}}
								/>
							</div>
							<div style={{marginLeft: '20px'}}>
								<div style={{cursor: 'pointer'}} onClick={onSort}>
									<CaretUpOutlined style={{color: sort === 'asc' ? '#1890FF' : '#bfbfbf', display: 'block'}}/>
									<CaretDownOutlined style={{color: sort === 'desc' ? '#1890FF' : '#bfbfbf', display: 'block'}}/>
								</div>
							</div>

						</Row>
						<Row justify="space-between"
							 align="middle">
							<Button onClick={ () => setImageUploaderPopupVisible( true ) }
									icon={ <UploadOutlined/> }>Klicka för att ladda upp</Button>
							<ImageUploaderPopup>
								<ImageUploader/>
							</ImageUploaderPopup>
						</Row>
					</Row>

					<Row gutter={ [ 16, 16 ] }
						 align='middle'
						 id='gallery-images'
						 justify='space-between'
						 style={{marginBottom: '20px'}}
					>
						{
							!loading ?
							images.objects.map( image => (
								<Col key={ image.id }
									 className='images-grid__col'
									 xs={ 10 }
									 sm={ 8 }
									 md={ 6 }
									 lg={ 3 }
								>
									<GalleryImage image={image}/>
								</Col>
							)) :
							<ImagesWithSpin length={25}/>
						}
					</Row>
					<Pagination onChange={paginationChangeHandler} showSizeChanger={false} pageSize={25} defaultCurrent={images.page} total={images?.total} />
				</>
			</>
	);
};

export default Images;
