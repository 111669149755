import React, {useCallback, useEffect, useState} from 'react';
import useDebounce from "../../hooks/useDebounce";
import {Input} from "antd";
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";

const SearchFilter = ({type, searchPlaceholder = 'Sök...',
                          isMainView = false,
                          customValue = null, setCustomValue = () => {},
                            debValue = null,
                          ...props}) => {
    const [value, setValue] = customValue !== null ? [customValue, setCustomValue] : useState('');
    const debounceValue = debValue !== null ? debValue : useDebounce(value, 300);
    const {getChosenImages, getItems, setImagesFilters} = useActions();
    const [isFirstRender, setIsFirstRender] = useState(false);
    const debounceSearch = useCallback(() => {

        if (!debounceValue && isFirstRender) {
            if (isMainView) {
                getItems({
                    ...getActionTypes(type)
                })
            } else {
                getChosenImages({params: {search: debounceValue}})
            }
            setImagesFilters({title: debounceValue})
        }

        if (debounceValue) {
            if (isMainView) {
                getItems({
                    params: {title: debounceValue},
                    ...getActionTypes(type)
                })
            } else {
                if (debValue === null) {
                    getChosenImages({params: {search: debounceValue}})
                }
            }
            setImagesFilters({title: debounceValue})

        }
        setIsFirstRender(true)


    }, [debounceValue]);

    useEffect(debounceSearch, [debounceValue, debounceSearch])

    const filterChangeHandler = (event) => {
        setValue(event.target.value)
    }

    return (
        <Input style={{maxWidth: '200px'}}
               placeholder={searchPlaceholder}
               onKeyUp={filterChangeHandler}
               {...props}
        />

    );
};

export default SearchFilter;
