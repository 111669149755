import React from 'react';
import {Col, Form, InputNumber, Row, Switch, Input} from "antd";

const StockForm = ({
                       layout,
                       stockFormItem,
                       setStockFormItems,
                       setIsChanged,
                       index,
                       ...props
                   }) => {
    const {warehouse, id, sku, product_id, create, disposable_quantity, active} = stockFormItem;
    const {name: warehouseName, id: warehouseId} = warehouse;

    const onValuesChange = (value, values) => {
        if (create) {
            values.create = true;
        }

        values.id = id;
        values.sku = sku;
        values.product_id = product_id
        values.warehouse = {
            name: warehouseName,
            id: warehouseId
        }

        setStockFormItems(prev => ({
            ...prev,
            [`stock_form_object_${index}`]: values
        }))

        setIsChanged(true)
    }


    return (
        <Form name={`edit-product-stock-objects-${index}`}
              onValuesChange={onValuesChange}
              {...layout}
              {...props}
        >
            <div className='form-table__row form-table__tbody'>
                <div className='form-table__col'>
                    <Form.Item name={['warehouse', 'name']}
                               initialValue={warehouseName}>
                        <Input readOnly={true} bordered={false}/>
                    </Form.Item>
                </div>
                <div className='form-table__col'>
                    <Form.Item name='disposable_quantity'
                               initialValue={disposable_quantity}>
                        <InputNumber/>
                    </Form.Item>
                </div>
                <div className='form-table__col'>
                    <Form.Item name='active' valuePropName="checked" initialValue={active}>
                        <Switch/>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export default StockForm;