import {
    SET_STOCK_OBJECTS_PAGINATION,
    SET_STOCK_OBJECTS_TABLE_FILTERS,
    SET_STOCK_OBJECTS_TABLE_SORTING
} from "../../../reducers/shopReducers/stockObjectsReducer";

export const stockObjectsActions = {
    setStockObjectsFilters: (payload) => (
        {
            type: SET_STOCK_OBJECTS_TABLE_FILTERS,
            payload
        }
    ),
    setStockObjectsSorting: (payload) => (
        {
            type: SET_STOCK_OBJECTS_TABLE_SORTING,
            payload
        }
    ),
    setStockObjectsPagination: (payload) => (
        {
            type: SET_STOCK_OBJECTS_PAGINATION,
            payload
        }
    )
}
