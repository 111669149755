import React from 'react';
import {Col, Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const ImagesWithSpin = ({length = 25, ...props}) => {
    return (
        Array.from({length}).map((item, index) => (
            <Col key={index + "_skeleton"} className='images-grid__col'
                 xs={ 10 }
                 sm={ 8 }
                 md={ 6 }
                 lg={ 3 }
                 {...props}
                >
                <Row justify='center' align='middle' style={{width: '100%', height: '8vw', backgroundColor: '#F5F5F5'}}>
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                </Row>
            </Col>
        ))
    );
};

export default ImagesWithSpin;
