import {SET_FREIGHT_POPUP} from "../../reducers/bookFreightReducer/bookFreightReducer";

export const bookFreightActions = {
    setBookFreightPopup: payload => {
        return {
            type: SET_FREIGHT_POPUP,
            payload
        }
    }
}
