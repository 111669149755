import React from 'react';
import {useSelector} from "react-redux";
import {Select} from "antd";
import "./SitesAndWarehouses.scss"
import useActions from "../../../hooks/useActions";

const SitesAndWarehouses = () => {
    const sites = useSelector(state => state?.sites?.sites)
    const warehouses = useSelector(state => state?.warehouses?.warehouses)
    const siteAndWarehousesFilters = useSelector(state => state?.products?.siteAndWarehousesFilters)
    const {setProductsSiteAndWarehousesFiltersEnabled, setSitesAndWarehousesFilters} = useActions();

    const onSelectChange = (value, values, type) => {
        const isNoValue = !value || value.length === 0;
        const val = (Array.isArray(value) ? value.join(',') : value)

        setSitesAndWarehousesFilters({
            warehouse_ids: type === 'warehouse' ? (isNoValue ? null : val) : siteAndWarehousesFilters.warehouse_ids,
            site_ids: type === 'site' ? (isNoValue ? null : val) : siteAndWarehousesFilters.site_ids,
        })

        setProductsSiteAndWarehousesFiltersEnabled(true)
    }

    const renderSelect = (data, type) => {
        const val = siteAndWarehousesFilters[type + '_ids'];
        const defaultValue = isNaN(val) ? val.split(',').map(item => +item) : +val;
        return (
            <Select onChange={(value, values) => onSelectChange(value, values, type)}
                    showSearch={false}
                    className='multiple-select'
                    mode='multiple'
                    {...val && {defaultValue}}
            >
                {
                    data.map(item => (
                        <Select.Option key={item.name + item.id} value={item.id}>{item.name}</Select.Option>
                    ))
                }
            </Select>
        )
    }

    return (
        <div className='saw-info'>
            <div>
                <span className='saw-info__item saw-info__item--label'>Lagerställen</span>
                <span className='saw-info__item'>
                    {renderSelect(warehouses?.objects, 'warehouse')}
                </span>
            </div>
            <div>
                <span className='saw-info__item saw-info__item--label'>Försäljningsställen</span>
                <span className='saw-info__item'>
                    {renderSelect(sites?.objects, 'site')}
                </span>
            </div>
        </div>
    );
};

export default SitesAndWarehouses;
