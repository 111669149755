import EditProductForm from "../components/Form/EditForms/EditProductForm/EditProductForm";
import EditAttributeForm from "../components/Form/EditForms/EditAttributeForm";
import EditCategoryForm from "../components/Form/EditForms/EditCategoryForm";
import EditTagForm from "../components/Form/EditForms/EditTagForm";
import EditStockObjectForm from "../components/Form/EditForms/EditStockObjectForm";
import EditSiteForm from "../components/Form/EditForms/EditSiteForm";
import EditImageForm from "../components/Form/EditForms/EditImageForm";
import OrderPage from "../pages/Dashboard/Orders/OrderPage/OrderPage";
import EditShippingBaseAddressForm from "../components/Form/EditForms/EditShippingBaseAddressForm";
import PickList from "../pages/Dashboard/PickList/PickList";
import DefaultPage from "../pages/Dashboard/DefaultPage/DefaultPage";
import EditShippingClassForm from "../components/Form/EditForms/EditShippingClassForm";
import EditUpsellObjectForm from "../components/Form/EditForms/EditUpsellObjectForm";
import EditShippingMethodForm from "../components/Form/EditForms/EditShippingMethodForm/EditShippingMethodForm";
import Cache from "../pages/Dashboard/Cache/Cache";
import UpsellObjects from "../pages/Dashboard/UpsellObjects/UpsellObjects";
import Products from "../pages/Dashboard/Products/Products";
import Attributes from "../pages/Dashboard/Attributes/Attributes";
import Categories from "../pages/Dashboard/Categories/Categories";
import Images from "../pages/Dashboard/Images/Images";
import Tags from "../pages/Dashboard/Tags/Tags";
import ShippingBaseAddresses from "../pages/Dashboard/ShippingBaseAddresses/ShippingBaseAddresses";
import ShippingClasses from "../pages/Dashboard/ShippingClasses/ShippingClasses";
import ShippingMethods from "../pages/Dashboard/ShippingMethods/ShippingMethods";
import StockObjects from "../pages/Dashboard/StockObjects/StockObjects";
import Orders from "../pages/Dashboard/Orders/Orders";
import Sites from "../pages/Dashboard/Sites/Sites";

export const paths = [
    {
        type: 'products',
        title: 'Produkter',
        page: Products,
        editForm: EditProductForm
    },
    {
        type: 'attributes',
        title: 'Attribut',
        page: Attributes,
        editForm: EditAttributeForm
    },
    {
        type: 'categories',
        title: 'Kategorier',
        page: Categories,
        editForm: EditCategoryForm
    },
    {
        type: 'tags',
        title: 'Taggar',
        page: Tags,
        editForm: EditTagForm
    },
    {
        type: 'orders',
        title: 'Ordrar',
        page: Orders,
        editForm: OrderPage
    },
    {
        type: 'shipping-base-addresses',
        title: 'Avsändaradresser',
        page: ShippingBaseAddresses,
        editForm: EditShippingBaseAddressForm
    },
    {
        type: 'shipping-classes',
        title: 'Fraktklasser',
        page: ShippingClasses,
        editForm: EditShippingClassForm
    },
    {
        type: 'shipping-methods',
        title: 'Fraktmetoder',
        page: ShippingMethods,
        editForm: EditShippingMethodForm
    },
    {
        type: 'stock_objects',
        title: 'Lagerobjekt',
        page: StockObjects,
        editForm: EditStockObjectForm
    },
    {
        type: 'sites',
        title: 'Försäljningsställen',
        page: Sites,
        editForm: EditSiteForm
    },
    {
        type: 'images',
        title: 'Bilder',
        page: Images,
        editForm: EditImageForm
    },
    {
        type: 'pick-lists',
        title: 'Plocklista',
        page: PickList,
        editForm: null,
    },
    {
        type: 'user',
        title: 'Användare',
        page: DefaultPage,
        editForm: null
    },
    {
        type: 'rights',
        title: 'Rättigheter',
        page: DefaultPage,
        editForm: null
    },
    {
        type: 'cache',
        title: 'Cache',
        page: Cache,
        editForm: null
    },
    {
        type: 'upsell-objects',
        title: 'Tilläggsköp',
        page: UpsellObjects,
        editForm: EditUpsellObjectForm
    }
]
