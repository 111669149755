import React, {useEffect} from 'react';
import {getColumns} from "../../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../../ant-table/dashboardColumns";
import {Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import AntTable from "../../../components/AntTable/AntTable";
import {getTextFromType} from "../../../helpers/helpers";
import {getActionTypes} from "../../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../../helpers/getUseSelectorFromType";
import {SET_SHIPPING_CLASSES} from "../../../redux-store/reducers/shopReducers/shippingClassesReducer";

const ShippingMethods = ({type = 'shipping_methods', title = 'Fraktklasser'}) => {
    const {getItems, removeItem, setShippingMethodsFilters, setShippingMethodsSorting, setShippingMethodsPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const items = useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]);
    const {loading, pagination} = useSelector(state => state[useSelectorType] && state[useSelectorType]);

    const {
        shippingMethodsTableFilters,
        shippingMethodsTableSorting} = useSelector(state => state?.shippingMethods)

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...{...(shippingMethodsTableFilters && Object.keys(shippingMethodsTableFilters).length > 0 && shippingMethodsTableFilters)},
                    ...(shippingMethodsTableSorting && Object.keys(shippingMethodsTableSorting).length > 0 && shippingMethodsTableSorting?.order && {
                        order_by: shippingMethodsTableSorting.field,
                        order_type: shippingMethodsTableSorting.order.replace('end', '')
                    })
                },
                ...getActionTypes(type)
            });

            getItems({
                ...getActionTypes('sites')
            });
        }
        setItemsNull(SET_SHIPPING_CLASSES, {
            objects: [],
            total: 0,
            page: 1,
        })
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                filters: shippingMethodsTableFilters,
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row className='filter-block' justify='end' align='middle'>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>

                        <AntTable initialData={items} total={items?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setShippingMethodsFilters}
                                  setSorting={setShippingMethodsSorting}
                                  setPagination={setShippingMethodsPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default ShippingMethods;
