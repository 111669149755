const initialState = {
    images: {
        objects: [],
        total: 0,
        page: 1,
    },
    image: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    imagesTableFilters: {},
    imagesTableSorting: {},
    loading: false,
}

export const SET_IMAGE = 'SET_IMAGE';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_IMAGES_LOADING = 'SET_IMAGES_LOADING';
export const SET_IMAGES_PAGINATION = 'SET_IMAGES_PAGINATION';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const ADD_IMAGE = 'ADD_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const SET_IMAGES_TABLE_FILTERS = 'SET_IMAGES_TABLE_FILTERS';
export const SET_IMAGES_TABLE_SORTING = 'SET_IMAGES_TABLE_SORTING';

const imagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IMAGES_LOADING:
            return {...state, loading: action.payload};
        case SET_IMAGES_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_IMAGES:
            return {...state, images: action.payload, loading: false};
        case SET_IMAGE:
            return {...state, image: {...action.payload}, loading: false};
        case UPDATE_IMAGE:
            return {...state, image: {...action.payload}, loading: false};
        case ADD_IMAGE:
            return {...state, image: action.payload, loading: false};
        case DELETE_IMAGE:
            return {...state, loading: false};
        case SET_IMAGES_TABLE_FILTERS:
            return {...state, imagesTableFilters: action.payload};
        case SET_IMAGES_TABLE_SORTING:
            return {...state, imagesTableSorting: action.payload};
        default:
            return state;
    }
}

export default imagesReducer;
