const initialState = {
    shippingBaseAddresses: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingBaseAddress: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shippingBaseAddressesTableFilters: {},
    shippingBaseAddressesTableSorting: {},
    loading: false,
}

export const SET_SHIPPING_BASE_ADDRESS = 'SET_SHIPPING_BASE_ADDRESS';
export const SET_SHIPPING_BASE_ADDRESSES = 'SET_SHIPPING_BASE_ADDRESSES';
export const SET_SHIPPING_BASE_ADDRESSES_LOADING = 'SET_SHIPPING_BASE_ADDRESSES_LOADING';
export const SET_SHIPPING_BASE_ADDRESSES_PAGINATION = 'SET_SHIPPING_BASE_ADDRESSES_PAGINATION';
export const UPDATE_SHIPPING_BASE_ADDRESS = 'UPDATE_SHIPPING_BASE_ADDRESS';
export const ADD_SHIPPING_BASE_ADDRESS = 'ADD_SHIPPING_BASE_ADDRESS';
export const REMOVE_SHIPPING_BASE_ADDRESS = 'REMOVE_SHIPPING_BASE_ADDRESS';
export const SET_SHIPPING_BASE_ADDRESSES_TABLE_FILTERS = 'SET_SHIPPING_BASE_ADDRESSES_TABLE_FILTERS';
export const SET_SHIPPING_BASE_ADDRESSES_TABLE_SORTING = 'SET_SHIPPING_BASE_ADDRESSES_TABLE_SORTING';

const shippingBaseAddressesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_BASE_ADDRESSES_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_BASE_ADDRESSES_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_SHIPPING_BASE_ADDRESSES:
            return {...state, shippingBaseAddresses: action.payload, loading: false};
        case SET_SHIPPING_BASE_ADDRESS:
            return {...state, shippingBaseAddress: {...action.payload}, loading: false};
        case UPDATE_SHIPPING_BASE_ADDRESS:
            return {...state, shippingBaseAddress: {...action.payload}, loading: false};
        case ADD_SHIPPING_BASE_ADDRESS:
            return {...state, shippingBaseAddress: {...action.payload}, loading: false};
        case REMOVE_SHIPPING_BASE_ADDRESS:
            return {...state, loading: false};
        case SET_SHIPPING_BASE_ADDRESSES_TABLE_FILTERS:
            return {...state, shippingBaseAddressesTableFilters: action.payload};
        case SET_SHIPPING_BASE_ADDRESSES_TABLE_SORTING:
            return {...state, shippingBaseAddressesTableSorting: action.payload};
        default:
            return state;
    }
}

export default shippingBaseAddressesReducer
