import React, {useState} from 'react';
import {Image, Popconfirm} from "antd";
import {getFallbackImage, getTextFromType} from "../../../helpers/helpers";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {getActionTypes} from "../../../helpers/getActionTypes";
import useActions from "../../../hooks/useActions";

const GalleryImage = ({image}) => {
    const [visible, setVisible] = useState(false);
    const {removeItem} = useActions();

    const removeItemHandler = () => {
        const id = image.id;

        removeItem( {
            id,
            ...getActionTypes( 'images' ),
            contentText: `${ getTextFromType( { string: 'images' } ) } med ID ${ id } har raderats`,
        } );
    }


    return (
        <>
            <div className='images-actions'>
                <EyeOutlined onClick={() => setVisible(prev => !prev)}/>
                <Link to={'/images/' + image.id}>
                    <EditOutlined />
                </Link>
                <Popconfirm title='Är du säker på att du vill ta bort detta bilder?' okText='Yes' cancelText="No" placement="topRight"
                            onConfirm={removeItemHandler}>
                    <DeleteOutlined/>
                </Popconfirm>

                {
                    image.url &&
                    <h4 className='images-actions__title'>
                        {image.title ?? image.url.split('/')[image.url.split('/').length - 1].replaceAll('_', ' ').replaceAll('-', ' ')}
                    </h4>
                }
            </div>
            <Image className='images-grid__image'
                   src={ image.url }
                   preview={{ visible: false }}
                   fallback={getFallbackImage()}
                   width="100%"
                   height="8vw"
            />

            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    <Image src={image.url} alt={image.alt_text} title={image.title}/>
                </Image.PreviewGroup>
            </div>
        </>
    );
};

export default GalleryImage;
