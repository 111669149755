import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Row, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../hooks/useActions";
import {getActionTypes} from "../../../helpers/getActionTypes";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditStockObjectForm = ({id}) => {
    const {updateItem, getItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const loading = useSelector(state => state?.stockObjects?.loading)
    const stockObject = useSelector(state => state?.stockObjects?.stockObject)
    const product = useSelector(state => state?.products?.product)
    const navigate = useNavigate();
    const formRef = createRef();

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('stock_objects'),
            contentText: `Lagerobject med id ${id} har uppdaterats framgångsrikt!`
        });
        setIsChanged(false);
    };

    useEffect(() => {
        if (Object.keys(stockObject).length === 0) return;
        getItem({
          id: stockObject.product_id,
          type: 'products'
        })
    }, [stockObject]);


    const cancelHandler = () => {
        navigate('/stock_objects');
    };


    if (Object.keys(stockObject).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    const initialValues = {
        product_id: stockObject?.product_id,
        sku: stockObject?.sku,
        disposable_quantity:stockObject?.disposable_quantity
    }

    return (
        <Form name='edit-stock-object'
              ref={formRef}
              layout={layout}
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              initialValues={initialValues}
              validateMessages={validateMessages}
        >
            {
                Object.keys(product).length > 0 &&
                <Form.Item label='Produktnamn'>
                    <span>{product.title}</span>
                </Form.Item>
            }
            <Form.Item name='product_id' label='Produkt-ID'>
                <InputNumber bordered={false} readOnly={true}/>
            </Form.Item>
            <Form.Item name='sku' label='SKU'>
                <Input bordered={false} readOnly={true}/>
            </Form.Item>
            <Form.Item name='disposable_quantity' label='Disponibelt antal i lager' rules={[
                {
                    pattern: /^\d*[1-9]\d*$/,
                    message: 'Nummer måste vara positiva'
                }
            ]}>
                <InputNumber/>
            </Form.Item>
            <Form.Item wrapperCol={{...layout.wrapperCol}}>
                <Row>
                    {
                        isChanged ?
                            <Button style={{marginRight: '20px'}} type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    )
};

export default EditStockObjectForm;
