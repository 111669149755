import React, {createRef, useState} from 'react';
import {Button, Form, Input, Row, Spin, InputNumber} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../hooks/useActions";
import FormImages from "../ProductImages/FormImages/FormImages";
import CustomSelect from "../CustomSelect/CustomSelect";
import {getActionTypes} from "../../../helpers/getActionTypes";
import SitesFieldTable from "../../SitesFieldTable/SitesFieldTable";

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditCategoryForm = ({id}) => {
    const {updateItem} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const category = useSelector(state => state?.categories?.category)
    const loading = useSelector(state => state?.categories?.loading)
    const navigate = useNavigate();
    const formRef = createRef()

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        const newValues = {...values}
        newValues.parent_id = +newValues.parent_id;

        if (newValues?.external_categories) {
            newValues.external_categories = newValues?.external_categories?.map(item => {
                const {site_name, ...rest} = item;
                return rest;
            })
        }

        updateItem({
            values: newValues,
            id,
            ...getActionTypes('categories'),
            contentText: `Kategorin med id ${id} har uppdaterats`
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/categories');
    };

    if (Object.keys(category).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <Form {...layout}
              name="edit-category"
              ref={formRef}
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>

            <SitesFieldTable shouldAddClasses={false} item={category} isPublishStatus={false} formName='external_categories' isSiteNameRequired={false}/>

            <Form.Item
                name='name'
                label="Namn"
                initialValue={category?.name}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name='description'
                label="Beskrivning"
                initialValue={category?.description}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input.TextArea/>
            </Form.Item>

            <Form.Item
                name='menu_order'
                label="Menyordning"
                initialValue={category?.menu_order}
            >
                <InputNumber/>
            </Form.Item>

            <Form.Item
                name='parent_id'
                label="Förälderkategori">
                <CustomSelect initialValue={category.parent_id?.id}
                              formItem={{isFormItem: true}}
                              name='parent_id'
                              modeType='single'
                              type='categories'/>
            </Form.Item>

            <FormImages
                initialFeaturedImageValue={category?.featured_image?.id ?? null}
                isCategory={true}
                formRef={formRef}
                setIsChanged={setIsChanged}/>

            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};

export default EditCategoryForm;
