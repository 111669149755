import React, {useState} from 'react';
import {Button, Drawer, Space} from "antd";
import PickListFilterForm from "./PickListFilterForm/PickListFilterForm";
import './pickListFilters.scss'
import useActions from "../../../hooks/useActions";
import PickListActiveFilters from "../PickListActiveFilters/PickListActiveFilters";
import {getParamsFromObject} from "../../../helpers/helpers";
import {useSelector} from "react-redux";
import moment from "moment";
import {getDateFormat} from "../../../helpers/getDate";

const PickListFilters = ({deliveryRangeDate, isDeliveryRangeChanged, setDeliveryRangeDate, setIsDeliveryRangeChanged}) => {
    const {getPicklists, setPicklistFilters} = useActions()
    const {productsNames, picklistFormRef, filters} = useSelector(state => state?.picklist)
    const [isPicklistFiltersPopupVisible, setIsPicklistFiltersPopupVisible] = useState(false);
    const dateFormat = getDateFormat();
    const todayDate = [moment(new Date(), dateFormat), moment(new Date(), dateFormat)];
    const [rangeDate, setRangeDate] = useState(filters?.date ? [moment(new Date(filters.date.date_from), dateFormat), moment(new Date(filters.date.date_to), dateFormat)] : todayDate)
    const [isRangeChanged, setIsRangeChanged] = useState(false);

    const submitHandler = () => {
        if( picklistFormRef.current ) {
            picklistFormRef.current.validateFields().then(() => {
                const values = picklistFormRef.current.getFieldsValue();

                getPicklists({
                    params: {
                        summary: 1,
                        ...getParamsFromObject(values)
                    }
                })

                const filtersValues = {...values};

                if (values?.sku?.length > 0) {
                    filtersValues.sku = productsNames;
                }
                setPicklistFilters(filtersValues)
                setIsPicklistFiltersPopupVisible(false)
            })
                .catch(err => {
                    console.log('err', err)
                })
        }
    }

    return (
        <>
            <PickListActiveFilters rangeDate={rangeDate}
                                   setRangeDate={setRangeDate}
                                   isRangeChanged={isRangeChanged}
                                   setIsRangeChanged={setIsRangeChanged}
                                   setIsPicklistFiltersPopupVisible={setIsPicklistFiltersPopupVisible}/>
            <Drawer
                title="Filtrera plocklista"
                width={700}
                forceRender={true}
                onClose={() => setIsPicklistFiltersPopupVisible(false)}
                open={isPicklistFiltersPopupVisible}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={() => setIsPicklistFiltersPopupVisible(false)}>Avbryt</Button>
                        <Button onClick={submitHandler} type="primary">
                            Filtrera
                        </Button>
                    </Space>
                }
            >
                <PickListFilterForm rangeDate={rangeDate}
                                    setRangeDate={setRangeDate}
                                    isRangeChanged={isRangeChanged}
                                    setIsRangeChanged={setIsRangeChanged}
                                    deliveryRangeDate={deliveryRangeDate}
                                    isDeliveryRangeChanged={isDeliveryRangeChanged}
                                    setDeliveryRangeDate={setDeliveryRangeDate}
                                    setIsDeliveryRangeChanged={setIsDeliveryRangeChanged}
                />
            </Drawer>
        </>
    );
};

export default PickListFilters;
