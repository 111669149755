import React from 'react';
import {Button, Modal} from "antd";
import useActions from "../../../../hooks/useActions";
import {useSelector} from "react-redux";

const TypeImagesPopup = () => {
    const {setImageUploaderPopupVisible, setProductImagePopupVisible, setProductMediaPopupVisible} = useActions();
    const {isEditPopupVisible} = useSelector(state => state?.productImages)
    return (
        <Modal open={isEditPopupVisible} footer={false} okText='Välj'
               onCancel={() => setProductImagePopupVisible(false)}>
            <Button
                onClick={() => setImageUploaderPopupVisible(true)}
                style={{marginRight: '20px'}}>
                Ladda upp från datorn</Button>
            <Button
                onClick={() => setProductMediaPopupVisible(true)}>
                Välj från Media-galleriet
            </Button>
        </Modal>
    );
};

export default TypeImagesPopup;
