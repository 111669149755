import {
    SET_CATEGORIES_PAGINATION,
    SET_CATEGORIES_TABLE_FILTERS,
    SET_CATEGORIES_TABLE_SORTING
} from "../../../reducers/shopReducers/categoriesReducer";

export const categoriesActions = {
    setCategoriesFilters: (payload) => (
        {
            type: SET_CATEGORIES_TABLE_FILTERS,
            payload
        }
    ),
    setCategoriesSorting: (payload) => (
        {
            type: SET_CATEGORIES_TABLE_SORTING,
            payload
        }
    ),
    setCategoriesPagination: (payload) => (
        {
            type: SET_CATEGORIES_PAGINATION,
            payload
        }
    )
}
