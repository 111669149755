import React, {forwardRef, useEffect} from 'react';

import {useSelector} from "react-redux";

import useActions from "@hooks/useActions";

import PickListTables from "@components/PickList/PickListTables/PickListTables";
import {useReactToPrint} from "react-to-print";


const PickListTablesWrapper = forwardRef((
    {
        activeButtons = {isViewTotalActive: false},
        summary,
        loading = false
    }, printRef) => {

    if (!activeButtons.isViewTotalActive || !summary | summary?.length === 0) {
        return null;
    }

    const {isPrintAllButtonPressed} = useSelector(state => state?.picklist);
    const {setIsPrintAllButtonPressed} = useActions();

    const print = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        if (isPrintAllButtonPressed && activeButtons.isViewTotalActive && printRef && printRef.current) {
            print();
            setIsPrintAllButtonPressed(false);
        }

    }, [isPrintAllButtonPressed, activeButtons, printRef])

    const tableCss = `  
        @media all {
          .page-break {
            display: none;
          }
          
        @media print {     
          html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
          }
    
          body {
            padding: 0px 50px;
            margin-top: 20px;
            font-family: 'Dia-Regular', sans-serif !important;
          }
          
          body * {
           line-height: 22px !important;
          }

          table {
             width: 100%;
             text-align: left;
          }
        
          table tr {
           border: 1px solid #000;
          }
          
          strong {
            font-size: 20px;
         }
         
         .view-all-print-table {
            width: 100%;
         }
           
          .view-all-print-table > tbody > tr, table.view-all-print-table {
            border: none;
          }
          
          .view-all-print-table > tbody > tr > td {
            width: 50%;
            border: none;
          }
          
          table thead {
            background-color: #ffffff;
          }
          
          table th:nth-child(2) {
            text-align: center;
          }
          
          
          table th, table td {
            padding: 3px;
            white-space: nowrap;
          }
          
          table th:not(:last-child), table td:not(:last-child) {
            border-right: 1px solid #000;
          }
          
          .ant-table-wrapper {
            display: none !important;
          }
          
          .not-visible {
            display: block !important;
          }
          
           .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: always;
          }
        }
        
        @page {
          size: auto;
        }
    `;


    return (
        <div ref={printRef}>
            {
                summary?.map((sum, i) => (
                        <React.Fragment key={sum.delivery_date + '_' + i}>
                            {
                                sum.delivery_date &&
                                <strong style={{marginBottom: '10px', display: 'block'}}>{sum.delivery_date.includes('.') ? sum.delivery_date.split('.')[0] : sum.delivery_date}</strong>
                            }
                            <PickListTables picklistLoading={loading}
                                            summary={sum}/>
                            {
                                (summary.length - 1) !== i &&
                                <div className="page-break"/>
                            }
                        </React.Fragment>
                    )
                )
            }

            <style>{tableCss}</style>
        </div>
    );
});

export default PickListTablesWrapper;