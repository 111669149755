import React, {useState} from 'react';
import {formatDate, getDateAndTime, getDateFormat, getFormatDate, getFormatTime} from "../../../helpers/getDate";
import OrderDeliverySelect from "../OrderDeliverySelect/OrderDeliverySelect";
import OrderDate from "../OrderDate/OrderDate";
import {Button, Row} from "antd";
import useActions from "../../../hooks/useActions";
import moment from "moment";

const OrderShippingMethodsAndDate = ({order}) => {
    const [shippingDeliveryValue, setShippingDeliveryValue] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const dateFormat = getDateFormat()
    const reformatTime = order?.delivery_time_interval ? order?.delivery_time_interval.split('-') : ['08:00', '14:00']
    const [date, setDate] = useState(order?.delivery_date ? moment(getDateAndTime(order.delivery_date), dateFormat) : moment(new Date(), dateFormat))
    const [time, setTime] = useState([moment(reformatTime[0], 'hh:mm'), moment(reformatTime[1], 'hh:mm')]);
    const {changeOrderDelivery} = useActions();

    const updateOrderDelivery = () => {
        const delivery_time_interval = time.map(_time => getFormatTime(_time._d).slice(0, -3)).join('-');
        const data = {
            delivery_date: getFormatDate(date._d),
            delivery_time_interval,
            shipping_method_id: shippingDeliveryValue
        };

        changeOrderDelivery(order.id, data);
        setIsDisabled(true);
        location.reload(true);
    }

    // Checking conditions for showing OrderDeliverySelect
    const isGordon = order?.shipping_method_type?.toLowerCase() === 'gordon';
    const isOrderDeliveryMoreThanCurrentDate = new Date(getDateAndTime(order?.delivery_date)).getTime() > new Date().getTime();
    const isOrderDeliveryNotEqualCurrentDate = getDateAndTime(order?.delivery_date) !== formatDate(new Date());
    const isOrderStatusNotCompletedAndCancelled = order?.status !== 'completed' && order?.status !== 'cancelled';
    const isGordonAndDatesNotEqualAndDeliveryDateMoreThanCurrent = (isOrderStatusNotCompletedAndCancelled && isGordon && isOrderDeliveryNotEqualCurrentDate && isOrderDeliveryMoreThanCurrentDate);

    const isShowingOrderDeliverySelect = isGordon ?
        (isGordonAndDatesNotEqualAndDeliveryDateMoreThanCurrent &&
            isOrderDeliveryMoreThanCurrentDate &&
            isOrderStatusNotCompletedAndCancelled) :
        (isOrderDeliveryMoreThanCurrentDate && isOrderStatusNotCompletedAndCancelled);

    return (
        <Row align='middle' style={{marginBottom: '20px'}}>
            <span style={{display: 'block', marginRight: '5px'}}>Leveransdag:</span>
            {
                isShowingOrderDeliverySelect ?
                    <>
                        <OrderDeliverySelect order={order}
                                             shippingDeliveryValue={shippingDeliveryValue}
                                             setShippingDeliveryValue={setShippingDeliveryValue}
                                             setIsDisabled={setIsDisabled}/>
                        <OrderDate date={date}
                                   time={time}
                                   setDate={setDate}
                                   setTime={setTime}
                                   setIsDisabled={setIsDisabled} />
                        <Button type='primary'
                                disabled={isDisabled}
                                onClick={updateOrderDelivery}>
                            Ändra
                        </Button>
                    </> :
                    <span>{getDateAndTime(order.delivery_date)}</span>
            }
        </Row>
    );
};

export default OrderShippingMethodsAndDate;
