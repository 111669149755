import React from 'react';
import {getAvailableAmountForRefund, getRefundedAmountAlready} from "../../../helpers/helpers";
import {Divider} from "antd";

const OrderInfo = ({order, isEditing}) => {
    const orderItemsTaxes = order?.order_items?.map(order => order.total_tax).reduce((prev, tax) => +prev + tax, 0);
    const orderItemsSubTotal = order?.order_items?.map(order => order.total).reduce((prev, total) => +prev + total, 0);
    var formatter = new Intl.NumberFormat('sv-SE', {
          style: 'decimal',
          minimumFractionDigits: 2,

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
    return (
        <div className='order-info'>
            <div>
                <span>Totalt: </span>
                <span><b>{formatter.format(order.total)} {order.currency}</b></span>
            </div>
            {
                order?.shipping_total &&
                <div>
                    <span>Frakt: </span>
                    <span><b>  {formatter.format(order.shipping_total)}  {order.currency}</b></span>
                </div>
            }
            {
                orderItemsTaxes > 0 &&
                <div>
                    <span>Moms: </span>
                    <span><b>{formatter.format(orderItemsTaxes)} {order.currency}</b></span>
                </div>
            }
            {
                order?.coupons && order?.coupons.length > 0 &&
                order.coupons.map(coupon => (
                    <div key={coupon.code} className='coupon-wrapper'>
                        <span className='coupon-item'>
                            <span>Rabattkod:</span>
                            <b>{coupon.code}</b>
                        </span>
                        <span className='coupon-item'>
                            <span>Rabatt:</span>
                            <b>{formatter.format(coupon.discount + coupon.discount_tax)} {order.currency}</b>
                        </span>
                    </div>

                ))
            }

            <Divider/>

            {
                getRefundedAmountAlready(order) > 0 &&
                <div style={{
                    color: !isEditing ? '#a00' : 'inherit',
                    textAlign: 'right'
                }}> {isEditing ? 'Belopp återbetalt: ' : 'Återbetalt: '}
                    -{getRefundedAmountAlready(order)} {order.currency}</div>
            }
            {
                isEditing &&
                <div style={{textAlign: 'right'}}>
                    <span>Tillgängligt belopp för återbetalning: </span>
                    <span>{getAvailableAmountForRefund(order)} {order.currency}</span>
                </div>
            }
            <div>
                <span>Summa: </span>
                <span>{order.total - getRefundedAmountAlready(order)} {order.currency}</span>
            </div>
        </div>
    );
};

export default OrderInfo;
