import React, {useEffect, useState} from 'react';

import {useSelector} from "react-redux";

import {Form, Select, Switch, Input} from "antd";

import ShippingClassSelect from "@components/SitesFieldTable/ShippingClassSelect/ShippingClassSelect";
import ProductPublishSchedule from "@components/Form/ProductPublishSchedule/ProductPublishSchedule";

const SitesFieldTable = ({
                             item,
                             formName = 'external_products',
                             shouldAddTimePublish = false,
                             shouldAddClasses = true
                         }) => {
    const sites = useSelector(state => state?.sites?.sites?.objects);
    const [externalSites, setExternalSites] = useState([]);

    const mergedSites = (allSites, externalSites = []) => {
        if (allSites) {
            return allSites.map(site => {
                const {
                    consumer_key,
                    consumer_secret,
                    datetime_created,
                    datetime_last_updated,
                    last_woocommerce_order_sync,
                    last_woocommerce_attribute_sync,
                    last_woocommerce_category_sync,
                    name,
                    product_id,
                    category_id,
                    id,
                    url,
                    ...restProperties
                } = site

                if (name) {
                    site.site_name = name
                }

                site.active = false
                site.site_id = id

                if (shouldAddTimePublish) {
                    site.time_publish = false
                }

                if (shouldAddClasses) {
                    site.shipping_class_id = ''
                }

                const foundExist = externalSites?.find(externalItem => externalItem.site_id === site.site_id);

                if (foundExist) {
                    const {category_id, id, ...fountItemRestProperties} = foundExist;
                    foundExist.site_name = site.site_name
                    return fountItemRestProperties;
                }

                return restProperties;
            })
        }
    }

    useEffect(() => {
        if (sites.length > 0) {
            const specialItem = item && item[formName] && item[formName]?.map(site => {
                if (site?.shipping_class_id?.id && shouldAddClasses) {
                    site.shipping_class_id = site.shipping_class_id.id
                }

                return site;
            })

            setExternalSites(mergedSites(sites, (item && item[formName]) ? specialItem : []))
        }
    }, [sites]);


    return externalSites.length > 0 &&
        <table className='form-table'>
            <thead className='form-table__row'>
            <tr className='form-table__head'>
                <th className='form-table__head--th'>Försäljningställe</th>
                <th className='form-table__head--th'>Aktiv</th>
                {
                    shouldAddClasses &&
                    <th style={{width: '15%'}} className='form-table__head--th'>Fraktklasser</th>
                }

                {
                    shouldAddTimePublish &&
                    <>
                        <th className='form-table__head--th'>Aktivera tidspublicering</th>
                        <th className='form-table__head--th'>Publicera från</th>
                        <th className='form-table__head--th'>Avpublicera från</th>
                    </>
                }

            </tr>
            </thead>
            <tbody>
            <Form.List name={formName}
                       initialValue={mergedSites(sites, item && item[formName] ? item[formName] : [])}>
                {fields => (
                    fields.map((field, index) => {
                        const currentSiteId = mergedSites(sites, item && item[formName] ? item[formName] : [])[index].site_id;

                        return (
                            <tr key={field.key}>
                                <td className='form-table__col'>
                                    <Form.Item name={[index, 'site_name']}>
                                        <Input readOnly={true} bordered={false}/>
                                    </Form.Item>

                                    <Form.Item style={{display: 'none'}} name={[index, 'site_id']}>
                                        <Input readOnly={true} bordered={false}/>
                                    </Form.Item>
                                </td>

                                <td className='form-table__col'>
                                    <Form.Item name={[index, 'active']} valuePropName="checked">
                                        <Switch/>
                                    </Form.Item>
                                </td>

                                {
                                    shouldAddClasses &&
                                    <td className='form-table__col'>
                                        <ShippingClassSelect name={[index, 'shipping_class_id']}
                                                             site_id={currentSiteId}/>
                                    </td>
                                }


                                {
                                    shouldAddTimePublish &&
                                    <ProductPublishSchedule
                                        index={index}
                                        initialValues={mergedSites(sites, item && item[formName] ? item[formName] : [])[index]}/>

                                }
                            </tr>
                        )
                    })
                )
                }
            </Form.List>
            </tbody>
        </table>
};

export default SitesFieldTable;
