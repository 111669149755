import React, {useEffect, useState} from 'react';

import {Table, Form} from 'antd';

import {useParams} from "react-router-dom";

import dragula from "dragula";

import {useSelector} from "react-redux";

import useActions from "@hooks/useActions";

import {cleanPayload, getParamsFromObject, renderDateYMD} from "@helpers/helpers";
import {getUseSelectorFromType} from "@helpers/getUseSelectorFromType";


import "dragula/dist/dragula.css";
import './ants.scss';
import "antd/dist/antd.min.css";

const AntTable = ({
                      initialData = {},
                      rowKey = (record) => record.id,
                      rangeDate = null,
                      isPickList = false,
                      dataSource = [],
                      loading,
                      columns,
                      total,
                      type = 'products',
                      getItemsDynamicData = {},
                      pagination,
                      isPaginationDisabled = false,
                      setPagination = () => {
                      },
                      setFilters = () => {
                      },
                      setSorting = () => {
                      },
                      isSiteAndWarehousesFiltersEnabled = false,
                      isEditableRow = false,
                      siteAndWarehousesFilters = {},
                      clearSorting = () => {
                      },
                      customIsTableChange = null,
                      setCustomTableIsChange = () => {
                      },
                      ...props
                  }) => {
    const {
        getItems,
        reorder,
        setSelectedRows,
        setSelectedRowKeys,
        setBulkOperationsAvailable,
        getPicklists,
        setProductsSiteAndWarehousesFiltersEnabled
    } = useActions();

    const {id} = useParams();
    const {selectedRowKeys} = useSelector(state => state?.bulkOperations)
    const useSelectorType = isPickList ? 'picklist' : getUseSelectorFromType(type);
    const tableFiltersType = useSelectorType + 'TableFilters';
    const tableSortingType = useSelectorType + 'TableSorting';
    const items = useSelector(state => state[useSelectorType])
    const tableFilters = useSelector(state => state[useSelectorType][tableFiltersType])
    const tableSorting = useSelector(state => state[useSelectorType][tableSortingType])
    const [isTableChanged, setIsTableChanged] = customIsTableChange !== null ? [customIsTableChange, setCustomTableIsChange] : useState(false);
    const [form] = Form.useForm();

    let siteAndWarehouseFilters = {};

    const getIndexInParent = (el) => {
        return Array.from(el.parentNode.children).indexOf(el)
    };

    const handleReorder = (start, end, data) => {
        if (data && data.length > 0 && typeof start !== 'undefined' && start !== null && typeof end !== 'undefined' && end !== null) {
            const end__ = data[end];
            const start__ = data[start];

            reorder({
                type,
                id: start__.id,
                order_id: end__.order_id,
                params: {
                    page: pagination.current,
                    ...tableFilters,
                    ...siteAndWarehousesFilters
                },
            })
        }
    };

    if (type === 'products') {
        siteAndWarehouseFilters = cleanPayload(useSelector(state => state?.products?.siteAndWarehousesFilters))
    }

    useEffect(() => {
        if (isTableChanged || isSiteAndWarehousesFiltersEnabled) {
            if (isPickList) {
                getPicklists({
                    params: {
                        delivery_date_from: renderDateYMD(rangeDate[0]._d),
                        delivery_date_to: renderDateYMD(rangeDate[1]._d),
                        summary: 1,
                        ...{
                            ...(tableSorting && Object.keys(tableSorting).length > 0 && tableSorting?.order && {
                                order_by: tableSorting.field,
                                order_type: tableSorting.order.replace('end', '')
                            })
                        },
                    },
                    page: pagination.current
                })
            } else {
                getItems({
                        ...getItemsDynamicData,
                        params: {
                            ...{
                                ...(tableSorting && Object.keys(tableSorting).length > 0 && tableSorting?.order && {
                                    order_by: tableSorting.field,
                                    order_type: tableSorting.order.replace('end', '')
                                })
                            },
                            ...{...((tableFilters && Object.keys(tableFilters).length > 0 || siteAndWarehouseFilters) && {...tableFilters, ...siteAndWarehouseFilters})},
                            page: pagination.current ?? 1,
                            ...{...type === 'products' && {limit: pagination.pageSize ?? 25}}
                        },
                    }
                )
            }
            setIsTableChanged(false);
            setProductsSiteAndWarehousesFiltersEnabled(false);
        }
    }, [tableFilters, tableSorting, isTableChanged, setIsTableChanged, isSiteAndWarehousesFiltersEnabled]);

    useEffect(() => {
        if (!loading && tableSorting?.field === 'order_id' && tableSorting?.order) {
            if (window.drake) {
                window.drake.destroy();
            }
            const container = document.querySelector(".ant-table-tbody");
            let start;
            let end;

            const drake = dragula([container], {
                moves: (el) => {
                    start = getIndexInParent(el);
                    return true;
                },
                invalid: function (el, handle) {
                    let draggable = el;

                    if (el.nodeName.toLowerCase() === 'path') {
                        draggable = el.parentElement.parentElement;
                    }

                    if (el.nodeName.toLowerCase() === 'td' || el.nodeName.toLowerCase() === 'tr') {
                        draggable = el.querySelector('.draggable');
                    }

                    if (el.nodeName.toLowerCase() === 'svg') {
                        draggable = el.parentElement
                    }

                    if (!draggable?.classList.contains('draggable')) {
                        return true;
                    }
                    return false;
                }
            });
            window.drake = drake;
            drake.on("drop", (el) => {
                end = getIndexInParent(el);
                handleReorder(start, end, items[type].objects);
            });
        }
    }, [loading, tableSorting])

    const handleTableChange = (pagination, filters, sorting) => {
        const filteredValues = getParamsFromObject(filters);
        setFilters(filteredValues)
        setSorting(sorting)
        setPagination(pagination);
        setIsTableChanged(true);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
            setBulkOperationsAvailable(selectedRows.length !== 0)
        }
    }

    const returnRowSelection = () => {
        if ((type === 'products' && !id) ||
            type === 'stock_objects' ||
            type === 'orders' ||
            type === 'pick-lists' ||
            type === 'shipping_base_addresses') {
            return {
                type: 'checkbox',
                ...rowSelection,
            }
        }

        return false
    }

    return (
        <Form form={form} component={false}>
            {
                <Table
                    id={'table-' + type}
                    rowSelection={returnRowSelection()}
                    columns={columns}
                    bordered
                    rowClassName="editable-row"
                    rowKey={rowKey}
                    dataSource={dataSource.length > 0 ? dataSource : (initialData && initialData.objects && initialData.objects.length > 0 ? initialData.objects : [])}
                    pagination={isPaginationDisabled ? false : {
                        ...pagination,
                        ...{...type !== 'products' && {pageSize: 25}},
                        showSizeChanger: type === 'products',
                        locale: {
                            items_per_page: '/ sida'
                        },
                        total
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                    {...props}
                />
            }
        </Form>
    );
}

export default AntTable;
