import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import useActions from "../../../../hooks/useActions";
import {Button, Checkbox, Image, Radio, Row} from "antd";
import SearchFilter from "../../../SearchFilter/SearchFilter";
import {getFallbackImage} from "../../../../helpers/helpers";
import {CaretDownOutlined, CaretUpOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import "./ChooseImage.scss";
import useDebounce from "../../../../hooks/useDebounce";

const ChooseImage = ({formRef, setIsChanged}) => {
    const {getChosenImages, setFeaturedId, setChooseImagesNull, getImagesByIds} = useActions();
    const {isFeaturedImage, galleryImages, featuredId} = useSelector(state => state?.productImages)
    const {chooseImages, page, loading} = useSelector(state => state?.chooseImages)
    const order = ['asc', 'desc', ''];
    const [sort, setSort] = useState('');
    const [sortingNumber, setSortingNumber] = useState(0);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [sortType, setSortType] = useState('')
    const [value, setValue] = useState('');
    const [chosenValues, setChosenValues] = useState([]);
    const debounceValue = useDebounce(value, 300);

    useEffect(() => {
        getChosenImages({})
        return () => {
            setChooseImagesNull()
        }
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            getChosenImages({
                params: {
                    ...{
                        ...sort && {
                            order_by: sortType,
                            order_type: sort
                        }
                    },
                    search: debounceValue
                }
            })
        }
        setIsFirstRender(false)
    }, [sort, debounceValue])

    useEffect(() => {
        if (!isFirstRender && chosenValues.length > 0) {
            const allValues = formRef?.current?.getFieldsValue();

            if (allValues.hasOwnProperty('images') &&
                allValues['images'] &&
                Array.isArray(allValues['images']) &&
                allValues['images'].length > 0) {

                const images = allValues['images'];

                if (Array.isArray(images) && images.length > 0) {
                    const uniqChosenImages = [...new Set([...images, ...chosenValues])];

                    getImagesByIds({
                        params: {
                            ids: uniqChosenImages
                        }, isFeatured: false
                    })

                    formRef.current.setFieldsValue({
                        images: uniqChosenImages
                    })
                }
            } else {
                getImagesByIds({
                    params: {
                        ids: chosenValues
                    }, isFeatured: false
                })

                formRef.current.setFieldsValue({
                    images: chosenValues
                })
            }
        }
    }, [chosenValues]);

    const loadMoreImages = () => {
        getChosenImages({params: {page: page + 1}, loadMore: true})
    }

    const changeHandler = (event) => {
        const target = event.target;

        if (isFeaturedImage) {
            setFeaturedId(target.value)
            formRef.current.setFieldsValue({
                featured_image: target.value
            })
        } else {
            setChosenValues((prev) => {
                if (target.checked) {
                    return [...new Set([...prev, target.value])];
                } else {
                    return prev.filter(id => id !== target.value);
                }
            })
        }
        setIsChanged(true)
    }

    const onSort = (isAlphabetic = false) => {
        sortingNumber === 2 && setSortingNumber(-1)
        setSort(order[sortingNumber])
        setSortingNumber(prev => prev + 1);
        setSortType(isAlphabetic ? 'title' : 'datetime_created')
    }

    return (
        <>
            <Row className='filter-sorting'>
                <div className='filter-sorting__item'>
                    <span className='filter-sorting__title'>Sök:</span>
                    <SearchFilter type='images' searchPlaceholder='Sök bild' customValue={value}
                                  setCustomValue={setValue} debValue={debounceValue}/>
                </div>
                <div className='filter-sorting__item'>
                    <span className='filter-sorting__title'>Sortera a-ö:</span>
                    <div className='filter-sorting__date' onClick={() => onSort(true)}>
                        {
                            (sort === 'asc' && sortType === 'title') || (sort === '' && sortType === 'title') ?
                                <SortAscendingOutlined
                                    className={`filter-sorting__icon ${sort === 'asc' && sortType === 'title' ? 'active' : ''}`}/>
                                : (sortType === 'title' ? <SortDescendingOutlined
                                            className={`filter-sorting__icon ${sort === 'desc' && sortType === 'title' ? 'active' : ''}`}/> :
                                        <SortAscendingOutlined
                                            className={`filter-sorting__icon ${sort === 'asc' && sortType === 'title' ? 'active' : ''}`}/>
                                )
                        }
                    </div>

                </div>
                <div className='filter-sorting__item '>
                    <span className='filter-sorting__title'>Sortera enligt datum:</span>
                    <div className='filter-sorting__date' onClick={() => onSort(false)}>
                        <CaretUpOutlined
                            className={`filter-sorting__icon filter-sorting__icon--date ${sort === 'asc' && sortType === 'datetime_created' ? 'active' : ''}`}/>
                        <CaretDownOutlined
                            className={`filter-sorting__icon filter-sorting__icon--date ${sort === 'desc' && sortType === 'datetime_created' ? 'active' : ''}`}/>
                    </div>
                </div>
            </Row>
            <ul className='gallery-image__list' style={{display: 'block', marginBottom: '20px'}}>
                {
                    chooseImages.length > 0 &&
                    <>
                        {
                            isFeaturedImage ?
                                <Radio.Group defaultValue={featuredId} onChange={changeHandler} optionType="button"
                                             size='large'>
                                    {
                                        chooseImages.map((img, index) => (
                                            <Radio.Button onChange={(test) => {
                                                console.log('test', test)
                                            }} value={img.id} key={img.id + '_' + index}>
                                                <Image fallback={getFallbackImage()} width={80} height={80}
                                                       style={{objectFit: "contain"}} className='gallery-image__item'
                                                       src={img.url}/>
                                            </Radio.Button>
                                        ))
                                    }
                                </Radio.Group>
                                :
                                <Checkbox.Group defaultValue={galleryImages.map(img => img.id)}>
                                    {
                                        chooseImages.map((img, index) => (
                                            <Checkbox onChange={changeHandler} value={img.id}
                                                      key={img.id + '_' + index + 'gallery'}>
                                                <Image fallback={getFallbackImage()} width={80} height={80}
                                                       style={{objectFit: "contain"}} className='gallery-image__item'
                                                       src={img.url}/>
                                            </Checkbox>
                                        ))
                                    }
                                </Checkbox.Group>
                        }
                    </>
                }
            </ul>
            <Button loading={loading} onClick={loadMoreImages}>Ladda fler</Button>
        </>
    );
};

export default ChooseImage;
