import {getTextFromType} from "./helpers";

export const getActionTypes = (type) => {
    const itemsType = type.includes('-') ? type.replaceAll('-', '_') : type;
    const itemType = getTextFromType({
        string: itemsType,
        isRemoveSymbols: false,
        isRemoveLastAndAllToUppercase: true});
    const uppercaseType = itemsType.toUpperCase();

    return {
        type: itemsType,
        setItemActionType: `SET_${itemType}`,
        itemDeleteActionType: `DELETE_${itemType}`,
        updateActionType: `UPDATE_${itemType}`,
        addItemActionType: `ADD_${itemType}`,
        setPaginationActionType: `SET_${uppercaseType}_PAGINATION`,
        setLoadingActionType: `SET_${uppercaseType}_LOADING`,
        setItemsActionType: `SET_${uppercaseType}`
    }
}
