import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Switch} from "antd";

import {useSelector} from "react-redux";
import {
    SET_STOCK_OBJECTS,
    UPDATE_STOCK_OBJECT
} from "../../../../redux-store/reducers/shopReducers/stockObjectsReducer";
import useActions from "../../../../hooks/useActions";

import {getActionTypes} from "../../../../helpers/getActionTypes";

import StockForm from "../StockForm/StockForm";
import RowSpinner from "../../../RowSpinner/RowSpinner";

import {getArrayDifference} from "../../../../helpers/getArrayDifference";


const compareWarehouseIds = (a, b) => {
    if (a?.warehouse?.id < b?.warehouse?.id) return -1;
    if (a?.warehouse?.id > b?.warehouse?.id) return 1;
    return 0;
}
const StockTable = ({product_id, layout, ...props}) => {
    const {getItems, updateItem, setItemsNull, addItem} = useActions();

    const warehouses = useSelector(state => state?.warehouses?.warehouses?.objects);
    const stockObjects = useSelector(state => state?.stockObjects?.stockObjects?.objects);

    const [stockFormItems, setStockFormItems] = useState({});
    const [tableStockObjects, setTableStockObjects] = useState([]);
    const [isChanged, setIsChanged] = useState(false);


    useEffect(() => {
        setItemsNull(SET_STOCK_OBJECTS)
        getItems({params: {product_id}, ...getActionTypes('stock_objects')})
    }, []);

    useEffect(() => {
        if (!stockObjects || !warehouses || stockObjects.length === 0 || warehouses.length === 0) return;

        const sku = stockObjects[0].sku;
        const isSameStock = (a, b) => a.id === b.warehouse?.id;

        /**
         * Hidden warehouse "Täby" and "Garnisonen"
         * @wrike https://www.wrike.com/open.htm?id=1304107934
         */
        const filteredStockObjects = stockObjects.filter(stockObject => {
            if (!stockObject.hasOwnProperty('warehouse')) {
                return stockObject;
            }
            return stockObject.warehouse.name !== 'Täby' && stockObject.warehouse.name !== 'Garnisonen';
        })

        const filteredWarehouses = getArrayDifference(warehouses, filteredStockObjects, isSameStock)
            .map(warehouse => ({
                product_id,
                sku,
                warehouse,
                disposable_quantity: 0,
                create: true
            }));

        setTableStockObjects([...filteredWarehouses, ...filteredStockObjects].sort(compareWarehouseIds))

    }, [stockObjects, warehouses])

    const finishHandler = () => {
        const stockObjectKeys = Object.keys(stockFormItems);

        if (stockObjectKeys.length === 0) return;

        for (const key of stockObjectKeys) {
            const stockObjectItem = stockFormItems[key];

            if (!stockObjectItem) return;

            if (stockObjectItem.create) {
                const {id, create, warehouse, ...values} = stockObjectItem;
                void addItem({
                    values: {...values, warehouse_id: warehouse.id},
                    ...getActionTypes('stock_objects'),
                    isMessage: false
                })
            } else {
                const {id, ...values} = stockObjectItem;
                void updateItem({
                    values,
                    id,
                    updateActionType: UPDATE_STOCK_OBJECT,
                    ...getActionTypes('stock_objects'),
                    contentText: 'Lagerobjekt har uppdaterats'
                });
            }
        }

        setIsChanged(false);
    }

    if (!stockObjects || stockObjects?.length === 0 || !tableStockObjects || tableStockObjects.length === 0) {
        return <RowSpinner/>
    }

    return (
        <>
            <div className='form-table stock-table'>
                <div className='form-table__row form-table__head'>
                    <div className='form-table__col'>
                        <span className='form-table__head--th'>Lagerställe</span>
                    </div>
                    <div className='form-table__col'>
                        <span className='form-table__head--th'>Disponibelt antal i lager</span>
                    </div>
                    <div className='form-table__col'>
                        <span className='form-table__head--th'>Aktiv</span>
                    </div>
                </div>

                <div>
                    {
                        tableStockObjects.map((stockFormItem, index) => (
                            <React.Fragment key={`${stockFormItem.id}_${index}`}>
                                <StockForm stockFormItem={stockFormItem}
                                           setStockFormItems={setStockFormItems}
                                           setIsChanged={setIsChanged}
                                           layout={layout}
                                           index={index}
                                           {...props}
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
            </div>
            <Button onClick={finishHandler}
                    style={{marginRight: '20px'}}
                    type="primary"
                    disabled={!isChanged}>
                Spara
            </Button>
        </>
    );
};

export default StockTable;
