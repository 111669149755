import React, {useEffect, useState} from 'react';

import {Outlet, useLocation, useParams} from "react-router-dom";

import {useSelector} from "react-redux";

import {Button, Row} from "antd";

import {dashboardColumns} from "@antTable/dashboardColumns";

import useActions from "@hooks/useActions";

import AntTable from "@components/AntTable/AntTable";
import BulkOperations from "@components/BulkOperations/BulkOperations";

import {getTextFromType} from "@helpers/helpers";
import {getActionTypes} from "@helpers/getActionTypes";
import {getColumns} from "@helpers/getColumns";
import {getUseSelectorFromType} from "@helpers/getUseSelectorFromType";

const Orders = ({type = 'orders', title = 'Ordrar'}) => {
    const {getItems, removeItem, setOrdersSorting, setOrdersFiltering, setOrderPagination} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const dashboardBulkFormElementsType = useSelectorType + 'BulkFormElements';
    const {id} = useParams();
    const location = useLocation();
    const {orders, loading, pagination, ordersTableSorting, ordersTableFilters} = useSelector(state => state?.orders);
    const [isTableChanged, setIsTableChanged] = useState(false);

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                ...getActionTypes(type),
                params: {
                    ...(ordersTableSorting && Object.keys(ordersTableSorting).length > 0 && ordersTableSorting?.order && {
                        order_by: ordersTableSorting.field,
                        order_type: ordersTableSorting.order.replace('end', '')
                    }),
                    ...{...(ordersTableFilters && Object.keys(ordersTableFilters).length > 0 && ordersTableFilters)},
                }
            });
        }
    }, [location]);

    const clearFilters = () => {
        setOrdersFiltering({})
        setIsTableChanged(true)
    }

    let columns = getColumns({
        type,
        isRemovable: false,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    return (
        id ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                <Row className='filter-block' justify='space-between' align='middle'>
                    <Row>
                        <BulkOperations type={type} dashboardBulkFormElementsType={dashboardBulkFormElementsType} actionsTypes={getActionTypes(type)} itemType='orders'/>
                        {
                             Object.keys(ordersTableFilters).length > 0 &&
                            <Button type='danger' ghost={true} onClick={clearFilters}>Rensa filter</Button>
                        }
                    </Row>
                </Row>

                <AntTable initialData={orders} total={orders?.total} loading={loading}
                          pagination={pagination} columns={columns} type='orders'
                          setSorting={setOrdersSorting}
                          setFilters={setOrdersFiltering}
                          setPagination={setOrderPagination}
                          setCustomTableIsChange={setIsTableChanged}
                          customIsTableChange={isTableChanged}
                          getItemsDynamicData={getActionTypes(type)}
                />
            </>
    );
};

export default Orders;
