import {
    SET_SHIPPING_METHODS_PAGINATION,
    SET_SHIPPING_METHODS_TABLE_FILTERS,
    SET_SHIPPING_METHODS_TABLE_SORTING
} from "../../../reducers/shopReducers/shippingMethodsReducer";

export const shippingMethodsActions = {
    setShippingMethodsFilters: (payload) => (
        {
            type: SET_SHIPPING_METHODS_TABLE_FILTERS,
            payload
        }
    ),
    setShippingMethodsSorting: (payload) => (
        {
            type: SET_SHIPPING_METHODS_TABLE_SORTING,
            payload
        }
    ),
    setShippingMethodsPagination: (payload) => (
        {
            type: SET_SHIPPING_METHODS_PAGINATION,
            payload
        }
    )
}
