import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Row, Select, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../../../hooks/useActions";
import {getActionTypes} from "../../../../helpers/getActionTypes";
import AvailabilityRulesForm from "./AvailabilityRulesForm/AvailabilityRulesForm";
import CustomSelect from "../../CustomSelect/CustomSelect";
import {formatDate} from "../../../../helpers/getDate";
import ShippingDeliveryType from "../../ShippingDeliveryType/ShippingDeliveryType";

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const shippingMethodEnabledOptions = {
    name: 'enabled',
    required: true,
    label: 'Aktiv',
    type: 'select',
    options: [
        {
            value: 'Aktiv',
            key: true
        },
        {
            value: 'Inaktiv',
            key: false
        }
    ]
}

const shippingTypeOptions = {
    name: 'shipping_type',
    required: true,
    label: 'Leveranstyp',
    type: 'select',
    options: [
        {
            value: 'Hämta i butik',
            key: 'pickup'
        },
        {
            value: 'Hemleverans',
            key: 'home'
        }
    ]
}

const EditShippingMethodForm = ({id}) => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const {loading, shippingMethod} = useSelector(state => state?.shippingMethods)
    const [isShippingTypeHome, setIsShippingTypeHome] = useState(false);
    const navigate = useNavigate();
    const formRef = createRef();

    useEffect(() => {
        if ( shippingMethod ) {
            setIsShippingTypeHome(shippingMethod.shipping_type === 'home')
        }
    }, [shippingMethod])



    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const timeToTwoDigits = (num) => {
        return String(num).padStart(2, '0');
    }

    const finishHandler = (values) => {
        const rules = Object.getOwnPropertyNames(values).filter(name => name.includes('rules_'))

        if (rules.length > 0) {
            const rulesValues = [];
            rules.forEach(rule => {
                if (values[rule]) {
                    rulesValues.push(...values[rule])
                    delete values[rule]
                }
            })

            values.rules = rulesValues.map(item => {
                const {time_ranges, time_ranges_2, date_2, ...rest} = item;
                const date = date_2 ? formatDate(date_2._d) : formatDate(new Date());

                return {
                    ...rest, date, time_ranges: time_ranges_2.map(time => {
                        const dateTo = time.time_to._d, dateFrom = time.time_from._d;

                        return {
                            time_to: timeToTwoDigits(dateTo.getHours()) + ':' + timeToTwoDigits(dateTo.getMinutes()),
                            time_from: timeToTwoDigits(dateFrom.getHours()) + ':' + timeToTwoDigits(dateFrom.getMinutes()),
                        }
                    })
                };
            })
        }

        if (shippingMethod?.rules?.length === 0 && values?.rules?.length === 0 || values?.rules?.length === 0) {
            message.warning('Det går ej att spara en fraktmetod utan regler')
            setIsChanged(false);
            return;
        }

        updateItem({
            values,
            id,
            ...getActionTypes('shipping_methods'),
            contentText: `Fraktmetod har uppdaterats`
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/shipping-methods');
    };


    if (Object.keys(shippingMethod).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    const initialValues = {
        site_id: shippingMethod?.site_id,
        warehouse_id: shippingMethod?.warehouse.id,
        description: shippingMethod?.description?.replace(/<\/?[^>]+(>|$)/g, ""),
        shipping_type: shippingMethod?.shipping_type,
        price: shippingMethod?.price,
        free_shipping_threshold: +shippingMethod?.free_shipping_threshold,
        postcodes: shippingMethod?.postcodes,
        name: shippingMethod?.name,
        pickup_address: shippingMethod?.pickup_address,
        woocommerce_id: shippingMethod?.woocommerce_id,
        enabled: shippingMethod?.enabled ?? false,
        home_delivery_type: shippingMethod?.home_delivery_type
    }

    return (
        <>
            <Form name='edit-shipping-method'
                  ref={formRef}
                  {...layout}
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}
                  initialValues={initialValues}
                  validateMessages={validateMessages}
            >
                <Form.Item name='name' label='Namn' rules={[{
                    required: true
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item name='description' label='Beskrivning'>
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item name='shipping_type' label='Leveranstyp'>
                    <Select mode='single' onChange={value => {
                        setIsShippingTypeHome(value === 'home')
                    }}>
                        {
                            shippingTypeOptions.options.map(option => (
                                <Select.Option key={option.key} value={option.key}>
                                    {option.value}
                                </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <ShippingDeliveryType isHome={isShippingTypeHome}/>
                <Form.Item name='pickup_address' label='Upphämtningsadress'>
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item name='price' label='Pris' rules={[{
                    required: true
                }]}>
                    <InputNumber/>
                </Form.Item>
                <Form.Item name='warehouse_id' label='Lagerställe'>
                    <CustomSelect formItem={{isFormItem: true}}
                                  name='warehouse_id'
                                  modeType='single'
                                  type='warehouses'/>
                </Form.Item>
                <Form.Item name='woocommerce_id' label='WooCommerce ID'>
                    <InputNumber/>
                </Form.Item>
                <Form.Item name='free_shipping_threshold' label='Fri frakt, gräns'>
                    <InputNumber/>
                </Form.Item>
                <Form.Item name='postcodes' label='Postnummer'>
                    <Input.TextArea placeholder={shippingMethod?.settings?.postcodes?.placeholder}/>
                </Form.Item>
                <Form.Item name='enabled' label='Aktiv'>
                    <Select mode='single'>
                        {
                            shippingMethodEnabledOptions.options.map(option => (
                                <Select.Option key={option.key} value={option.key}>
                                    {option.value}
                                </Select.Option>
                                )
                            )
                        }
                    </Select>
                </Form.Item>
                <CustomSelect modeType='single' type='sites' formItem={{
                    isFormItem: true,
                    label: 'Site',
                    name: 'site_id',
                    layout: {
                        labelCol: {
                            span: 4,
                        },
                        wrapperCol: {
                            span: 18,
                        }
                    }
                }}
                />
                <AvailabilityRulesForm shippingMethod={shippingMethod}/>
                <Form.Item wrapperCol={{...layout.wrapperCol}}>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={finishHandler}>
                                    Spara
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    )
};

export default EditShippingMethodForm;
