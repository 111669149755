import React, {useEffect} from 'react';
import {Collapse, Empty} from "antd";
import OrderNotesForm from "../OrderNotesForm/OrderNotesForm";
import {getDateAndTime} from "../../../../helpers/getDate";
import {useParams} from "react-router-dom";
import useActions from "../../../../hooks/useActions";
import {useSelector} from "react-redux";

const OrderNotesList = () => {
    const {id} = useParams();
    const {getComments} = useActions();
    const {orderNotes} = useSelector(state => state?.orders)

    useEffect(() => {
        getComments(id)
    }, []);

    return (
        <Collapse className='attribute-select__collapse' defaultActiveKey={['collapse_order_notes']}
                  style={{marginTop: '0'}}
                  expandIconPosition='end' accordion>
            <Collapse.Panel forceRender={true} style={{width: '100%'}} header='Orderkommentarer' key='collapse_order_notes'>
                <div className='order-comments'>
                    <ul className='order-comments__list'>
                        {
                            orderNotes.notes.length > 0 ?
                                orderNotes.notes.reverse().map(note => {
                                return (
                                    <li key={note.datetime_created} className='order-comments__item'>
                                        <span className='order-comments__note'>{note.comment}</span>
                                        <span className='order-comments__date'><span className='order-comments__date--text'>{getDateAndTime(note.datetime_created)}</span></span>
                                    </li>
                                )
                            }) :
                            <Empty description='Det finns inga orderkommentarer' image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        }
                    </ul>
                    <OrderNotesForm id={id} notesLoading={orderNotes.notesLoading}/>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};

export default OrderNotesList;
