import {
    SET_IMAGES_TABLE_FILTERS,
} from "../../../reducers/shopReducers/imagesReducer";

export const imagesActions = {
    setImagesFilters: (payload) => (
        {
            type: SET_IMAGES_TABLE_FILTERS,
            payload
        }
    )
}
