import React, {useEffect} from 'react';
import {getColumns} from "../../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../../ant-table/dashboardColumns";
import {Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import AntTable from "../../../components/AntTable/AntTable";
import {getTextFromType} from "../../../helpers/helpers";
import {getActionTypes} from "../../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../../helpers/getUseSelectorFromType";
import EditableRow from "../../../components/Form/Editable/EditableRow/EditableRow";
import EditableCell from "../../../components/Form/Editable/EditableCell/EditableCell";
import BulkOperations from "../../../components/BulkOperations/BulkOperations";

const StockObjects = ({type = 'stock_objects', title = 'Attribut'}) => {
    const {getItems, removeItem, setStockObjectsFilters, updateItem, setStockObjectsSorting, setStockObjectsPagination} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const dashboardBulkFormElementsType = useSelectorType + 'BulkFormElements';
    const {id} = useParams();
    const location = useLocation();
    const items = useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]);
    const {loading, pagination} = useSelector(state => state[useSelectorType] && state[useSelectorType]);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const {
        stockObjectsTableFilters,
        stockObjectsTablePagination,
        stockObjectsTableSorting} = useSelector(state => state?.stockObjects)

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                params: {
                    ...{...(stockObjectsTableFilters && Object.keys(stockObjectsTableFilters).length > 0 && stockObjectsTableFilters)},
                    ...(stockObjectsTableSorting && Object.keys(stockObjectsTableSorting).length > 0 && stockObjectsTableSorting?.order && {
                        order_by: stockObjectsTableSorting.field,
                        order_type: stockObjectsTableSorting.order.replace('end', '')
                    })
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                filters: stockObjectsTableFilters,
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    const handleSave = (values) => {
        updateItem({
            values,
            id: values.id,
            shouldUpdateItems: true,
            currentPage: pagination.current,
            ...getActionTypes('stock_objects'),
            contentText: `Lagerobjekt med Id ${values.id} har nu uppdaterats`
        })
    }

    /**
     * For columns editing in table view
     */

    columns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                title: col.title,
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                options: col.options ?? [],
                inputType: col.type === 'number' ? 'number' :  (col.type === 'select' ? 'select' : 'text'),
                handleSave,
            }),
        };
    });

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row className='filter-block' justify='space-between' align='middle'>
                            <Row>
                                <BulkOperations type={type} dashboardBulkFormElementsType={dashboardBulkFormElementsType} actionsTypes={getActionTypes(type)} itemType='stockObjects'/>
                            </Row>
                            <Row>
                                <Link to='new'>
                                    <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                                </Link>
                            </Row>
                        </Row>

                        <AntTable initialData={items} total={items?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setStockObjectsFilters}
                                  setSorting={setStockObjectsSorting}
                                  setPagination={setStockObjectsPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                                  components={components}
                        />
                    </>
                }
            </>
    );
};

export default StockObjects;
