import {
    SET_UPSELL_OBJECTS_PAGINATION,
    SET_UPSELL_OBJECTS_TABLE_FILTERS,
    SET_UPSELL_OBJECTS_TABLE_SORTING
} from "../../../reducers/shopReducers/upsellObjectsReducer";

export const upsellObjectsActions = {
    setUpsellObjectsFilters: (payload) => (
        {
            type: SET_UPSELL_OBJECTS_TABLE_FILTERS,
            payload
        }
    ),
    setUpsellObjectsSorting: (payload) => (
        {
            type: SET_UPSELL_OBJECTS_TABLE_SORTING,
            payload
        }
    ),
    setUpsellObjectsPagination: (payload) => (
        {
            type: SET_UPSELL_OBJECTS_PAGINATION,
            payload
        }
    )
}
