import React from 'react';
import {Form, InputNumber} from "antd";

const HiddenIdsFormItems = ({fieldIndex, shippingClassId, shippingMethodId}) => {
    return (
        <>
            <Form.Item initialValue={shippingClassId} style={{display: 'none'}} name={[fieldIndex, 'shipping_class_id']}>
                <InputNumber/>
            </Form.Item>
            <Form.Item initialValue={+shippingMethodId} style={{display: 'none'}} name={[fieldIndex, 'shipping_method_id']}>
                <InputNumber/>
            </Form.Item>
        </>
    );
};

export default HiddenIdsFormItems;
