import React, {useState} from 'react';
import {Button, Form, Input, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import useActions from "../../../../hooks/useActions";
import {useParams} from "react-router-dom";

const OrderNotesForm = ({notesLoading}) => {
    const {id} = useParams()
    const [disabled, setDisabled] = useState(true);
    const {addOrderComments} = useActions();
    const [form] = Form.useForm();

    const onOrderNoteFinish = (value) => {
        addOrderComments({
            id,
            contentText: `Meddelande ${value.comment} tillagt`,
            values: {
                order_id: +id,
                comment: value.comment
            }
        })
        form.resetFields();
    }

    return (
        <Form form={form} name='order-note' onFinish={onOrderNoteFinish} onValuesChange={() => setDisabled(false)}>
            <label style={{marginBottom: '10px', display: 'inline-block'}} htmlFor='order_comment'>Lägg till orderkommentar
                <Tooltip title='Lägg till ett meddelande som referens, eller lägg till en kundkommentar (kunden kommer att meddelas om detta).'>
                    <InfoCircleOutlined style={{marginLeft: '10px'}}/>
                </Tooltip>
            </label>
            <Form.Item name='comment' style={{marginBottom: '15px'}}>
                <Input.TextArea id='order_comment'/>
            </Form.Item>
            <Form.Item>
                <Button loading={notesLoading} disabled={disabled} htmlType='submit'>Lägg till orderkommentar</Button>
            </Form.Item>
        </Form>
    );
};

export default OrderNotesForm;
