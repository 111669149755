import React from 'react';
import Dashboard from "../pages/Dashboard/Dashboard";
import {Route, Routes} from "react-router-dom";
import Items from "../pages/Dashboard/Items/Items";
import ItemAdd from "../pages/Dashboard/Items/ItemAdd/ItemAdd";
import ItemEdit from "../pages/Dashboard/Items/ItemEdit/ItemEdit";
import NotFound from "../pages/Dashboard/NotFound/NotFound";
import LostPassword from "../pages/LostPassword/LostPassword";
import {paths} from "./paths";

const ApplicationRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Dashboard/>}>
                {
                    paths.map(path => (
                        <Route key={path.type} path={path.type} element={<Items type={path.type} Page={path.page} title={path.title}/> }>
                            <Route path=':id' element={<ItemEdit type={path.type} EditForm={path.editForm}/>}/>
                            <Route path='new' element={<ItemAdd type={path.type}/>}/>
                        </Route>
                    ))
                }
                <Route path='*' element={<NotFound/>}/>
            </Route>
            <Route path='/lost-password' element={<LostPassword/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    );
};

export default ApplicationRoutes;
