import React from 'react';
import {Upload, Modal} from "antd";
import {useSelector} from "react-redux";
import useActions from "../../../hooks/useActions";
import {getBase64Image} from "../../../helpers/helpers";

const ImageUploader = ({isMultiple = true, isProduct = false}) => {
    const {
        setImageUploaderFileList,
        setImageUploaderButtonDisabled,
        setImageUploaderPreviewVisible,
        setImageUploaderPreviewImage,
        setImageUploaderPreviewTitle
    } = useActions()
    const {fileList, previewVisible, previewImage, previewTitle} = useSelector(state => state?.imagesUploader)

    const onImageChange = ({fileList}) => {
        setImageUploaderFileList([...fileList])
        setImageUploaderButtonDisabled(false)
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64Image(file.originFileObj);
        }

        setImageUploaderPreviewVisible(true)
        setImageUploaderPreviewImage(file.url || file.preview)
        setImageUploaderPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    };

    return (
        <>
            <Upload {...(!isMultiple && isProduct && {maxCount: 1})}
                    multiple={isMultiple}
                    onPreview={handlePreview}
                    beforeUpload={() => {return false}}
                    onChange={onImageChange}
                    fileList={fileList}
                    listType="picture-card">
                {isMultiple ? 'Välj bilder' : 'Välj bild'}
            </Upload>
            <Modal
                open={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => setImageUploaderPreviewVisible(false)}
            >
                <img alt="preview" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </>
    );
};

export default ImageUploader;
