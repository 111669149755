const initialState = {
    shippingMethods: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingMethod: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shippingMethodsTableFilters: {},
    shippingMethodsTableSorting: {},
    loading: false,
}

export const SET_SHIPPING_METHODS = 'SET_SHIPPING_METHODS';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_SHIPPING_METHODS_LOADING = 'SET_SHIPPING_METHODS_LOADING';
export const SET_SHIPPING_METHODS_PAGINATION = 'SET_SHIPPING_METHODS_PAGINATION';
export const UPDATE_SHIPPING_METHOD = 'UPDATE_SHIPPING_METHOD';
export const SET_SHIPPING_METHODS_TABLE_FILTERS = 'SET_SHIPPING_METHODS_TABLE_FILTERS';
export const SET_SHIPPING_METHODS_TABLE_SORTING = 'SET_SHIPPING_METHODS_TABLE_SORTING';

const shippingMethodsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_METHODS_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_METHODS_PAGINATION:
            return {
                ...state, pagination: {
                    ...state.pagination,
                    ...action.payload
                }
            }
        case SET_SHIPPING_METHODS:
            return {...state, shippingMethods: action.payload, loading: false};
        case SET_SHIPPING_METHOD:
            return {...state, shippingMethod: {...action.payload}, loading: false};
        case UPDATE_SHIPPING_METHOD:
            return {...state, shippingMethod: {...action.payload}, loading: false};
        case SET_SHIPPING_METHODS_TABLE_FILTERS:
            return {...state, shippingMethodsTableFilters: action.payload};
        case SET_SHIPPING_METHODS_TABLE_SORTING:
            return {...state, shippingMethodsTableSorting: action.payload};
        default:
            return state;
    }
}

export default shippingMethodsReducer
