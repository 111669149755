import {
    SET_UPLOAD_IMAGE_DISABLED,
    SET_UPLOAD_IMAGE_FILE_LIST,
    SET_UPLOAD_IMAGE_POPUP_VISIBLE,
    SET_UPLOAD_IMAGE_PREVIEW_IMAGE,
    SET_UPLOAD_IMAGE_PREVIEW_TITLE,
    SET_UPLOAD_IMAGE_PREVIEW_VISIBLE
} from "../../reducers/imageUploaderReducer/imageUploaderReducer";

export const imageUploaderActions = {
    setImageUploaderFileList: (payload) => {
        return {
            type: SET_UPLOAD_IMAGE_FILE_LIST,
            payload
        }
    },
    setImageUploaderPopupVisible: (payload) => {
        return {
            type: SET_UPLOAD_IMAGE_POPUP_VISIBLE,
            payload
        }
    },
    setImageUploaderPreviewVisible: (payload) => {
        return {
            type: SET_UPLOAD_IMAGE_PREVIEW_VISIBLE,
            payload
        }
    },
    setImageUploaderPreviewImage: (payload) => {
        return {
            type: SET_UPLOAD_IMAGE_PREVIEW_IMAGE,
            payload
        }
    },
    setImageUploaderPreviewTitle: (payload) => {
        return {
            type: SET_UPLOAD_IMAGE_PREVIEW_TITLE,
            payload
        }
    },
    setImageUploaderButtonDisabled: (payload) => {
        return {
            type: SET_UPLOAD_IMAGE_DISABLED,
            payload
        }
    }
}
