import {SET_LOGOUT, SET_TOKEN_EXPIRE, SET_USER} from "../../reducers/loginReducer/loginReducer";
import {SET_AUTH} from "../../reducers/authReducer/authReducer";
import {message} from "antd";

export const logoutActionsCreator = {
 logout: (payload, isSessionExpired = false) => (dispatch) => {
     localStorage.removeItem('user');
     localStorage.removeItem('token');
     localStorage.removeItem('tokenExpire');
     dispatch({type: SET_AUTH, payload: payload})
     dispatch({type: SET_USER, payload: null})
     dispatch({type: SET_TOKEN_EXPIRE, payload: 0})

     if (isSessionExpired) {
         message.warning('Sessionen har redan avslutats')
     } else {
         message.success('Du har loggats ut');
     }

     return {
         type: SET_LOGOUT,
         payload
     }
 }
}
