import React, {createRef, useState} from 'react';
import useActions from "../../../hooks/useActions";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, Row, Spin} from "antd";
import getComponentByType from "../../../helpers/getComponentByType";
import {getActionTypes} from "../../../helpers/getActionTypes";

const validateMessages = {
    required: '${label} är obligatorisk!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const EditShippingBaseAddressForm = () => {
    const {updateItem} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const loading = useSelector(state => state?.shippingBaseAddresses?.loading)
    const shippingBaseAddress = useSelector(state => state?.shippingBaseAddresses?.shippingBaseAddress)
    const navigate = useNavigate();
    const formRef = createRef();
    const {id} = useParams()

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('shipping_base_addresses'),
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/shipping-base-addresses');
    };

    if (Object.keys(shippingBaseAddress).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    const initialValues = {
        name: shippingBaseAddress?.name,
        address: shippingBaseAddress?.address,
        city: shippingBaseAddress?.city,
        country: shippingBaseAddress?.country,
        email: shippingBaseAddress?.email,
        phone: shippingBaseAddress?.phone
    }

    const formElements = [
        {
            name: 'name',
            required: true,
            label: "Namn",
        },
        {
            name: 'address',
            required: true,
            label: "Adress",
        },
        {
            name: 'city',
            required: true,
            label: "Ort",
        },
        {
            name: 'country',
            required: true,
            label: "Land",
        },
        {
            name: 'email',
            required: true,
            label: "E-post",
            type: 'email',
            rules: {
                type: 'email',
                message: 'Detta är inte en giltig e-postadress!',
            }
        },
        {
            name: 'phone',
            required: true,
            label: "Telefon",
            type: 'phone'
        }
    ]

    return (
        <Form name='edit-stock-object'
              ref={formRef}
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              initialValues={initialValues}
              validateMessages={validateMessages}
        >
            {
                formElements.map(formElement => {
                    return (
                        <Form.Item {...layout} key={formElement.name} name={formElement.name} label={formElement.label} rules={[
                            {
                                required: formElement.required,
                                ...formElement.rules
                            },
                        ]}>
                            {
                                getComponentByType({value: formElement, formRef, setIsChanged})
                            }
                        </Form.Item>
                    )
                })
            }
            <Form.Item {...layout}>
                <Row>
                    {
                        isChanged ?
                            <Button style={{marginRight: '20px'}} type="primary" loading={loading} htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    )
};

export default EditShippingBaseAddressForm;
