import React, {useEffect} from 'react';
import {Image, Popconfirm} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import useActions from "../../../../hooks/useActions";
import {useSelector} from "react-redux";

const ProductImage = ({
                          fallback,
                          url,
                          isOne = false,
                          formRef,
                          setIsChanged,
                          width = '200px',
                          height = '200px',
                          id,

                      }) => {
    const {removeFeaturedImage, removeGalleryImage} = useActions();
    const {galleryImages} = useSelector(state => state?.productImages)

    const removeHandler = () => {
        if (!formRef.current) return;

        if (isOne) {
            removeFeaturedImage('');
            formRef.current.setFieldsValue({
                featured_image: ''
            })
        } else {
            removeGalleryImage(id)
            formRef.current.setFieldsValue({
                images: [...galleryImages.filter(img => img.id !== id).map(img => img.id)]
            })

        }
        setIsChanged(true)
    }

    return (
        <div style={{position: 'relative', width: 'max-content'}}>
            <Image
                className='product-image__gallery'
                width={width}
                style={{objectFit: 'contain', border: '1px solid #f0f0f0', borderRadius: '5px', padding: '5px'}}
                height={height}
                src={url}
                fallback={fallback}
            />
            <div className={['product-image__actions', !isOne && 'is-gallery'].join(' ')}>
                {
                    url &&
                    <Popconfirm title='Är du säker på att du vill ta bort denna bild?' onConfirm={removeHandler}>
                        <DeleteOutlined className='delete'/>
                    </Popconfirm>
                }
            </div>
        </div>
    );
};

export default ProductImage;
