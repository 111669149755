const initialState = {
    categories: {
        objects: [],
        total: 0,
        page: 1,
    },
    category: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    categoriesTableFilters: {},
    categoriesTableSorting: {},
    loading: false,
}

export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGORY_NULL = 'SET_CATEGORY_NULL';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING';
export const SET_CATEGORIES_PAGINATION = 'SET_CATEGORIES_PAGINATION';
export const SET_CATEGORIES_TABLE_FILTERS = 'SET_CATEGORIES_TABLE_FILTERS';
export const SET_CATEGORIES_TABLE_SORTING = 'SET_CATEGORIES_TABLE_SORTING';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES_LOADING:
            return {...state, loading: action.payload};
        case SET_CATEGORIES_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_CATEGORIES:
            return {...state, categories: action.payload, loading: false};
        case SET_CATEGORY:
            return {...state, category: {...action.payload}, loading: false};
        case SET_CATEGORY_NULL:
            return {...state, category: action.payload};
        case UPDATE_CATEGORY:
            return {...state, category: {...action.payload}, loading: false};
        case ADD_CATEGORY:
            return {...state, categories: action.payload, loading: false};
        case DELETE_CATEGORY:
            return {...state, loading: false};
        case SET_CATEGORIES_TABLE_FILTERS:
            return {...state, categoriesTableFilters: action.payload};
        case SET_CATEGORIES_TABLE_SORTING:
            return {...state, categoriesTableSorting: action.payload};
        default:
            return state;
    }
}

export default categoriesReducer;
