import React, {useEffect, useState} from 'react';
import CustomSelect from "../CustomSelect/CustomSelect";
import {useSelector} from "react-redux";
import {Button, Collapse, Row} from "antd";
import AttributeSelect from "./AttributeSelect/AttributeSelect";
import useActions from "../../../hooks/useActions";
import {getActionTypes} from "../../../helpers/getActionTypes";

const AttributesSelect = ({defaultProductAttributes = [], formRef, setIsChanged}) => {
    const {
        setDefaultAttributes,
        getItem,
        setProductAttributesFormValues,
        setProductAttributes,
        setProductAttributesChoiceValue,
        setAddAttrButtonDisabled,
        setProductAttributesColId,
        setProductForAttributes
    } = useActions()
    const {objects} = useSelector(state => state?.attributes?.attributes)
    const {attributesForProducts} = useSelector(state => state?.attributes)
    const {
        defaultAttributes,
        productAttributes,
        buttonDisabled,
        formValues,
        choiceValue,
        colId
    } = useSelector(state => state?.productAttribute)
    const [isFetchedProdAttrs, setIsFetchedProdAttrs] = useState(false);
    const {Panel} = Collapse;

    useEffect(() => {
        return () => {
            setProductAttributesFormValues(null)
            setDefaultAttributes([])
            setProductAttributesChoiceValue('')
            setProductAttributesColId(0)
            setProductForAttributes(null)
            setProductAttributes(null)
        }
    }, [])

    const fetchDefaultAttributes = () => {
        if (defaultProductAttributes.length === 0 || isFetchedProdAttrs) return;
        setDefaultAttributes(defaultProductAttributes)

        defaultProductAttributes.forEach(attr => {
            getItem({
                id: attr.attribute_id,
                ...getActionTypes('attributes'),
                isGetProductAttributes: true,
            })
        })

        defaultProductAttributes.forEach(defAttr => {
            setProductAttributesFormValues(
                {
                    id: defAttr.attribute_id,
                    name: defAttr.name,
                    is_variation: true,
                    values: [...defAttr.values.map(value => value.value)]
                }
            )
        })

        setIsFetchedProdAttrs(true);
    }

    useEffect(fetchDefaultAttributes, [defaultProductAttributes, isFetchedProdAttrs]);

    useEffect(() => {
        if (formValues.length === 0 && !formRef.current) return;

        formRef.current.setFieldsValue({
            attributes: [...formValues]
        })

    }, [formRef, formValues])

    useEffect(() => {
        if (attributesForProducts.length === 0) return;
        attributesForProducts.forEach(attr => setProductAttributes(attr))
    }, [attributesForProducts])

    const selectChangeHandler = (value) => {
        setAddAttrButtonDisabled(false)

        if (attributesForProducts.length > 0) {
            const alreadyExistAttr = attributesForProducts.find(attr => attr.id === value)

            if (!alreadyExistAttr) {
                setProductAttributesChoiceValue(value)
            } else {
                setAddAttrButtonDisabled(true)
            }

        } else {
            setProductAttributesChoiceValue(value)
        }
    }

    const createNewSelectFieldHandler = () => {
        setProductAttributesColId(choiceValue)
        setProductAttributes(objects.find(attr => attr.id === choiceValue))
        setAddAttrButtonDisabled(true)
        setProductAttributesChoiceValue('')
    }


    return (
        <Row justify='space-between' align='middle'>
            <CustomSelect style={{width: '79%'}} modeType='single' type='attributes' formItem={{isFormItem: false}}
                          onChange={selectChangeHandler}
                          value={choiceValue}/>
            <Button disabled={buttonDisabled} style={{width: '20%'}} type='primary'
                    onClick={createNewSelectFieldHandler}>Lägg till</Button>
            {
                productAttributes.length > 0 &&
                <Collapse className='attribute-select__collapse' expandIconPosition='end' accordion>
                    {
                        productAttributes.map((option, i) => (
                            <Panel forceRender={true} style={{width: '100%'}} header={option.name} key={colId + i}>
                                <AttributeSelect setIsChanged={setIsChanged}
                                                 formRef={formRef}
                                                 option={option}
                                                 defaultAttributes={defaultAttributes}
                                />
                            </Panel>
                        ))
                    }
                </Collapse>
            }
        </Row>
    );
};

export default AttributesSelect;
