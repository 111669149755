import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Empty, Form, Input, Select, Spin, Row, Modal} from "antd";
import useActions from "../../../hooks/useActions";
import {
    ADD_TAG,
    SET_TAGS,
    SET_TAGS_LOADING,
    SET_TAGS_PAGINATION
} from "../../../redux-store/reducers/shopReducers/tagsReducer";
import useDebounce from "../../../hooks/useDebounce";
import {PlusCircleOutlined} from "@ant-design/icons";
import {getActionTypes} from "../../../helpers/getActionTypes";

const TagSelect = ({initialValues = []}) => {
    const {getItems, addItem} = useActions()
    const {tags, loading} = useSelector(state => state?.tags);
    const {Option} = Select;
    const [searchValue, setSearchValue] = useState('');
    const [newTagPopupVisible, setNewTagPopupVisible] = useState(false);
    const [isSearchedValueChanged, setIsSearchedValueChanged] = useState(false);
    const [newTagName, setNewTagName] = useState([])
    const [disabled, setDisabled] = useState(true)
    const debouncedValue = useDebounce(searchValue, 300);

    useEffect(() => {
        if (tags?.objects?.length === 0) {
            getItems(
                {...getActionTypes('tags'),
                    params: {
                        limit: 10,
                        ...{
                            ...initialValues.length > 0 && {ids: initialValues.map(val => val.id)}
                        }
                    }
                })
        }

    }, [tags]);

    const debounceSearch = useCallback(() => {
        if (isSearchedValueChanged) {
            getItems({
                params: {search: debouncedValue},
                ...getActionTypes('tags')
            })
            setIsSearchedValueChanged(false)
        }
    }, [debouncedValue, isSearchedValueChanged]);

    useEffect(debounceSearch, [debouncedValue])

    const onSearch = (value) => {
        setIsSearchedValueChanged(true)
        setSearchValue(value)
    }

    const newTagPopupOpen = () => {
        setNewTagPopupVisible(true)
    }

    const addNewTagHandler = () => {
        if (newTagName.length > 0) {
            newTagName.forEach(tag => {
                addItem({
                    values: {
                        name: tag
                    },
                    ...getActionTypes('tags'),
                    contentText: `Taggen ${tag} har lagts till`
                })
            })
        }
        setNewTagPopupVisible(false)
        setNewTagName([])
    }

    const tagsSelectChangeHandler = (value) => {
        setDisabled(false)
        value.forEach(val => {
            setNewTagName(prev => [...prev.filter(pr => pr !== val), val])
        })
    }

    return (
        <Form.Item label='Taggar' style={{marginBottom: '0'}}
                   labelCol={{span: 4}} wrapperCol={{span: 18}}>
            <Input.Group compact style={{width: '100%'}}>
                <Form.Item style={{width: '70%'}} name='tags' initialValue={initialValues.map(tag => tag.id)}>
                    <Select notFoundContent={loading ? <Row style={{height: '100%', width: '100%'}} justify='center' align='middle'><Spin size="small"/></Row> : <Empty/>}
                            size='middle'
                            style={{width: '100%'}}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            mode='multiple'
                            onSearch={onSearch}>
                        {
                            tags?.objects?.length > 0 &&
                            tags?.objects?.map(tag => {
                                return <Option key={tag.id + '_' + tag.name} value={tag.id}>{tag.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Button onClick={newTagPopupOpen} style={{width: '30%'}}>Lägg till taggar<PlusCircleOutlined/></Button>
            </Input.Group>
            <Modal open={newTagPopupVisible} onCancel={() => setNewTagPopupVisible(false)} okButtonProps={{
                disabled
            }} okText='Lägg till taggar' onOk={addNewTagHandler}>
                <label htmlFor='select-tags' style={{marginBottom: '10px', display: 'block'}}>Välj taggar</label>
                <Select id='select-tags' value={newTagName} style={{width: '100%'}} mode='tags' onChange={tagsSelectChangeHandler}/>
            </Modal>
        </Form.Item>
    );
};

export default TagSelect;
