import {getFilteredState} from "../../../helpers/helpers";

const initialState = {
    productAttributes: [],
    defaultAttributes: [],
    formValues: [],
    buttonDisabled: true,
    choiceValue: '',
    colId: 0,
    loading: false,
}

export const DELETE_PRODUCT_ATTRIBUTES = 'DELETE_PRODUCT_ATTRIBUTES';
export const SET_PRODUCT_ATTRIBUTES = 'SET_PRODUCT_ATTRIBUTES';
export const SET_PRODUCT_ATTRIBUTES_DEFAULT = 'SET_PRODUCT_ATTRIBUTES_DEFAULT';
export const SET_PRODUCT_ATTRIBUTES_CHOICE_VALUE = 'SET_PRODUCT_ATTRIBUTES_CHOICE_VALUE';
export const SET_PRODUCT_ATTRIBUTES_COL_ID = 'SET_PRODUCT_ATTRIBUTES_COL_ID';
export const SET_PRODUCT_ATTRIBUTE_BUTTON_DISABLED = 'SET_PRODUCT_ATTRIBUTE_BUTTON_DISABLED';
export const SET_PRODUCT_FORM_VALUES = 'SET_PRODUCT_FORM_VALUES';

let payload;

const productAttributeReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT_ATTRIBUTES:
            payload = getFilteredState(action.payload, state.productAttributes)
            return {...state, productAttributes: payload, loading: false }
        case SET_PRODUCT_ATTRIBUTES_DEFAULT:
            return {...state, defaultAttributes: action.payload, loading: false }
        case SET_PRODUCT_ATTRIBUTES_CHOICE_VALUE:
            return {...state, choiceValue: action.payload }
        case SET_PRODUCT_ATTRIBUTES_COL_ID:
            return {...state, colId: action.payload }
        case SET_PRODUCT_FORM_VALUES:
            payload = getFilteredState(action.payload, state.formValues)
            return {...state, formValues: payload }
        case SET_PRODUCT_ATTRIBUTE_BUTTON_DISABLED:
            return {...state, buttonDisabled: action.payload }
        case DELETE_PRODUCT_ATTRIBUTES:
            return {...state,
                productAttributes: state.productAttributes.filter(attr => attr.id !== action.payload),
                formValues: state.formValues.filter(attr => attr.id !== action.payload),
                loading: false }
        default:
            return state
    }
}

export default productAttributeReducer
