import React from 'react';
import {Modal} from "antd";
import ChooseImage from "../ChooseImage";
import useActions from "../../../../../hooks/useActions";
import {useSelector} from "react-redux";

const ChooseImagePopup = ({formRef, setIsChanged, isCategory}) => {
    const {setProductMediaPopupVisible, setFeaturedImage, setProductImagePopupVisible} = useActions()
    const {isMediaPopupVisible, featuredId} = useSelector(state => state?.productImages)
    const {chooseImages} = useSelector(state => state?.chooseImages)

    const onChooseImageClickHandler = () => {
        formRef.current.setFieldsValue({
            featured_image_id: featuredId
        })

        const foundImage = chooseImages.find(img => img.id === featuredId);
        if (foundImage) {
            setFeaturedImage(foundImage.url)
        }
        setProductMediaPopupVisible(false)
        setProductImagePopupVisible(false)
    }

    return (
        <Modal open={isMediaPopupVisible}
               okText='Välj bild'
               width='600px'
               onOk={onChooseImageClickHandler}
               onCancel={() => setProductMediaPopupVisible(false)}
               title='Välj utvald bild'>
            <ChooseImage formRef={formRef} isCategory={isCategory} setIsChanged={setIsChanged}/>
        </Modal>
    );
};

export default ChooseImagePopup;
