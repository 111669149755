import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row, Select, Input} from "antd";
import RangeDatePicker from "../../../RangeDatePicker/RangeDatePicker";
import RangeInput from "../../../Form/RangeInput/RangeInput";
import CustomSelect from "../../../Form/CustomSelect/CustomSelect";
import useActions from "../../../../hooks/useActions";
import {useSelector} from "react-redux";

const PickListFilterForm = ({
                                isRangeChanged,
                                setIsRangeChanged,
                                rangeDate,
                                setRangeDate,
                                deliveryRangeDate,
                                isDeliveryRangeChanged,
                                setDeliveryRangeDate,
                                setIsDeliveryRangeChanged
                            }) => {
    const {setPicklistFormRef, setPicklistProductsNames} = useActions()
    const [isFormSet, setIsFromSet] = useState(false);
    const {filters} = useSelector(state => state?.picklist);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && !isFormSet) {
            setPicklistFormRef(ref)
            ref.current?.setFieldsValue({...filters})
            setIsFromSet(true)
        }
    }, [ref, isFormSet])

    const productSelectChangeHandler = (value, values) => {
        setPicklistProductsNames(values.map(item => item.children))
    }

    return (
        <Form ref={ref} name='picklist-filters'>
            <Row className='picklist-filters__row' justify='space-between' align='middle'>
                <Col className='picklist-filters__col'>
                    <Form.Item name='customer_first_name' label='Kundens namn'>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col className='picklist-filters__col'>
                    <Form.Item name='customer_last_name' label='Kundens efternamn'>
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='picklist-filters__row' justify='space-between' align='middle'>
                <Col className='picklist-filters__col'>
                    <Form.Item name='order_number' label='Ordernummer'>
                        <RangeInput/>
                    </Form.Item>
                </Col>
                <Col className='picklist-filters__col'>
                    <Form.Item name='delivery_date' label='Leveransdag'>
                        <RangeDatePicker custom={
                            {
                                customRange: deliveryRangeDate,
                                customIsRangeChanged: isDeliveryRangeChanged,
                                isCustom: true,
                                customSetIsRangeChanged: setIsDeliveryRangeChanged,
                                customSetRange: setDeliveryRangeDate,
                                customFrom: 'delivery_date_from',
                                customTo: 'delivery_date_to',
                                customName: 'delivery_date'
                            }
                        } formRef={ref}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='picklist-filters__row' justify='space-between' align='middle'>
                <Col className='picklist-filters__col'>
                    <Form.Item name='date' label='Datum'>
                        <RangeDatePicker custom={
                            {
                                customRange: rangeDate,
                                customIsRangeChanged: isRangeChanged,
                                isCustom: true,
                                customSetIsRangeChanged: setIsRangeChanged,
                                customSetRange: setRangeDate,
                                customFrom: 'date_from',
                                customTo: 'date_to',
                                customName: 'date'
                            }
                        } formRef={ref}/>
                    </Form.Item>
                </Col>
                <Col className='picklist-filters__col'>
                    <Form.Item name='email' label='E-post'>
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='picklist-filters__row' justify='space-between' align='middle'>
                <Col className='picklist-filters__col'>
                    <Form.Item name='phone_number' label='Telefonnummer'>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col className='picklist-filters__col'>
                    <Form.Item name='status' label='Orderstatus'>
                        <Select>
                            <Select.Option value='processing'>
                                Behandlas
                            </Select.Option>
                            <Select.Option value='completed'>
                                Färdigbehandlad
                            </Select.Option>
                            <Select.Option value='cancelled'>
                                Avbruten
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='picklist-filters__row' justify='space-between' align='middle'>
                <Col className='picklist-filters__col'>
                    <Form.Item name='order_total' label='Total'>
                        <RangeInput/>
                    </Form.Item>
                </Col>
                <Col className='picklist-filters__col'>
                    <CustomSelect formItem={{isFormItem: true, name: 'skus', label: 'Produkt(er)'}}
                                  type='products'
                                  optionName='title'
                                  optionValue='sku'
                                  onChange={productSelectChangeHandler}
                                  modeType='multiple'
                    />
                </Col>
                <Col className='picklist-filters__col'>
                    <Form.Item name='packing_status' label='Plocklistestatus'>
                        <Select>
                            <Select.Option value='packed'>
                                Packad
                            </Select.Option>
                            <Select.Option value='not_packed'>
                                Ej packad
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col className='picklist-filters__col'>
                    <CustomSelect
                        optionName='name'
                        optionValue='machine_name'
                        style={{width: '100%'}}
                        showSearch={false}
                        modeType='single'
                        type='shipping_methods'
                        formItem={{
                            isFormItem: true,
                            label: 'Fraktmetod',
                            name: 'shipping_method_machine_name',
                            layout: {
                                labelCol: {
                                    span: 8,
                                },
                                wrapperCol: {
                                    span: 18,
                                }
                            }
                        }}/>
                </Col>
                <Col className='picklist-filters__col'>
                    <CustomSelect showSearch={false} type='sites' modeType='single' formItem={{
                        isFormItem: true,
                        label: 'Försäljningsställen',
                        name: 'site_id',
                        layout: {
                            labelCol: {
                                span: 12,
                            },
                            wrapperCol: {
                                span: 12,
                            }
                        }
                    }}/>
                </Col>
            </Row>
        </Form>
    );
};

export default PickListFilterForm;
