import React from 'react';
import {Modal} from "antd";
import {
    SET_IMAGE,
    SET_IMAGES,
    SET_IMAGES_LOADING,
    SET_IMAGES_PAGINATION
} from "../../../../redux-store/reducers/shopReducers/imagesReducer";
import {useSelector} from "react-redux";
import useActions from "../../../../hooks/useActions";
import ImageUploader from "../ImageUploader";

const ImageUploaderPopup = ({isFeaturedImage = false, isProduct = false, formRef}) => {
    const {setImageUploaderFileList, addItem, setImageUploaderPopupVisible} = useActions();
    const {fileList, popupVisible, disabled, loading} = useSelector(state => state?.imagesUploader)

    const beforeUpload = () => {
        fileList.forEach(file => {
            const data = new FormData();
            data.append('file', file.originFileObj);
            const allValues = formRef?.current?.getFieldsValue();
            const images = allValues && allValues.hasOwnProperty('images') && allValues['images'] ? allValues['images'] : null;

            addItem({
                values: data,
                type: 'images',
                setItemsActionType: SET_IMAGES,
                setPaginationActionType: SET_IMAGES_PAGINATION,
                setLoadingActionType: SET_IMAGES_LOADING,
                addItemActionType: SET_IMAGE,
                formRef,
                product: {
                    isProduct,
                    isFeaturedImage,
                    file,
                    images
                },
                contentText: `Bilden ${file.name} har laddats upp till Media-biblioteket`
            })
        })

        setImageUploaderFileList([])
        setImageUploaderPopupVisible(false)
    }

    const handleCancel = () => {
        setImageUploaderPopupVisible(false)
    }

    return (
        <Modal open={popupVisible}
               onCancel={handleCancel}
               okText='Ladda upp'
               okButtonProps={{disabled, loading}}
               onOk={beforeUpload}>
            <ImageUploader isMultiple={!isFeaturedImage} isProduct={true}/>
        </Modal>
    );
};

export default ImageUploaderPopup;
