import React, {useEffect} from 'react';

import {Link, Outlet, useLocation, useParams} from "react-router-dom";

import {useSelector} from "react-redux";

import {Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";

import useActions from "@hooks/useActions";
import {dashboardColumns} from "@antTable/dashboardColumns";
import AntTable from "@components/AntTable/AntTable";
import {getTextFromType} from "@helpers/helpers";
import {getActionTypes} from "@helpers/getActionTypes";
import {getUseSelectorFromType} from "@helpers/getUseSelectorFromType";
import {getColumns} from "@helpers/getColumns";

const UpsellObjects = ({type = 'upsell-objects', title = 'Tilläggsköp'}) => {
    const {
        getItems,
        removeItem,
        setUpsellObjectsFilters,
        setUpsellObjectsSorting,
        setUpsellObjectsPagination
    } = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const items = useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]);
    const {loading, pagination} = useSelector(state => state[useSelectorType] && state[useSelectorType]);

    const {
        upsellObjectsTableFilters,
        upsellObjectsTableSorting
    } = useSelector(state => state?.upsellObjects)

    useEffect(() => {
        getItems({
            ...getActionTypes('products')
        });
    }, [])

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                params: {
                    ...{...(upsellObjectsTableFilters && Object.keys(upsellObjectsTableFilters).length > 0 && upsellObjectsTableFilters)},
                    ...(upsellObjectsTableSorting && Object.keys(upsellObjectsTableSorting).length > 0 && upsellObjectsTableSorting?.order && {
                        order_by: upsellObjectsTableSorting.field,
                        order_type: upsellObjectsTableSorting.order.replace('end', '')
                    })
                },
                ...getActionTypes(type)
            });

            getItems({
                ...getActionTypes('sites')
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                filters: upsellObjectsTableFilters,
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row className='filter-block' justify='end' align='middle'>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>

                        <AntTable initialData={items} total={items?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setUpsellObjectsFilters}
                                  setSorting={setUpsellObjectsSorting}
                                  setPagination={setUpsellObjectsPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default UpsellObjects;
