import React, {useState} from 'react';
import useActions from "../../../../../hooks/useActions";
import {Button, Form, Select} from "antd";
import {
    SET_ATTRIBUTE,
    SET_ATTRIBUTES_LOADING
} from "../../../../../redux-store/reducers/shopReducers/attributesReducer";

const AttributeNewValueForm = ({id, attr, setIsModalVisible}) => {
    const [isChanged, setIsChanged] = useState(false)
    const {updateItem} = useActions()

    const finishHandler = (values) => {
        const reworkedValues = attr.values.length > 0 ? [...attr.values, ...values.values] : values.values

        if (isChanged) {
            updateItem({
                id: attr.id,
                values: {
                    name: attr.name,
                    values: reworkedValues.map(val => ({value: val}))
                },
                type: 'attributes',
                setLoadingActionType: SET_ATTRIBUTES_LOADING,
                updateActionType: SET_ATTRIBUTE,
                contentText: `Attribut ${id} har uppdaterats`
            })
            setIsChanged(false);
            setIsModalVisible(false)
        }
    }

    return (
        <>
            <h2 style={{marginBottom: '20px'}}>Lägg till attributvärde</h2>
            <Form onFinish={finishHandler} onValuesChange={() => setIsChanged(true)} name='attribute-values-form' labelCol={{span: 8}} wrapperCol={{span: 18}}>
                <Form.Item name='values' label={'Lägg till värden'}>
                    <Select showSearch mode='tags' />
                </Form.Item>
                <Form.Item>
                    <Button disabled={!isChanged} type='primary' htmlType='submit'>Lägg till attributvärden</Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default AttributeNewValueForm;
