import React from 'react';
import {DatePicker, Row, TimePicker} from "antd";

import locale from 'antd/es/date-picker/locale/sv_SE';

import {getRangeArray} from "../../../helpers/getRangeArray";


const OrderDate = ({setIsDisabled, date, setDate, time, setTime}) => {
    const {RangePicker} = TimePicker;

    const changeHandler = (date, set) => {
        set(date)
        setIsDisabled(false)
    }

    return (
        <>
            <DatePicker mode='date'
                        allowClear={false}
                        locale={locale}
                        style={{marginRight: '10px'}}
                        value={date}
                        onChange={date => changeHandler(date, setDate)}/>
            <RangePicker
                hideDisabledOptions={true}
                format={'HH:mm'}
                value={time}
                style={{marginRight: '10px', maxWidth: '170px'}}
                onChange={time => changeHandler(time, setTime)}
                minuteStep={60}
                disabledTime={() => ({
                    disabledHours: () => [...getRangeArray(0, 8), ...getRangeArray(21, 24)]
                })}
            />
        </>

    );
};

export default OrderDate;