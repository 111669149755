import {
    REMOVE_FEATURED_IMAGE,
    REMOVE_GALLERY_IMAGE,
    SET_FEATURED_ID,
    SET_FEATURED_IMAGE,
    SET_GALLERY_IMAGES,
    SET_IS_FEATURED_IMAGE, SET_IS_FEATURED_IMAGE_UPLOADED, SET_IS_GALLERY_IMAGES_UPLOADED,
    SET_PRODUCT_IMAGE_POPUP_VISIBLE,
    SET_PRODUCT_MEDIA_POPUP_VISIBLE
} from "../../reducers/productImagesReducer/productImagesReducer";
import {getApiUrl, getCleanPayload, getParams} from "../../../helpers/helpers";
import axios from "axios";
import {message} from "antd";

export const productImagesAction = {
    setFeaturedImage: (payload) => ({
        type: SET_FEATURED_IMAGE,
        payload
    }),
    setGalleryImages: payload => ({
        type: SET_GALLERY_IMAGES,
        payload
    }),
    removeFeaturedImage: payload => ({
        type: REMOVE_FEATURED_IMAGE,
        payload
    }),
    removeGalleryImage: payload => ({
        type: REMOVE_GALLERY_IMAGE,
        payload
    }),
    setFeaturedId: payload => ({
        type: SET_FEATURED_ID,
        payload
    }),
    setProductImagePopupVisible: payload => ({
        type: SET_PRODUCT_IMAGE_POPUP_VISIBLE,
        payload
    }),
    setProductMediaPopupVisible: payload => ({
        type: SET_PRODUCT_MEDIA_POPUP_VISIBLE,
        payload
    }),
    setIsFeaturedImage: payload => ({
        type: SET_IS_FEATURED_IMAGE,
        payload
    }),
    setIsFeaturedImageUploaded: payload => ({
        type: SET_IS_FEATURED_IMAGE_UPLOADED,
        payload
    }),
    setIsGalleryImagesUploaded: payload => ({
        type: SET_IS_GALLERY_IMAGES_UPLOADED,
        payload
    }),
    getImageByName: ({params = {}, isFeaturedImage = true, productImages = [], formRef = null}) => dispatch => {
        return axios.get(`${getApiUrl('images')}images/${getParams(params)}`, {headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}})
            .then(res => {
                if (isFeaturedImage) {
                    dispatch({type: SET_FEATURED_IMAGE, payload: res.data.objects[0].url})
                    dispatch({type: SET_FEATURED_ID, payload: res.data.objects[0].id})
                    dispatch({type: SET_IS_FEATURED_IMAGE_UPLOADED, payload: true})
                } else {
                    const image = res.data?.objects[0];

                    if (image) {
                        const ids = [...new Set([...productImages, image.id])];

                        dispatch(productImagesAction.getImagesByIds({
                            params: {ids},
                            isFeatured: false,
                            formRef
                        }))

                        // dispatch({type: SET_GALLERY_IMAGES, payload: [...uniqSet]})
                        dispatch({type: SET_IS_GALLERY_IMAGES_UPLOADED, payload: true})
                    }
                }
            })
            .catch((errors) => {
                if (errors.response?.data?.detail) {
                    message.error(errors.response?.data?.detail);
                } else {
                    message.error(errors.toString());
                }
            });
    },
    getImagesByIds: ({params = {}, isFeatured = true, formRef = null}) => dispatch => {
        return axios.get(`${getApiUrl('images')}images/${getParams(params)}`, {headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}})
            .then(res => {
                if (res.data.objects.length > 0) {
                    if (isFeatured) {
                        dispatch({type: SET_FEATURED_IMAGE, payload: res.data.objects[0].url})
                        dispatch({type: SET_FEATURED_ID, payload: res.data.objects[0].id})
                    } else {
                        dispatch({type: SET_GALLERY_IMAGES, payload: res.data.objects})
                        if (formRef && formRef.current) {
                            formRef.current.setFieldsValue({
                                images: params.ids
                            })
                        }
                    }
                }
            })
            .catch((errors) => {
                if (errors.response?.data?.detail) {
                    message.error(errors.response?.data?.detail);
                } else {
                    message.error(errors.toString());
                }
            });
    },
}
