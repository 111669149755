import React, {useEffect, useState} from 'react';

import {Select} from "antd";

import {useSelector} from "react-redux";
import useActions from "../../../hooks/useActions";
import {getActionTypes} from "../../../helpers/getActionTypes";

import "./OrderDeliverySelect.scss";

const OrderDeliverySelect = ({order, shippingDeliveryValue, setShippingDeliveryValue, setIsDisabled}) => {
    const {Option} = Select;
    const {shippingMethods, loading} = useSelector(state => state?.shippingMethods)
    const [filteredShippingMethods, setFilteredShippingMethods] = useState([]);
    const {getItems} = useActions();

    const selectChangeHandler = (shippingDeliveryValue) => {
        setShippingDeliveryValue(shippingDeliveryValue);
        setIsDisabled(false)
    }

    // Get all shipping methods
    useEffect(() => {
        getItems({...getActionTypes('shipping_methods')});
    }, [])


    // Set default shippingDeliveryValue for select
    useEffect(() => {
        if (filteredShippingMethods && filteredShippingMethods.length > 0) {
            const foundDefaultValue = filteredShippingMethods.find(delivery => {
                return delivery.machine_name && delivery.machine_name === order?.shipping_method_id
            });

            if (foundDefaultValue && foundDefaultValue.id) {
                setShippingDeliveryValue(foundDefaultValue.id)
            }
        }
    }, [filteredShippingMethods])

    // Collect shipping methods for pickup and best delivery
    useEffect(() => {
        if (shippingMethods?.objects?.length > 0) {
            setFilteredShippingMethods(() =>
                shippingMethods.objects.filter(method => method.shipping_type === 'pickup' || method.machine_name?.includes('bring'))
            )
        }
    }, [shippingMethods])

    return (
            <Select loading={loading}
                    placeholder='Byte av leveranssätt'
                    className='order-delivery__select'
                    value={shippingDeliveryValue}
                    onChange={selectChangeHandler}>
                {
                    filteredShippingMethods.map(delivery => (
                        <Option key={delivery.name} value={delivery.id}>{delivery.name}</Option>
                    ))
                }
            </Select>

    );
};

export default OrderDeliverySelect;
