import {
    SET_SHIPPING_CLASSES_PAGINATION,
    SET_SHIPPING_CLASSES_TABLE_FILTERS,
    SET_SHIPPING_CLASSES_TABLE_SORTING
} from "../../../reducers/shopReducers/shippingClassesReducer";

export const shippingClassesActions = {
    setShippingClassesFilters: (payload) => (
        {
            type: SET_SHIPPING_CLASSES_TABLE_FILTERS,
            payload
        }
    ),
    setShippingClassesSorting: (payload) => (
        {
            type: SET_SHIPPING_CLASSES_TABLE_SORTING,
            payload
        }
    ),
    setShippingClassesPagination: (payload) => (
        {
            type: SET_SHIPPING_CLASSES_PAGINATION,
            payload
        }
    )
}
