import React from 'react';
import {Col, DatePicker, Form, InputNumber, Select} from "antd";
import moment from "moment";

const DateFormItems = ({index, fieldIndex, data}) => {
    const weekDays = {
        monday: 'Måndag', tuesday: 'Tisdag', wednesday: 'Onsdag', thursday: 'Torsdag', friday: 'Fredag', saturday: 'Lördag', sunday: 'Söndag'
    };

    const renderPeriod = () => (
        <>
            <span>Period (dagar)</span>
            <Form.Item name={[fieldIndex, 'time_range_interval']} initialValue={30}>
                <InputNumber/>
            </Form.Item>
        </>
    )

    const options = Object.keys(weekDays).map(day => (
        {
            label: weekDays[day],
            value: day
        }
    ))

    return (
        <Col span={4}>
            <span>Dag/Datum</span>
            <Form.Item name={[fieldIndex, 'date_type']} initialValue='day'>
                <Select style={{minWidth: '120px'}}>
                    <Select.Option value='day'>Veckodag</Select.Option>
                    <Select.Option value='week'>Hela veckan</Select.Option>
                    <Select.Option value='date'>Specifikt datum</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item noStyle
                       shouldUpdate={(prevValues, currentValues) => {
                           const prev = prevValues['rules_' + index];
                           const cur = currentValues['rules_' + index];
                           return prev[fieldIndex]?.date_type !== cur[fieldIndex]?.date_type
                       }}>
                {({getFieldValue}) => {
                    const rules = getFieldValue()['rules_' + index];
                    const items = rules ? rules[fieldIndex] : {};
                    return (
                        items?.date_type === 'day' ? (
                            <>
                                <Form.Item name={[fieldIndex, 'day']} initialValue='monday' rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                    <Select style={{minWidth: '120px'}} options={options}/>
                                </Form.Item>
                                {renderPeriod()}
                            </>
                        ) : (
                            items?.date_type === 'week' ?
                                renderPeriod() :
                                (
                                    items?.date_type === 'date' ?
                                        <Form.Item name={[fieldIndex, 'date_2']} initialValue={moment((data?.date ?? new Date().toISOString().split('T')[0]), 'YYYY:MM:DD')}>
                                            <DatePicker/>
                                        </Form.Item> :
                                        null
                                )
                        )
                    )
                }
                }
            </Form.Item>
        </Col>
    );
};

export default DateFormItems;
