import {
    SET_CHOOSE_IMAGES,
    SET_CHOOSE_IMAGES_LOADING, SET_CHOOSE_IMAGES_MORE,
    SET_CHOOSE_IMAGES_PAGE
} from "../../reducers/chooseImageReducer/chooseImageReducer";
import {getApiUrl, getParams} from "../../../helpers/helpers";
import axios from "axios";
import {message} from "antd";

export const chooseImagesActions = {
    getChosenImages: ({params = {}, loadMore = false}) => dispatch => {
        dispatch({type: SET_CHOOSE_IMAGES_LOADING, payload: true})
        const {page} = params

        return axios.get(`${getApiUrl('images')}images/${getParams(params)}`, {
            headers: {'Authorization': localStorage.getItem('token')?.replaceAll('"', '')}
        })
            .then(res => {
                if (loadMore) {
                    dispatch({type: SET_CHOOSE_IMAGES_PAGE, payload: page})
                    dispatch({type: SET_CHOOSE_IMAGES_MORE, payload: res.data.objects})
                }else {
                    dispatch({type: SET_CHOOSE_IMAGES, payload: res.data.objects})
                    dispatch({type: SET_CHOOSE_IMAGES_PAGE, payload: page ?? 1 })
                }

            })
            .catch((errors) => {
                if (errors.response?.data?.detail) {
                    message.error(errors.response?.data?.detail);
                } else {
                    message.error(errors.toString());
                }
                dispatch({type: SET_CHOOSE_IMAGES_LOADING, payload: false})
            });
    },
    setChooseImagesNull: () => {
        return {
            type: SET_CHOOSE_IMAGES,
            payload: []
        }
    }
}
