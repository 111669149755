import React from 'react';
import BookFreightPopup from "../BookFreight/BookFreightPopup/BookFreightPopup";
import {Button} from "antd";
import useActions from "../../../hooks/useActions";

const BookFreight = ({order}) => {
    const { setBookFreightPopup} = useActions()
    const showBookFreightPopup = () => {
        setBookFreightPopup({
            visible: true,
            order: order
        })
    }

    return (
        <div>
            <Button style={{marginRight: '10px'}} type='primary' onClick={showBookFreightPopup}>Boka frakt</Button>
            <BookFreightPopup order={order}/>
        </div>
    );
};

export default BookFreight;
