import React from 'react';

import {DatePicker, Form, Switch} from "antd";

const ProductPublishSchedule = ({index}) => {

    return (
        <>
            <td className='form-table__col'>
                <Form.Item name={[index, 'time_publish']}
                           valuePropName="checked">
                    <Switch/>
                </Form.Item>
            </td>
            <td className='form-table__col'>
                <Form.Item name={[index, 'time_publish_from']}>
                    <DatePicker showTime/>
                </Form.Item>
            </td>
            <td className='form-table__col'>
                <Form.Item name={[index, 'time_publish_to']}>
                    <DatePicker showTime/>
                </Form.Item>
            </td>
        </>
    );
};

export default ProductPublishSchedule;