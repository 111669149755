import React, {useEffect, useState} from 'react';
import HiddenImagesFromItems from "../HiddenImagesFormItems/HiddenImagesFromItems";
import TypeImagesPopup from "../TypeImagesPopup/TypeImagesPopup";
import ChooseImagePopup from "../ChooseImage/ChooseImagePopup/ChooseImagePopup";
import ImageUploaderPopup from "../../ImageUploader/ImageUplaoderPopup/ImageUploaderPopup";
import {useSelector} from "react-redux";
import ProductImages from "../ProductImages";
import useActions from "../../../../hooks/useActions";

const FormImages = ({initialFeaturedImageValue, initialGalleryImageValues = [], formRef, isCategory = false, setIsChanged = () => {}}) => {
    const {setGalleryImages, setFeaturedId, setFeaturedImage} = useActions();
    const {isFeaturedImage} = useSelector(state => state?.productImages);

    useEffect(() => {
        return () => {
            setFeaturedImage('');

            if (!isCategory) {
                setGalleryImages([]);
            }

            setFeaturedId(null);
        }
    }, []);

    return (
       <>
           <HiddenImagesFromItems isCategory={isCategory}/>
           <ProductImages setIsChanged={setIsChanged}
                          isCategory={isCategory}
                          formRef={formRef}
                          initialGalleryImageValues={initialGalleryImageValues}
                          initialFeaturedImageValue={initialFeaturedImageValue}/>

           <TypeImagesPopup/>
           <ChooseImagePopup formRef={formRef} isCategory={isCategory} setIsChanged={setIsChanged}/>
           <ImageUploaderPopup isFeaturedImage={isFeaturedImage} isProduct={true} formRef={formRef}/>
       </>
    );
};

export default FormImages;
