import React from 'react';

import {Button, Popconfirm} from "antd";

import useActions from "../../../hooks/useActions";
import {useParams} from "react-router-dom";

const style = {
    marginRight: '10px'
}

const CancelDelivery = () => {
    const {id} = useParams();
    const {cancelDelivery} = useActions();
    return (
        <Popconfirm title='Är du säker på att du vill avbryta orderleveransen?'
                    placement='topLeft'
                    okText="Ja"
                    cancelText="Nej"
                    onConfirm={() => cancelDelivery(id)}>
            <Button type='primary'
                    ghost={true}
                    style={style}>
                Avboka leverans
            </Button>
        </Popconfirm>

    );
};

export default CancelDelivery;
