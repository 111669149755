import axios from "axios";
import {SET_AUTH} from "../redux-store/reducers/authReducer/authReducer";
import {
    SET_LOGIN_LOADING,
    SET_POOL,
    SET_TOKEN_EXPIRE,
    SET_USER
} from "../redux-store/reducers/loginReducer/loginReducer";
import {getPool} from "./getCognitoData";
import {message} from "antd";

export const setLoginCognito = ({
                                       url = process.env.COGNITO_TEST_ENDPOINT,
                                       token,
                                       expire,
                                       user,
                                       cognitoData,
                                       dispatch
                                   }) => {
    return axios({
        method: 'GET',
        url: url,
        headers: {
            'Authorization': token}
    })
        .then(res => {
            dispatch({type: SET_AUTH, payload: true})
            dispatch({type: SET_USER, payload: user ?? getPool().getCurrentUser()})
            dispatch({type: SET_TOKEN_EXPIRE, payload: expire})
            dispatch({type: SET_POOL, payload: cognitoData.pool})
            localStorage.setItem('user', JSON.stringify(user ?? getPool().getCurrentUser()));
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('tokenExpire', expire.toString());
            message.success('Inloggning lyckad');

        })
        .catch(error => {
            console.error('cognitoCallToLogin', error);
            dispatch({type: SET_LOGIN_LOADING, payload: false})
        });
}
