const initialState = {
    fileList: [],
    popupVisible: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    disabled: true,
}

export const SET_UPLOAD_IMAGE_FILE_LIST = 'SET_UPLOAD_IMAGE_FILE_LIST';
export const SET_UPLOAD_IMAGE_POPUP_VISIBLE = 'SET_UPLOAD_IMAGE_POPUP_VISIBLE';
export const SET_UPLOAD_IMAGE_PREVIEW_VISIBLE = 'SET_UPLOAD_IMAGE_PREVIEW_VISIBLE';
export const SET_UPLOAD_IMAGE_PREVIEW_IMAGE = 'SET_UPLOAD_IMAGE_PREVIEW_IMAGE';
export const SET_UPLOAD_IMAGE_PREVIEW_TITLE = 'SET_UPLOAD_IMAGE_PREVIEW_TITLE';
export const SET_UPLOAD_IMAGE_DISABLED = 'SET_UPLOAD_IMAGE_DISABLED';

const imageUploaderReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_UPLOAD_IMAGE_FILE_LIST:
            return {...state, fileList: [...action.payload]}
        case SET_UPLOAD_IMAGE_POPUP_VISIBLE:
            return {...state, popupVisible: action.payload}
        case SET_UPLOAD_IMAGE_PREVIEW_VISIBLE:
            return {...state, previewVisible: action.payload}
        case SET_UPLOAD_IMAGE_PREVIEW_IMAGE:
            return {...state, previewImage: action.payload}
        case SET_UPLOAD_IMAGE_PREVIEW_TITLE:
            return {...state, previewTitle: action.payload}
        case SET_UPLOAD_IMAGE_DISABLED:
            return {...state, disabled: action.payload}
        default:
            return state
    }
}

export default imageUploaderReducer;
